import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActivityType } from 'shared/lib/comment';
import { checkLinkedRunStarted } from './Renderers/LinkedRunRenderer';

const handleActionType = (actionType: string) => {
  if (checkLinkedRunStarted(actionType)) {
    return ['text-green-200', 'list-check'];
  }

  let color = 'text-blue-200';
  let icon = 'pencil';

  switch (actionType) {
    case 'Updated Severity ID':
    case 'Updated Status ID':
    case 'Updated Sub Status ID':
    case 'Updated Title':
    case 'Updated Project ID':
    case 'edit':
      break;
    case ActivityType.Comment:
    case 'Added Comment':
      icon = 'comment';
      color = 'text-gray-200';
      break;
    case 'Updated Assignee':
      icon = 'user';
      break;
    case 'Updated Notes':
      icon = 'clipboard';
      break;
    case 'Created Issue':
    case 'Created Risk':
    case 'create':
      icon = 'file-circle-exclamation';
      color = 'text-red-200';
      break;
    case 'pause':
      icon = 'pause';
      color = 'text-yellow-200';
      break;
    case 'resume':
      icon = 'play';
      color = 'text-green-200';
      break;
    case 'issue pause':
      icon = 'pause';
      color = 'text-orange-200';
      break;
    case 'all issues resolved':
      icon = 'thumbs-up';
      break;
    case 'Updated Issue Actions':
    case 'Updated Risk Actions':
      icon = 'check';
      break;
    case 'Issue Action Signed Off':
    case 'Risk Action Signed Off':
      icon = 'check';
      color = 'text-green-200';
      break;
    default:
      icon = 'file';
      break;
  }
  return [color, icon];
};

export interface TimelineIconProps {
  actionType: string;
  isFirstIcon?: boolean;
}

const TimelineIcon = ({ actionType, isFirstIcon = true }: TimelineIconProps) => {
  const [color, icon] = handleActionType(actionType);

  return (
    <div className="flex-none w-6 h-full">
      {/* Overlay a background-colored circle underneath the actual status icon circle so that the blue line does not show through */}
      {isFirstIcon && <div className="absolute ml-3 h-full border-l-2 border-gray-300 opacity-50" />}
      <FontAwesomeIcon icon="circle" className="absolute text-3xl text-white" />
      <FontAwesomeIcon icon="circle" className={`z-5 absolute mt-0.5 mr-1 text-2xl ${color}`} />
      <FontAwesomeIcon icon={icon as IconName} className="fa-fw ml-1 z-6 mt-2 absolute text-gray-600 text-xs" />
    </div>
  );
};

export default TimelineIcon;
