import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { PERM } from '../../lib/auth';
import procedureUtil from '../../lib/procedureUtil';
import runUtil, { RUN_STATE } from '../../lib/runUtil';
import { useAuth } from '../../contexts/AuthContext';
import { useSettings } from '../../contexts/SettingsContext';
import useProcedureObserver from '../../hooks/useProcedureObserver';
import useRunObserver from '../../hooks/useRunObserver';
import { generateHiddenClassString } from '../../lib/styles';
import RunProgressBar from '../RunProgressBar';
import { useUserInfo } from '../../contexts/UserContext';
import externalDataUtil from '../../lib/externalDataUtil';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { INVALID_ITEMS_MESSAGE } from '../ButtonsProcedure';
import { ParentReferenceType, Procedure, ProcedureLinkBlock, Run } from 'shared/lib/types/views/procedures';
import SubstepNumber from '../SubstepNumber';
import { procedureSnapshotViewPathWithSourceUrl, runViewPath } from '../../lib/pathUtil';
import usePendingChangesPrompt from '../../hooks/usePendingChangesPrompt';
import { isProcedureWithBatchSteps, prepareBatchStepsForRun } from '../../lib/batchSteps';
import RunBatchProcedureModal from '../BatchSteps/RunBatchProcedureModal';
import { cloneDeep } from 'lodash';
import configUtil from '../../lib/configUtil';
import { useRunContext } from '../../contexts/RunContext';
import RunLabel from '../RunLabel';

/**
 * @param onStartLinkedRun - Callback function for starting a linked procedure.
 * @param run - the run id, see {@link https://gitlab.com/epsilon3inc/spock/-/blob/c6f526ec2ac53a616d47af56cf8faa562b23a471/web/src/components/Blocks/BlockProcedureLink.js#L37 usage in BlockProcedureLink.js}
 */
export type BlockProcedureLinkProps = {
  contentIndex: number;
  docId: string;
  docState: string;
  operation: string;
  link: ProcedureLinkBlock;
  onStartLinkedRun: ((contentIndex: number, linkedRun: string) => void) | undefined;
  isHidden: boolean;
  isEnabled: boolean;
  blockLabel?: string;
  showLabels?: boolean;
  wrapInsideTable?: boolean;
  spreadContent?: boolean;
  addCompletedRunId?: (string) => void;
  sourceName?: string;
  multiLine?: boolean;
};

/**
 * Component for rendering a linked procedure.
 */
const BlockProcedureLink = ({
  contentIndex,
  docId,
  docState,
  operation,
  link,
  onStartLinkedRun,
  isHidden,
  isEnabled,
  blockLabel,
  showLabels = true,
  wrapInsideTable = true,
  spreadContent = false,
  addCompletedRunId,
  sourceName = 'Untitled',
  multiLine = false,
}: BlockProcedureLinkProps) => {
  const { procedure: linkedProcedure } = useProcedureObserver({
    id: link.procedure,
  });
  const { services, currentTeamId } = useDatabaseServices();
  const { run } = useRunObserver({ id: link.run });
  const { runParentReference } = useRunContext();
  const { auth } = useAuth();
  const { userInfo } = useUserInfo();
  const userId = userInfo.session.user_id;
  const { getSetting, config } = useSettings();
  const isMounted = useRef(true);
  const history = useHistory();
  const confirmPendingChanges = usePendingChangesPrompt();
  const [showBatchRunModal, setShowBatchRunModal] = useState(false);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  const runStatus = useMemo(() => {
    return runUtil.getRunStatus(run);
  }, [run]);

  useEffect(() => {
    if (addCompletedRunId && runStatus.state === RUN_STATE.COMPLETED) {
      addCompletedRunId(run?._id);
    }
  }, [run?._id, runStatus, addCompletedRunId]);

  const section = useMemo(() => link.section, [link]);

  const hasSection = useMemo(() => {
    return !(section === undefined || section === null || section === '');
  }, [section]);

  const linkedSectionIndex = useMemo((): number | null => {
    if (!section) {
      return null;
    }
    return procedureUtil.getSectionIndexById(linkedProcedure, section);
  }, [linkedProcedure, section]);

  const containsLinkedSection = useMemo(() => {
    if (!linkedProcedure || !linkedProcedure.sections) {
      return true;
    }

    return linkedSectionIndex !== null;
  }, [linkedProcedure, linkedSectionIndex]);

  // Creates a run doc and updates with dynamic data if online.
  const createLinkedProcedureRun = useCallback(
    (procedureToRun) => {
      let userParticipantType;
      if (config) {
        userParticipantType = configUtil.getUserParticipantType(config);
      }
      let parentReferenceId = docId;
      let parentReferenceType = ParentReferenceType.Run;
      if (runParentReference) {
        parentReferenceId = runParentReference.id.toString();
        parentReferenceType = runParentReference.type;
      }
      let run = runUtil.newLinkedProcedureRunDoc({
        procedure: procedureToRun,
        linkedSectionIndex,
        parentReferenceId,
        parentReferenceType,
        operation,
        userId,
        userParticipantType,
      });
      return services.externalData
        .getAllExternalItems(run)
        .then((externalItems) => {
          run = externalDataUtil.updateProcedureWithItems(run, externalItems);
          return run;
        })
        .catch(() => {
          // Ignore any errors and fall back to using procedure without dynamic data.
          return run;
        });
    },
    [config, docId, linkedSectionIndex, operation, services.externalData, userId, runParentReference]
  );

  /**
   * If linked only to a section, return whether there is an invalid item in the linked section.
   * If linked to the whole procedure, return whether there is an invalid item in the whole procedure.
   */
  const hasInvalidExternalItems = useCallback(
    (linkedRun: Run): boolean => {
      if (linkedSectionIndex !== null) {
        // Only one section is linked.
        const linkedSection = linkedRun.sections[linkedSectionIndex];
        return externalDataUtil.sectionHasInvalidExternalItems(linkedSection);
      }

      // The whole procedure is linked.
      return externalDataUtil.hasInvalidExternalItems(linkedRun);
    },
    [linkedSectionIndex]
  );

  const startLinkedRun = useCallback(
    (linkedRun, event) => {
      if (typeof onStartLinkedRun !== 'function') {
        return;
      }
      if (hasInvalidExternalItems(linkedRun) && !window.confirm(INVALID_ITEMS_MESSAGE)) {
        return;
      }
      // Issue callback with new run document.
      onStartLinkedRun(contentIndex, linkedRun);
      const newRunLink = runViewPath(currentTeamId, linkedRun._id);
      if (event?.metaKey || event?.ctrlKey) {
        window.open(newRunLink);
      } else {
        history.push(newRunLink);
      }
    },
    [onStartLinkedRun, hasInvalidExternalItems, contentIndex, history, currentTeamId]
  );

  const runProcedureLink = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (typeof onStartLinkedRun !== 'function') {
        return;
      }
      if (!linkedProcedure) {
        return;
      }

      // Warn the user if any part of the procedure has potential changes
      if (!(await confirmPendingChanges(linkedProcedure))) {
        return;
      }

      if (isProcedureWithBatchSteps(linkedProcedure)) {
        setShowBatchRunModal(true);
        return;
      }

      // Create new run document.
      const linkedRun = await createLinkedProcedureRun(linkedProcedure);
      startLinkedRun(linkedRun, event);
    },
    [onStartLinkedRun, linkedProcedure, confirmPendingChanges, createLinkedProcedureRun, startLinkedRun]
  );

  const onStartBatchRun = useCallback(
    async (batchSize: number) => {
      const procedureToRun = cloneDeep(linkedProcedure) as Procedure;
      prepareBatchStepsForRun(procedureToRun, batchSize);
      const run = await createLinkedProcedureRun(procedureToRun);
      startLinkedRun(run, undefined);
    },
    [linkedProcedure, createLinkedProcedureRun, startLinkedRun]
  );

  const onCancelBatchRun = useCallback(() => {
    setShowBatchRunModal(false);
  }, [setShowBatchRunModal]);

  const displaySection = useMemo(() => {
    if (linkedSectionIndex !== null) {
      return `[Section ${procedureUtil.displaySectionKey(
        linkedSectionIndex,
        getSetting('display_sections_as', 'letters')
      )}]`;
    }
    return null;
  }, [linkedSectionIndex, getSetting]);

  const displayLinkText = useMemo(() => {
    if (link.run && run) {
      return <RunLabel code={run.code} runNumber={run.run_number} runNumberBg="medium" />;
    }
    if (linkedProcedure && linkedProcedure.code && linkedProcedure.name) {
      return `${linkedProcedure.code} - ${linkedProcedure.name}${displaySection ? ` ${displaySection}` : ''}`;
    }
    return null;
  }, [displaySection, linkedProcedure, run, link]);

  const showRunAction = useMemo(() => {
    if (!linkedProcedure) {
      return false;
    }
    // Check if the section exists in the procedure
    if (hasSection && !containsLinkedSection) {
      return false;
    }
    return !linkedProcedure.archived && auth.hasPermission(PERM.RUNS_EDIT, linkedProcedure.project_id);
  }, [linkedProcedure, hasSection, containsLinkedSection, auth]);

  const runStepCounts = useMemo(() => {
    return runUtil.getRunStepCounts(run);
  }, [run]);

  const pathToRunOrProcedure = useMemo(() => {
    if (link.run) {
      return runViewPath(currentTeamId, link.run);
    }
    const sourceUrl = `${history.location.pathname}${history.location.search ?? ''}`;

    return procedureSnapshotViewPathWithSourceUrl({
      teamId: currentTeamId,
      procedureId: link.procedure,
      sectionId: link.section,
      sourceUrl,
      sourceName,
    });
  }, [link, currentTeamId, sourceName, history.location]);

  const marginClasses = onStartLinkedRun && !multiLine ? 'mt-3 ml-4 mr-8' : '';

  // Layout is intended for CSS grid template defined in Run.js and Procedure.js.
  const content = (
    <>
      {showBatchRunModal && <RunBatchProcedureModal onRun={onStartBatchRun} onCancel={onCancelBatchRun} />}
      <div className={generateHiddenClassString(`${marginClasses} flex page-break`, isHidden)}>
        {blockLabel && <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={false} />}
        {linkedProcedure && (
          <div className={`flex ${multiLine ? 'flex-col' : 'flex-row'} flex-grow justify-between items-center`}>
            {/* Render procedure link definition. */}
            <div className={`flex gap-x-2 ${multiLine ? 'self-start' : 'self-center'} mr-2`}>
              {showLabels && <div className="whitespace-nowrap">Linked procedure:</div>}
              {displayLinkText && (
                <Link aria-label="Linked procedure title" className="link" to={pathToRunOrProcedure}>
                  {displayLinkText}
                </Link>
              )}
            </div>
            {/* Render procedure archived message */}
            {linkedProcedure.archived && (
              <div className="ml-2 pt-2 italic text-sm flex items-end self-center">
                (This procedure has been archived.)
              </div>
            )}
            {!linkedProcedure.archived && hasSection && !containsLinkedSection && (
              <div className="ml-2 pt-2 italic text-sm flex items-end self-center">
                (This section has been removed.)
              </div>
            )}
            {/* Render run procedure link. */}
            {!link.run && runUtil.isRunStateActive(docState) && (
              <>
                {showRunAction && (
                  <div className="self-start pt-0">
                    {onStartLinkedRun !== undefined && (
                      <button className="btn-small" disabled={!isEnabled} onClick={runProcedureLink}>
                        Run
                      </button>
                    )}
                  </div>
                )}
              </>
            )}
            {/* Render link to generated procedure run. */}
            {link.run && run && (
              <div
                className={generateHiddenClassString(
                  `${spreadContent ? 'self-center' : 'self-start'} pt-0 w-56`,
                  isHidden
                )}
              >
                <RunProgressBar stepCounts={runStepCounts.runCounts} runStatus={runStatus} showStatus={true} />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );

  return wrapInsideTable ? (
    <tr>
      <td></td>
      <td colSpan={2}>{content}</td>
    </tr>
  ) : (
    content
  );
};

export default BlockProcedureLink;
