import React, { Fragment, useMemo, useState, useCallback } from 'react';
import { isEqual } from 'lodash';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import validateUtil from '../lib/validateUtil';
import { BlockTypes } from './Blocks/BlockTypes';
import FieldSetProcedureStep from './FieldSetProcedureStep';
import { useProcedureContext } from '../contexts/ProcedureContext';
import runUtil, { RUN_STATE } from '../lib/runUtil';
import { useRunContext } from '../contexts/RunContext';
import MenuSubmitSuggestedEdit from './MenuSubmitSuggestedEdit';
import { Form, Formik } from 'formik';
import { selectProceduresNoDraftsForReleased } from '../contexts/proceduresSlice';
import { useStore } from 'react-redux';
import attachmentUtil from '../lib/attachmentUtil';

const CONFIRM_CANCEL_MSG = 'Are you sure you want to cancel? Your changes will be lost';

const ProcedureStepEdit = ({
  step,
  isPending,
  docState,
  precedingStepId,
  onSave,
  onCancel,
  configurePartKitBlock,
  configurePartBuildBlock,
}) => {
  const store = useStore();
  const [stepValues, setStepValues] = useState(step);
  const [stepErrors, setStepErrors] = useState(null);

  /** @type {{ services: import('../contexts/proceduresSlice').DatabaseServices; currentTeamId: string }} */
  const { services, currentTeamId } = useDatabaseServices();
  const { procedure } = useProcedureContext();
  const { isUserParticipant } = useRunContext();

  const isDirty = useMemo(() => !isEqual(stepValues, step), [stepValues, step]);

  const onStepFormChanged = useCallback(
    (values) => {
      if (!stepValues) {
        return;
      }
      const cleaned = {
        id: stepValues.id,
        ...values,
      };
      if (isEqual(cleaned, stepValues)) {
        return;
      }
      setStepValues(cleaned);
    },
    [stepValues]
  );

  const onSaveStep = useCallback(
    async ({ editType }) => {
      const isRedline = editType !== 'Blueline';

      const sectionAndStepIds = validateUtil._getAllSectionAndStepIds(procedure);
      const procedureWithPendingStep = runUtil.getProcedureWithPendingStep(procedure, stepValues, precedingStepId);
      const procedureMap = validateUtil.getProcedureMap(procedureWithPendingStep);
      const procedures = selectProceduresNoDraftsForReleased(store.getState(), currentTeamId); // get state on demand to prevent re-rendering of component every time procedures change
      const { errors } = await validateUtil.validateStep({
        step: stepValues,
        teamId: currentTeamId,
        sectionAndStepIds,
        procedureMap,
        procedures,
        showRedlineValidation: true,
      });
      setStepErrors(errors);
      if (Object.keys(errors).length !== 0) {
        return;
      }
      await attachmentUtil.uploadAllFilesFromStep(stepValues, services.attachments);
      return onSave && onSave(stepValues, isRedline);
    },
    [procedure, stepValues, precedingStepId, store, currentTeamId, services.attachments, onSave]
  );

  const onCancelStep = useCallback(() => {
    if (isDirty && !window.confirm(CONFIRM_CANCEL_MSG)) {
      return;
    }
    return onCancel && onCancel();
  }, [isDirty, onCancel]);

  return (
    <Fragment>
      <tbody aria-label="Step" role="region">
        <tr>
          <td colSpan={3} className="px-8">
            {/* Step content */}
            <FieldSetProcedureStep
              step={stepValues}
              isPending={isPending}
              errors={stepErrors}
              isCollapsed={false}
              isStepHeadersEnabled={false}
              precedingStepId={precedingStepId}
              enabledContentTypes={[
                BlockTypes.Alert,
                BlockTypes.Text,
                BlockTypes.Attachment,
                BlockTypes.FieldInput,
                BlockTypes.TableInput,
                BlockTypes.Expression,
                BlockTypes.JumpTo,
                BlockTypes.ProcedureLink,
                BlockTypes.Commanding,
                BlockTypes.Telemetry,
                BlockTypes.Requirement,
                BlockTypes.ExternalItem,
                BlockTypes.Reference,
                BlockTypes.Conditionals,
                BlockTypes.Dependencies,
                BlockTypes.PartKit,
                BlockTypes.PartBuild,
                BlockTypes.PartUsage,
                BlockTypes.InventoryDetailInput,
                BlockTypes.ToolCheckOut,
                BlockTypes.ToolCheckIn,
                BlockTypes.ToolUsage,
                BlockTypes.TestCases,
              ]}
              onFieldRefChanged={() => {}}
              onStepCollapse={() => {}}
              onStepFormChanged={onStepFormChanged}
              configurePartKitBlock={configurePartKitBlock}
              configurePartBuildBlock={configurePartBuildBlock}
            />
            <Formik initialValues={{ editType: 'Redline' }} onSubmit={onSaveStep}>
              {({ handleSubmit }) => (
                <Form onSubmit={handleSubmit} className="w-full">
                  <MenuSubmitSuggestedEdit
                    isRedlineSubmitDisabled={docState === RUN_STATE.PAUSED || !isUserParticipant}
                    isRedlineCancelDisabled={docState === RUN_STATE.PAUSED || !isUserParticipant}
                    onRedlineCancel={onCancelStep}
                    onSubmit={handleSubmit}
                    runOnly={false} // this component renders the inital added step cancel/save so we always want to present both red and bluelines in the save dropdown
                  />
                </Form>
              )}
            </Formik>
          </td>
        </tr>
      </tbody>
      <tbody>
        <tr className="h-4"></tr>
      </tbody>
    </Fragment>
  );
};

export default ProcedureStepEdit;
