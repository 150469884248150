import cloneDeep from 'lodash.clonedeep';
import procedureUtil from './procedureUtil';
import percentUtil from './percentUtil';
import { FEATURE_RUN_PARTICIPANT_TOGGLE_ENABLED } from '../config';
import { CONTENT_TYPE_PROCEDURE_LINK } from '../components/FieldSetProcedureLink';
import { CONTENT_TYPE_TELEMETRY } from '../components/FieldSetTelemetry';
import _, { partition, uniq } from 'lodash';
import { durationToComponents, durationToDhms, getDurationComponentLabel } from './datetime';
import signoffUtil from 'shared/lib/signoffUtil';
import {
  ACTION_TYPE,
  STEP_STATE,
  copyStepWithoutActiveContent,
  newRunDoc,
  isStepEnded,
  getStepState,
} from 'shared/lib/runUtil';
import sharedDiffUtil from 'shared/lib/diffUtil';
import ProcedureGraph from 'shared/lib/ProcedureGraph';

export const ACTION_DESCRIPTION = {
  [ACTION_TYPE.SIGNOFF]: 'Signoff',
  [ACTION_TYPE.REVOKE_SIGNOFF]: 'Signoff revoked',
  [ACTION_TYPE.FAIL]: 'Marked as failed',
  [ACTION_TYPE.SKIP]: 'Skipped',
  [ACTION_TYPE.COMPLETE]: 'Submitted',
  [ACTION_TYPE.ISSUE]: 'created',
  [ACTION_TYPE.PAUSE]: 'Run Paused',
  [ACTION_TYPE.RESUME]: 'Run Resumed',
  [ACTION_TYPE.AUTOMATION_PAUSE]: 'Automation Paused',
  [ACTION_TYPE.AUTOMATION_RESUME]: 'Automation Resumed',
  [ACTION_TYPE.AUTOMATION_START]: 'Automation Started',
  [ACTION_TYPE.ISSUE_PAUSE]: 'Critical Issue Created',
  [ACTION_TYPE.ALL_ISSUES_RESOLVED]: 'All Issues Resolved',
  [ACTION_TYPE.STEP_ADDED]: 'Step added',
};

export const getActionDescription = (action) => {
  if (action.type === ACTION_TYPE.REVOKE_SIGNOFF) {
    return `Signoff ${action.revoked_operator ?? ''} revoked`;
  }
  return ACTION_DESCRIPTION[action.type];
};

export const getIconForActionType = (type) => {
  switch (type) {
    case ACTION_TYPE.SIGNOFF:
      return 'check-circle';
    case ACTION_TYPE.REVOKE_SIGNOFF:
      return 'times-circle';
    case ACTION_TYPE.FAIL:
      return 'exclamation-circle';
    case ACTION_TYPE.SKIP:
      return 'step-forward';
    case ACTION_TYPE.COMPLETE:
      return 'check-circle';
    case ACTION_TYPE.ISSUE:
      return 'file-circle-exclamation';
    case ACTION_TYPE.AUTOMATION_PAUSE:
    case ACTION_TYPE.AUTOMATION_RESUME:
      return 'bolt';
    case ACTION_TYPE.STEP_ADDED:
      return 'square-plus';
    default:
      return 'exclamation-circle';
  }
};

export const getColorForActionType = (type) => {
  switch (type) {
    case ACTION_TYPE.SIGNOFF:
      return 'bg-green-200';
    case ACTION_TYPE.REVOKE_SIGNOFF:
      return 'bg-gray-200';
    case ACTION_TYPE.FAIL:
      return 'bg-red-200';
    case ACTION_TYPE.SKIP:
      return 'bg-gray-200';
    case ACTION_TYPE.COMPLETE:
      return 'bg-slate-200';
    case ACTION_TYPE.ISSUE:
      return 'bg-indigo-200';
    case ACTION_TYPE.AUTOMATION_PAUSE:
      return 'bg-blue-200';
    default:
      return 'bg-gray-200';
  }
};

export const PARTICIPANT_TYPE = {
  PARTICIPATING: 'participant',
  VIEWING: 'viewer',
};

export const RUN_STATE = {
  RUNNING: 'running',
  COMPLETED: 'completed',
  PAUSED: 'paused',
};

export const ACTIVE_RUN_STATES = [RUN_STATE.RUNNING, RUN_STATE.PAUSED];

/**
 * @deprecated use {@link file://./runUtilTs.ts} instead
 */
const runUtil = {
  // Returns run name with section if needed. Eg, "Engine test", "Engine test [Section B]"
  displayName: (run, config) => {
    if (!run) {
      return null;
    }
    let name = run.name;

    if (run.run_section !== undefined && run.run_section !== null && !isNaN(parseInt(run.run_section))) {
      const index = parseInt(run.run_section);
      name = `${name} [Section ${procedureUtil.displaySectionKey(index, config && config.display_sections_as)}]`;
    }
    return name;
  },

  //Base Functions for Run and Section

  getTotalStepsCount: (sectionList) => {
    if (!sectionList || !sectionList.length) {
      return 0;
    }
    return sectionList.reduce((sum, section) => sum + section.steps.length, 0);
  },

  /*
   * Returns the number of steps in run matching criteria defined in filterFunc
   * sectionList: the list of sections in which to count steps
   * filterFunc: predicate that accepts a Step object and returns true if the step should be included in the count
   */
  getStepsCountFiltered: (sectionList, filterFunc) => {
    if (!sectionList || !sectionList.length) {
      return 0;
    }
    return sectionList.reduce((sum, section) => sum + section.steps.filter(filterFunc).length, 0);
  },

  getCompletedStepsCount: (sectionList) =>
    runUtil.getStepsCountFiltered(sectionList, (step) => getStepState(step) === STEP_STATE.COMPLETED),

  getSkippedStepsCount: (sectionList) =>
    runUtil.getStepsCountFiltered(sectionList, (step) => getStepState(step) === STEP_STATE.SKIPPED),

  getFailedStepsCount: (sectionList) =>
    runUtil.getStepsCountFiltered(sectionList, (step) => getStepState(step) === STEP_STATE.FAILED),

  getEndedStepsCount: (sectionList) => runUtil.getStepsCountFiltered(sectionList, (step) => isStepEnded(step)),

  getCompletedPercent: (sectionList) => {
    const num = runUtil.getCompletedStepsCount(sectionList);
    const denom = runUtil.getTotalStepsCount(sectionList);
    return percentUtil.toPercent(num, denom);
  },

  getFailedPercent: (sectionList) => {
    const num = runUtil.getFailedStepsCount(sectionList);
    const denom = runUtil.getTotalStepsCount(sectionList);
    return percentUtil.toPercent(num, denom);
  },

  getSkippedPercent: (sectionList) => {
    const num = runUtil.getSkippedStepsCount(sectionList);
    const denom = runUtil.getTotalStepsCount(sectionList);
    return percentUtil.toPercent(num, denom);
  },

  getEndedPercent: (sectionList) => {
    const num = runUtil.getEndedStepsCount(sectionList);
    const denom = runUtil.getTotalStepsCount(sectionList);
    return percentUtil.toPercent(num, denom);
  },

  getRunSummaryPercent: (summary) => {
    const num = summary.completed_steps + summary.skipped_steps + summary.failed_steps;
    const denom = summary.total_steps - summary.not_required_steps;
    const percentEnded = percentUtil.toPercent(num, denom);

    // Special case when both numerator/denominator are zero e.g. entire run cannot complete yet
    if (percentEnded === undefined) {
      return 0;
    }
    return percentEnded;
  },

  /*
   * Completed percent rounded to nearest percent
   * Return type is a number, e.g., 67
   */
  displayCompletedPercent: (sectionList) => {
    const percent = runUtil.getCompletedPercent(sectionList);
    return percentUtil.toNearestPercent(percent);
  },

  /*
   * Failed percent rounded to nearest percent
   * Return type is a number, e.g., 67
   */
  displayFailedPercent: (sectionList) => {
    const percent = runUtil.getFailedPercent(sectionList);
    return percentUtil.toNearestPercent(percent);
  },

  /*
   * Skipped percent rounded to nearest percent
   * Return type is a number, e.g., 67
   */
  displaySkippedPercent: (sectionList) => {
    const percent = runUtil.getSkippedPercent(sectionList);
    return percentUtil.toNearestPercent(percent);
  },

  /*
   * Parallel Set of Functions for Run
   */
  getRunCompletedPercent: (run) => (run ? runUtil.getCompletedPercent(runUtil.runSectionAndRepeats(run)) : 0),

  getRunSkippedPercent: (run) => (run ? runUtil.getSkippedPercent(runUtil.runSectionAndRepeats(run)) : 0),

  displayRunCompletedPercent: (run) => (run ? runUtil.displayCompletedPercent(runUtil.runSectionAndRepeats(run)) : 0),

  displayRunFailedPercent: (run) => (run ? runUtil.displayFailedPercent(runUtil.runSectionAndRepeats(run)) : 0),

  displayRunSkippedPercent: (run) => (run ? runUtil.displaySkippedPercent(runUtil.runSectionAndRepeats(run)) : 0),

  /*
   * Parallel Set of Functions for Section
   */

  getSectionCompletedPercent: (section) => (section ? runUtil.getCompletedPercent([section]) : 0),

  getSectionFailedPercent: (section) => (section ? runUtil.getFailedPercent([section]) : 0),

  getSectionSkippedPercent: (section) => (section ? runUtil.getSkippedPercent([section]) : 0),

  getSectionEndedPercent: (section) => (section ? runUtil.getEndedPercent([section]) : 0),

  _getSectionStats: (section, runGraph) => {
    let totalCount = 0;
    let completedCount = 0;
    let notRequiredCount = 0;
    let skippedCount = 0;
    let failedCount = 0;
    section.steps?.forEach((step) => {
      const stepState = getStepState(step);
      if (stepState === STEP_STATE.SKIPPED) {
        skippedCount += 1;
      } else if (stepState === STEP_STATE.COMPLETED) {
        completedCount += 1;
      } else if (stepState === STEP_STATE.FAILED) {
        failedCount += 1;
      } else if (!runGraph.areRequirementsMet(step.id)) {
        notRequiredCount += 1;
      }
      totalCount += 1;
    });
    return {
      totalCount,
      completedCount,
      notRequiredCount,
      skippedCount,
      failedCount,
    };
  },

  getRunStepCounts: (run, runGraph = new ProcedureGraph(run)) => {
    let runTotalCount = 0;
    let runCompletedCount = 0;
    let runNotRequiredCount = 0;
    let runSkippedCount = 0;
    let runFailedCount = 0;
    const sectionStepCounts = new Map();

    if (run) {
      const sectionList = runUtil.runSectionAndRepeats(run);
      sectionList.forEach((section) => {
        const sectionStepCount = runUtil._getSectionStats(section, runGraph);
        runTotalCount += sectionStepCount.totalCount;
        runCompletedCount += sectionStepCount.completedCount;
        runNotRequiredCount += sectionStepCount.notRequiredCount;
        runSkippedCount += sectionStepCount.skippedCount;
        runFailedCount += sectionStepCount.failedCount;
        sectionStepCounts.set(section.id, sectionStepCount);
      });
    }
    return {
      runCounts: {
        totalCount: runTotalCount,
        completedCount: runCompletedCount,
        notRequiredCount: runNotRequiredCount,
        skippedCount: runSkippedCount,
        failedCount: runFailedCount,
      },
      sectionCounts: sectionStepCounts,
    };
  },

  getRunStatus: (run) => {
    return {
      id: run?._id,
      status: run?.status,
      state: run?.state,
    };
  },

  /**
   * @param {import('shared/lib/types/views/procedures').Procedure | null | undefined} procedure
   */
  isRun: (procedure) => {
    return Boolean(procedure && procedure.state && Object.values(RUN_STATE).includes(procedure.state));
  },

  runHasUnfinishedSteps: (runCounts) => {
    if (!runCounts) {
      return false;
    }
    return (
      runCounts.completedCount + runCounts.notRequiredCount + runCounts.skippedCount + runCounts.failedCount <
      runCounts.totalCount
    );
  },

  /**
   * Returns a list of all linked procedure Ids
   */
  getLinkedProcedureRunIds: (run) => {
    const linkedProcedureIds = [];
    if (!run) {
      return linkedProcedureIds;
    }
    for (const section of run.sections) {
      for (const step of section.steps) {
        for (const block of step.content) {
          if (block.type === CONTENT_TYPE_PROCEDURE_LINK && block.run) {
            linkedProcedureIds.push(block.run);
          }
        }
      }
    }
    return linkedProcedureIds;
  },

  /**
   * Returns whether an element has a repeat, i.e., if the provided index holds
   * an element that is not the last instance (repeated or not) of the original
   * element. A repeat is
   *
   * repeatables: array of elements with a repeat_of field in which to find if that
   *              there exists a repeat of the element at the specified index.
   * index: index of the step or section used to check if that step or section is
   *        repeated.
   *
   * returns: boolean that is true if step or section located at index has a repeat.
   */
  hasARepeat: (repeatables, index) => {
    // TODO (jon): improve error handling for these types of scenarios
    if (!repeatables || index >= repeatables.length || index < 0) {
      return false;
    }
    // last step in section
    if (index === repeatables.length - 1) {
      return false;
    }
    return repeatables[index].id === repeatables[index + 1].repeat_of;
  },

  isStepComplete: (step) => getStepState(step) === STEP_STATE.COMPLETED,

  getStepEndedTimestamp: (step) => {
    const stepState = getStepState(step);

    if (stepState === STEP_STATE.COMPLETED) {
      return step.completedAt;
    }
    if (stepState === STEP_STATE.SKIPPED) {
      return step.skippedAt;
    }
    if (stepState === STEP_STATE.FAILED) {
      return runUtil.getFailedAction(step)?.timestamp;
    }
  },

  setStepEndedTimestamp: (step, timestamp) => {
    const stepState = getStepState(step);

    if (stepState === STEP_STATE.COMPLETED) {
      step.completedAt = timestamp;
    }
    if (stepState === STEP_STATE.SKIPPED) {
      step.skippedAt = timestamp;
    }
    if (stepState === STEP_STATE.FAILED) {
      const failedStep = runUtil.getFailedAction(step);
      if (failedStep.timestamp) {
        failedStep.timestamp = timestamp;
      }
    }
  },

  // Returns the number of step or section repeats up to the provided index
  _repeatsUpToIndex: (repeatables, index) => {
    return repeatables.filter((elem, i) => i <= index && elem.repeat_of).length;
  },

  // Returns the number of step or section additions up to the provided index
  _runAdditionsUpToIndex: (stepOrSectionsList, index) => {
    return stepOrSectionsList.filter((elem, i) => i <= index && elem.created_during_run).length;
  },

  // Returns step key, e.g., "1", "2", etc., taking into account repeated steps
  displayStepKey: (steps, stepIndex) => {
    const repeatsUpToIndex = runUtil._repeatsUpToIndex(steps, stepIndex);
    return procedureUtil.displayStepKey(stepIndex - repeatsUpToIndex);
  },

  // Returns section key, e.g., "A", "B", etc., taking into account repeated sections
  displaySectionKey: (sections, sectionIndex, style) => {
    const repeatsUpToIndex = runUtil._repeatsUpToIndex(sections, sectionIndex);
    return procedureUtil.displaySectionKey(sectionIndex - repeatsUpToIndex, style);
  },

  // Returns combined step and section key, e.g., "B2", taking into account repeats
  displaySectionStepKey: (sections, sectionIndex, stepIndex, style) => {
    const repeatsSectionUpToIndex = sectionIndex - runUtil._repeatsUpToIndex(sections, sectionIndex);
    const repeatsStepUpToIndex = stepIndex - runUtil._repeatsUpToIndex(sections[sectionIndex].steps, stepIndex);
    return procedureUtil.displaySectionStepKey(repeatsSectionUpToIndex, repeatsStepUpToIndex, style);
  },

  /**
   * Returns "X", where "X" is the number of the repeat (1-indexed) as a string.
   * Returns empty string if the element is not repeated
   */
  displayRepeatKey: (repeatables, index) => {
    if (!repeatables || index >= repeatables.length || index < 0) {
      return '';
    }
    if (!repeatables[index].repeat_of) {
      return '';
    }
    let repeatNumber = 0;
    let i = index;
    while (i >= 0 && repeatables[i].repeat_of) {
      if (i > 0 && i <= repeatables.length) {
        if (repeatables[i].repeat_of !== repeatables[i - 1].id) {
          /*
           * Something went wrong. The repeat_of field doesn't correspond to the
           * correct id
           */
          return '';
        }
      }
      repeatNumber++;
      i--;
    }
    return String(repeatNumber);
  },

  // Returns run with only specified section and its repeats
  runSectionAndRepeats: (run) => {
    if (!run) {
      return [];
    }
    // Return all sections if run_section not specified.
    if (run.run_section === undefined || run.run_section === null) {
      return run.sections;
    }
    // Return only the section at index runSectionIndex and its repeats.
    const sections = [];
    let sectionIsParent = false;
    run.sections.forEach((section, sectionIndex) => {
      const runSectionIndex = parseInt(run.run_section); // cast to number
      if (sectionIndex === runSectionIndex || (sectionIsParent && section.repeat_of)) {
        sections.push(section);
        sectionIsParent = true;
      } else {
        sectionIsParent = false;
      }
    });
    return sections;
  },

  /**
   * Returns the index of the parent step or section (the index of the step or section
   * in the original procedure) given the index of a step or section in the run.
   *
   * The index of the step or section in the run may be different than the index of
   * the step or section in the procedure because of repeats and additions.
   *
   * stepsOrSectionsList: array of steps or sections which may contain repeats and additions.
   *              Repeats have a repeat_of field and additions have created_during_run: true.
   * index: index of the step or section of interest.
   *
   * returns: the index of the parent step or section. Returns -1 if the step has no
   *          parent, which occurs if the step was added during the run at the
   *          beginning of a section.
   *
   * TODO (jon): refactor this to use ids only and not indexes.
   */
  getOriginalProcedureIndex: (stepsOrSectionsList, index) => {
    /**
     * The index of a step or section in the procedure is the index of that step or
     * section in the run if the run were to have:
     *
     * - no repeats
     * - no steps or sections added during the run
     */
    const numRepeatsUpToIndex = runUtil._repeatsUpToIndex(stepsOrSectionsList, index);
    const numAdditionsUpToIndex = runUtil._runAdditionsUpToIndex(stepsOrSectionsList, index);
    return index - numRepeatsUpToIndex - numAdditionsUpToIndex;
  },

  /**
   * Searches a run document for the given section and steps by id.
   *
   * @param run - The current run document.
   * @param sectionId {string} - A section id.
   * @param stepId {string} - A step id.
   * @returns An object with { sectionIndex, stepIndex } if found,
   *          otherwise throws an error.
   * @throws Error getting the section index or step index, if either the section or step ID cannot be found.
   */
  getSectionAndStepIndices: (run, sectionId, stepId) => {
    const sectionIndex = runUtil.getSectionIndex(run, sectionId);
    const stepIndex = runUtil.getStepIndex(run, stepId, sectionIndex);
    return { sectionIndex, stepIndex };
  },

  getSectionIndex: (run, sectionId) => {
    const sectionIndex = run.sections.findIndex((section) => section.id === sectionId);
    if (sectionIndex === -1) {
      throw new Error(`Invalid section index for section ID ${sectionId}.`);
    }
    return sectionIndex;
  },

  getStepIndex: (run, stepId, sectionIndex) => {
    if (sectionIndex < 0 || sectionIndex >= run.sections.length) {
      throw new Error(`Invalid section index when getting step ID ${stepId}.`);
    }
    const stepIndex = run.sections[sectionIndex].steps.findIndex((step) => step.id === stepId);
    if (stepIndex === -1) {
      throw new Error(`Invalid step index for step ID ${stepId}.`);
    }
    return stepIndex;
  },

  // Returns the original (without repeats) sectionIndex and stepIndex
  findOriginalStepIndexPath: (run, sectionId, stepId) => {
    const originalSections = run.sections.filter((section) => !section.repeat_of);
    const sectionIndex = originalSections.findIndex((section) => section.id === sectionId);

    if (sectionIndex === -1) {
      return null;
    }

    const originalSteps = originalSections[sectionIndex].steps.filter(runUtil._isOriginalStep);
    const stepIndex = originalSteps.findIndex((step) => step.id === stepId);

    if (stepIndex === -1) {
      return null;
    }

    return {
      sectionIndex,
      stepIndex,
    };
  },

  // Returns all repeats of given id.
  getRepeats: (repeatables, id) => {
    const repeatsArray = [];
    let parentId = id;

    repeatables.forEach((repeatable) => {
      if (repeatable.repeat_of === parentId) {
        parentId = repeatable.id;

        repeatsArray.push(repeatable);
      }
    });

    return repeatsArray;
  },

  _isOriginalStep: (step) => !step.repeat_of && !step.created_during_run,

  /**
   * Returns the latest repeat id's of the section or step (includes the step header id for a step).
   * If the ids for an added step are passed in, return data for the added step, since added steps are not copied to repeated sections
   */
  getLatestRepeat: (run, originalSectionId, originalStepId) => {
    const originalSection = run.sections.find((section) => section.id === originalSectionId);
    if (originalStepId) {
      const originalStep = originalSection.steps.find((step) => step.id === originalStepId);
      if (originalStep.created_during_run) {
        return {
          sectionId: originalSectionId,
          stepId: originalStepId,
          stepHeaderId: procedureUtil.getStepHeaderId(originalStep),
        };
      }
    }
    const matchingSectionRepeats = runUtil.getRepeats(run.sections, originalSectionId);
    const latestRepeatedSection = matchingSectionRepeats.length
      ? matchingSectionRepeats[matchingSectionRepeats.length - 1]
      : originalSection;
    const latestRepeatedSectionId = latestRepeatedSection.id;

    let latestRepeatedStepId;
    let latestRepeatedStepHeaderId;

    if (originalStepId) {
      const stepIndex = runUtil.findOriginalStepIndexPath(run, originalSectionId, originalStepId)?.stepIndex;
      const repeatedStep = latestRepeatedSection.steps.filter(runUtil._isOriginalStep)[stepIndex];
      const repeatedStepId = repeatedStep.id;
      const repeatedStepHeaderId = procedureUtil.getStepHeaderId(repeatedStep);
      latestRepeatedStepId = repeatedStepId;
      latestRepeatedStepHeaderId = repeatedStepHeaderId;

      const matchingStepRepeats = runUtil.getRepeats(latestRepeatedSection.steps, repeatedStepId);
      if (matchingStepRepeats.length) {
        const latestRepeatedStepIndex = matchingStepRepeats.length - 1;
        const latestRepeatedStep = matchingStepRepeats[latestRepeatedStepIndex];
        latestRepeatedStepId = latestRepeatedStep.id;
        latestRepeatedStepHeaderId = procedureUtil.getStepHeaderId(latestRepeatedStep);
      }
    }

    return {
      sectionId: latestRepeatedSectionId,
      stepId: latestRepeatedStepId,
      stepHeaderId: latestRepeatedStepHeaderId,
    };
  },

  /**
   * Returns the original id's of the section or step (includes the step header id for a step) for a diffed procedure.
   * Assumes there are no repeated or added steps since this for diffs for reviews only.
   */
  getLatestRepeatForDiff: (run, originalSectionId, originalStepId) => {
    const originalSection = run.sections.find(
      (section) =>
        sharedDiffUtil.getDiffValue(section, 'id', 'new') === originalSectionId ||
        section.id === `${originalSectionId}__removed`
    );
    let originalStep;
    let originalStepHeaderId;
    if (originalStepId) {
      originalStep = originalSection.steps.find(
        (step) =>
          sharedDiffUtil.getDiffValue(step, 'id', 'new') === originalStepId || step.id === `${originalStepId}__removed`
      );
      if (originalStep) {
        const originalStepHeaderIdField = procedureUtil.getStepHeaderId(originalStep);
        originalStepHeaderId = sharedDiffUtil.getDiffValue({ id: originalStepHeaderIdField }, 'id', 'new');
      }
    }

    return {
      sectionId: originalSection ? sharedDiffUtil.getDiffValue(originalSection, 'id', 'new') : undefined,
      stepId: originalStep ? sharedDiffUtil.getDiffValue(originalStep, 'id', 'new') : undefined,
      stepHeaderId: originalStepHeaderId && typeof originalStepHeaderId === 'string' ? originalStepHeaderId : undefined,
    };
  },

  /**
   * Checks if the step is a repeat of another step
   *
   * @param {Object} step
   * @returns {Boolean} - true if the step is a repeat
   */
  isStepRepeat: (step) => Boolean(step.repeat_of),

  /**
   * Checks if the section is a repeat of another section
   *
   * @param {Object} section
   * @returns {Boolean} - true if the section is a repeat
   */
  isSectionRepeat: (section) => Boolean(section.repeat_of),

  /**
   * Returns an array of sections or steps without any repeats.
   * It will filter out any sections or steps with the repeat_of property set to a truthy value.
   *
   * @param {Array} repeatables - An array of steps or sections.
   * @returns {Array} - An array of steps or sections without repeats.
   */
  getCollectionWithoutRepeats: (repeatables) => {
    return repeatables.filter((rep) => !rep.repeat_of);
  },

  /**
   * Returns an array of sections or steps without any additions.
   * It will filter out any sections or steps where created_during_run is true.
   *
   * @param {Array} stepsOrSectionsList - An array of steps or sections.
   * @returns {Array} - An array of steps or sections without additions.
   */
  getCollectionWithoutAdditions: (stepsOrSectionsList) => {
    return stepsOrSectionsList.filter((stepOrSection) => {
      return !stepOrSection.created_during_run;
    });
  },

  /**
   * Returns ids of all attachments recorded in step.
   *
   * @param {Object} recorded - The recorded property of a content block.
   * @returns {Array} array of ids of all attachments recorded in step.
   */
  getBlockRecordedAttachmentIds: (recorded) => {
    const attachmentIds = [];
    if (recorded?.value?.attachment_id) {
      attachmentIds.push(recorded?.value?.attachment_id);
    }
    return attachmentIds;
  },

  /**
   * Returns ids of all attachments recorded in step.
   *
   * @param {Object} recorded - map of content index to content block.
   * @returns {Array} array of ids of all attachments recorded in step.
   */
  getFieldInputAttachmentIds: (recorded) => {
    if (!recorded) {
      return [];
    }

    // Pull ids out of recorded object
    return Object.values(recorded).flatMap((contentRecorded) => {
      return runUtil.getBlockRecordedAttachmentIds(contentRecorded);
    });
  },

  /**
   * Creates an initial version of a run doc for a given linked procedure.
   *
   * @param {Object} linkedProcedure
   * @param {Object} linkedProcedure.procedure
   * @param {number | null} linkedProcedure.linkedSectionIndex - The section index of the linked procedure
   *   to run. If not defined, all sections are run.
   *   TODO: there may be some legacy procedure data that stores procedureSection
   *         as a string. Audit the procedure data to see if we can drop "String".
   * @param {String} linkedProcedure.parentReferenceId - Run id of parent procedure.
   * @param {String} linkedProcedure.parentReferenceType - reference type of parent of linked procedure
   * @param {Object} [linkedProcedure.operation] - Operation object to set for linked procedure.
   *   Eg, { key: 'operation 2021', name: 'Operation 2021' }
   * @param {String} [linkedProcedure.userId] - Run id of parent procedure.
   * @param {'participant' | 'viewer'} [linkedProcedure.userParticipantType]
   * @returns {Object} - New run document for linked procedure.
   */
  newLinkedProcedureRunDoc: ({
    procedure,
    linkedSectionIndex,
    parentReferenceId,
    parentReferenceType,
    operation,
    userId,
    userParticipantType,
  }) => {
    const run = newRunDoc({ procedure, userId, userParticipantType });

    // Add custom fields for linked procedures.
    if (parentReferenceType === 'run') {
      run.source_run = parentReferenceId;
    } else {
      run.parent_reference = {
        id: parentReferenceId,
        type: parentReferenceType,
      };
    }

    // Operation propagates through to linked procedures/sections
    if (operation && operation.key && operation.name) {
      run.operation = operation;
    }
    if (linkedSectionIndex !== null) {
      run.run_section = `${linkedSectionIndex}`; // this should be a number
    }

    return run;
  },

  getIsUserParticipant: (run, userId) => {
    /*
     * Workaround to avoid editing all tests-- user is a participant if the
     * toggle is disabled, and the toggle is disabled in the tests so the
     * user is always a participant.
     */
    if (!FEATURE_RUN_PARTICIPANT_TOGGLE_ENABLED) {
      return true;
    }
    if (!run.participants) {
      return false;
    }
    return run.participants.some((participant) => {
      return participant.user_id === userId && participant.type === PARTICIPANT_TYPE.PARTICIPATING;
    });
  },

  /**
   * Gets all non-viewer participant ids for an array of runs.
   *
   * @param {Array<Pick<import('shared/lib/types/views/procedures').Run, 'participants'>>} runs
   * @returns {Array<string>}
   */
  getUserParticipantIds: (runs) => {
    return _.uniq(
      runs
        .map((run) =>
          run.participants
            ? run.participants.filter((p) => p.type === PARTICIPANT_TYPE.PARTICIPATING).map((p) => p.user_id)
            : []
        )
        .flat()
    );
  },

  getIsUserViewing: (run, userId) => {
    if (!run.participants) {
      return false;
    }
    return run.participants.some((participant) => {
      return participant.user_id === userId && participant.type === PARTICIPANT_TYPE.VIEWING;
    });
  },

  getIsUserParticipantOrViewing: (run, userId) => {
    const isParticipant = runUtil.getIsUserParticipant(run, userId);
    const isViewing = runUtil.getIsUserViewing(run, userId);
    return isParticipant || isViewing;
  },

  /**
   * Finds the step id that contains a link to the given running procedure.
   *
   * When starting a linked procedure, the link to the running procedure is
   * stored in the `procedure_link` content block. We don't do any backlinking,
   * so we just search the parent document.
   *
   * @param {Object} run - A run document.
   * @param {string} linkedRunId - Id of running procedure linked from `run`.
   * @returns {string | undefined} - Step id of the step that contains the linked run.
   */
  linkedRunStepId: (run, linkedRunId) => {
    if (!run || !linkedRunId) {
      return;
    }
    for (const section of run.sections) {
      for (const step of section.steps) {
        for (const block of step.content) {
          if (block.type === CONTENT_TYPE_PROCEDURE_LINK && block.run === linkedRunId) {
            return step.id;
          }
        }
      }
    }
  },

  getOperatorRolesInRun: (run) => {
    if (!run) {
      return null;
    }
    let operatorRoles = [];
    for (const section of run.sections) {
      for (const step of section.steps) {
        if (step.signoffs) {
          for (const signoff of step.signoffs) {
            operatorRoles = operatorRoles.concat(signoff.operators);
          }
        }
      }
    }
    return [...new Set(operatorRoles)]; // Remove duplicates
  },

  /**
   * Returns a cleaned copy of a section intended for repeating a section.
   *
   * Removes recorded, signoff, or completion data, and any added steps added
   * during the run.
   *
   * @param {Object} section - A running procedure section object.
   * @returns {Object} - An updated copy of the section.
   */
  copySectionWithoutActiveContent: (section) => {
    const updated = cloneDeep(section);
    delete updated.repeated_user_id;
    delete updated.repeated_at;
    delete updated.repeat_of;
    updated.steps = [];
    section.steps.forEach((step, stepIndex) => {
      // only copy last repeated instance of a step for redlines and update step id
      if (runUtil._isLastRepeat(section.steps, stepIndex)) {
        const updatedStep = copyStepWithoutActiveContent(step);
        updated.steps.push(updatedStep);
      }
    });
    // Filter out steps created during this run.
    updated.steps = runUtil.getCollectionWithoutAdditions(updated.steps);
    return updated;
  },

  // returns copy of block without recorded content.
  copyBlockWithoutActiveContent: (block) => {
    const updated = cloneDeep(block);
    delete updated.recorded;

    return updated;
  },

  getFailedAction: (step) => {
    const failedActionSingletonList = step.actions?.filter((a) => a.type === ACTION_TYPE.FAIL);
    return failedActionSingletonList?.length > 0 && failedActionSingletonList[0];
  },

  _isLastRepeat: (steps, stepIndex) => {
    return stepIndex === steps.length - 1 || !steps[stepIndex + 1].repeat_of;
  },

  /**
   * @param {Object} run - run object.
   * @returns {Map} A Map of Step Labels with the Key being the Step ID.
   */
  getStepsLabels: (run, sectionDisplaySetting) => {
    const stepLabelsMap = new Map();

    run?.sections?.forEach((section, sectionIndex) => {
      section.steps.forEach((step, stepIndex) => {
        const stepKey = procedureUtil.displaySectionStepKey(sectionIndex, stepIndex, sectionDisplaySetting);
        const stepId = step.id;
        stepLabelsMap.set(stepId, stepKey);
      });
    });
    return stepLabelsMap;
  },

  /**
   * @param {Object} run - run object.
   * @returns {Array} array of steps where the steps are fulfilled (completed, failed or skipped).
   */
  getStepsEnded: (run) => {
    const endedSteps = [];

    run?.sections?.forEach((section) => {
      section.steps.forEach((step) => {
        if (isStepEnded(step)) {
          endedSteps.push(step);
        }
      });
    });
    return endedSteps;
  },

  /**
   * @param {Object} run - run object.
   *
   * @param {String} sectionDisplaySetting - Display setting for step keys
   * @returns {Array} Returning metadata for step that are fulfilled (completed, failed or skipped).
   */
  getEndedStepsMetaData: (run, sectionDisplaySetting) => {
    const fullStepMetadata = runUtil.getStepsEnded(run);
    const endedStepsLablesMap = runUtil.getStepsLabels(run, sectionDisplaySetting);

    fullStepMetadata.forEach((step) => {
      const stepId = step.id;
      step.key = endedStepsLablesMap.get(stepId);
    });

    const necessaryStepsMetadata = [];

    let longestStepDurationMs = 0;
    //The duration of the steps combined will be less because total run duration is calculated based on when the run gets marked as completed not when the step gets completed.

    if (fullStepMetadata.length > 0) {
      fullStepMetadata.sort(
        (a, b) => +new Date(runUtil.getStepEndedTimestamp(a)) - +new Date(runUtil.getStepEndedTimestamp(b))
      );
      fullStepMetadata.forEach((step, index) => {
        const completedAt = new Date(runUtil.getStepEndedTimestamp(step)).getTime();
        const prevCompletedAt =
          index === 0
            ? new Date(run.starttime).getTime()
            : new Date(runUtil.getStepEndedTimestamp(fullStepMetadata[index - 1])).getTime();
        const durationInMilliseconds = completedAt - prevCompletedAt;
        const durationInDhms = durationToDhms(durationInMilliseconds);

        step.duration = durationInMilliseconds;
        step.durationInDhms = durationInDhms;
        if (durationInMilliseconds > longestStepDurationMs) {
          longestStepDurationMs = durationInMilliseconds;
        }
      });
    }

    const longestStepLabel = getDurationComponentLabel(longestStepDurationMs);

    //Adding only the values we need right now
    fullStepMetadata.forEach((step, index) => {
      const durationComponents = durationToComponents(step.duration);
      const normalizedDuration = durationComponents[longestStepLabel.long];
      const stepMetadata = {
        key: step.key,
        name: step.name,
        duration: step.duration,
        normalizedDuration,
        completed: step.completed,
        startedAt: step.startedAt,
        completedAt: step.completedAt,
        state: step.state,
        skipped: step.skipped,
        skippedAt: step.skippedAt,
        normalizedDurationUnits: longestStepLabel,
        orderCompletedIn: index + 1,
      };
      necessaryStepsMetadata.push(stepMetadata);
    });

    return necessaryStepsMetadata;
  },

  /**
   * @param {Object} run - run object.
   *
   * @returns {number} Returning total pause time in run.
   */
  getTotalPauseDuration: (run) => {
    const pauseTimeArray = runUtil.getPauseTimeArray(run.actions);
    let duration = 0;

    pauseTimeArray.forEach((pause) => {
      duration += pause.duration;
    });

    return duration;
  },

  /**
   *
   * @param {Object} run - run to map sections and steps from.
   * @returns {Object} of step ids mapping to a section id they belong to.
   */
  getStepToSectionIdMap: (run) => {
    const map = {};

    run.sections.forEach((section) => {
      section.steps.forEach((step) => {
        map[step.id] = section.id;
      });
    });

    return map;
  },

  getProcedureWithPendingStep: (procedure, pendingStep, precedingStepId) => {
    const procedureWithPendingStep = cloneDeep(procedure);

    for (let sectionIndex = 0; sectionIndex < procedure.sections.length; sectionIndex++) {
      const section = procedure.sections[sectionIndex];
      for (let stepIndex = 0; stepIndex < section.steps.length; stepIndex++) {
        const step = section.steps[stepIndex];
        if (step.id === precedingStepId) {
          const pendingStepCopy = cloneDeep(pendingStep);
          procedureWithPendingStep.sections[sectionIndex].steps.splice(stepIndex + 1, 0, pendingStepCopy);
          return procedureWithPendingStep;
        }
      }
    }

    // No change was made, return the original procedure
    return procedure;
  },

  // returns true if any content block in run contains telemetry
  hasTelemetry: (run) => {
    for (const section of run.sections) {
      for (const step of section.steps) {
        for (const block of step.content) {
          if (block.type === CONTENT_TYPE_TELEMETRY) {
            return true;
          }
        }
      }
    }
    return false;
  },

  allTelemetryInRun: (run) => {
    if (!run) {
      return [];
    }
    let allTelemetry = [];
    run.sections.forEach((section) => {
      section.steps.forEach((step) => {
        const telemetryStep = step.content.filter((content) => content.type.toLowerCase() === 'telemetry');
        allTelemetry = [...allTelemetry, ...telemetryStep];
      });
    });
    return allTelemetry;
  },

  isCompleted: (run) => run.state === RUN_STATE.COMPLETED,

  /**
   * Get the latest paused action
   * @param {Object} run - the run
   * @returns {Object} the latest paused action, if the procedure is paused, else return null
   */
  getLatestPausedAction: (run) => {
    // modify to get latest issue pause and risk pause if each of them are present. Might have to do this in Run.tsx
    if (
      !run ||
      !run.actions ||
      run.actions.length === 0 ||
      (run.state !== RUN_STATE.PAUSED && run.automation_status !== RUN_STATE.PAUSED)
    ) {
      return null;
    }
    const actions = [...run.actions].reverse();
    return actions.find(
      (action) =>
        action.type === ACTION_TYPE.PAUSE ||
        action.type === ACTION_TYPE.ISSUE_PAUSE ||
        action.type === ACTION_TYPE.ALL_ISSUES_RESOLVED ||
        action.type === ACTION_TYPE.AUTOMATION_PAUSE
    );
  },

  isRunStateActive: (runState) => {
    return ACTIVE_RUN_STATES.includes(runState);
  },

  /**
   * If run is completed, runs status, otherwise returns state.
   *
   * @param {import('shared/lib/types/views/procedures').RunState} [state]
   * @param {import('shared/lib/types/views/procedures').RunStatus} [status]
   * @returns {import('shared/lib/types/views/procedures').RunState | import('shared/lib/types/views/procedures').RunStatus | undefined}
   */
  getStatus: (state, status) => {
    if (state === RUN_STATE.COMPLETED && status) {
      return status;
    }
    return state;
  },

  /**
   * Get the neighbor steps (previous, next) of the current step
   * @param {string} currentStepId - the current step ID
   * @param {Object} run - the run
   * @returns {Object} an object containing "previousStep" and "nextStep" objects correlating to the
   * current step.  Null indicates the neighbor doesn't exist (e.g. if current step is first or last of run)
   *
   */
  getNeighborSteps: (currentStepId, run) => {
    const neighbors = {
      previousStep: null,
      nextStep: null,
    };

    let found = false;
    for (let sectionIndex = 0; sectionIndex < run.sections.length; sectionIndex++) {
      for (let stepIndex = 0; stepIndex < run.sections[sectionIndex].steps.length; stepIndex++) {
        const step = run.sections[sectionIndex].steps[stepIndex];
        if (found) {
          neighbors.nextStep = step;
          return neighbors;
        }
        if (step.id === currentStepId) {
          found = true;
        } else {
          neighbors.previousStep = step;
        }
      }
    }
    return neighbors;
  },

  // Linear search each step and return the most recently completed step ID prior to current step
  getPreviousCompletedStepId: (currentStepId, run) => {
    if (!run || !currentStepId) {
      return null;
    }
    const previous = {
      id: null,
      time: null,
    };
    const runSectionIndex = run.run_section && parseInt(run.run_section);
    let firstSection = 0;
    let lastSectionLimit = run.sections.length;
    if (runSectionIndex) {
      firstSection = runSectionIndex;
      lastSectionLimit = runSectionIndex + 1;
    }

    for (let sectionIndex = firstSection; sectionIndex < lastSectionLimit; sectionIndex++) {
      const section = run.sections[sectionIndex];
      for (let stepIndex = 0; stepIndex < section.steps.length; stepIndex++) {
        const step = section.steps[stepIndex];
        if (step.id === currentStepId) {
          return previous.id;
        }
        if (step.completedAt) {
          if (previous.time) {
            if (step.completedAt > previous.time) {
              previous.id = step.id;
              previous.time = step.completedAt;
            }
          } else {
            previous.id = step.id;
            previous.time = step.completedAt;
          }
        }
      }
    }
    return previous.id;
  },
  /**
   * Get the procedure id of the master procedure of a run.
   */
  getProcedureId: (run) => run.procedure_id || run.procedure,

  /**
   * @returns {Array<string>} a flat list of all the latest step ids from
   * section and step repeats.
   */
  getLatestStepIds: (run) => {
    return run.sections
      .filter((section) => !section.repeat_of)
      .flatMap((section) => {
        return section.steps
          .filter((step) => !step.repeat_of)
          .flatMap((step) => {
            const { stepId } = runUtil.getLatestRepeat(run, section.id, step.id);
            return stepId;
          });
      });
  },

  _getScrollIdEntry: (scrollToId, context, pending) => ({
    ...context,
    scrollToId,
    ...(pending !== undefined && { pending }),
  }),

  /**
   * Split redlines into field redlines and block redlines.
   *
   * @param {import('shared/lib/types/views/procedures').RunHeader | import('shared/lib/types/views/procedures').RunStep} container
   * @param {import('../hooks/useScroll').ScrollContext} context
   * @returns {{
   *   fieldEntries: Array<import('../hooks/useScroll').IdScrollEntry>,
   *   blockEntries: Array<import('../hooks/useScroll').IdScrollEntry>
   * }}
   */
  _partitionFieldAndBlockRedlines: (container, context) => {
    if (!container.redlines) {
      return {
        fieldEntries: [],
        blockEntries: [],
      };
    }
    const [fieldRedlines, blockRedlines] = partition(container.redlines, (redline) => redline.field);
    const fieldList = uniq(fieldRedlines.map((redline) => redline.field));
    const fieldMap = new Map(fieldRedlines.map((redline) => [redline.field, redline.pending]));

    /*
     * Repeated steps have content ids that differ from those in source steps.
     * Therefore use the block index to determine which block the redline is
     * for.
     */
    const contentIndexMap = new Map(
      blockRedlines.map((redline) => {
        const contentId = redline.content_id ?? redline.source_content_id;
        const index = (redline.header ?? redline.step).content.findIndex((block) => block.id === contentId);
        return [index, redline.pending];
      })
    );

    const fieldEntries = fieldList.map((field) =>
      runUtil._getScrollIdEntry(container.id, context, fieldMap.get(field))
    );
    const blockEntries = container.content.flatMap((block, blockIndex) => {
      if (!contentIndexMap.has(blockIndex)) {
        return [];
      }
      return runUtil._getScrollIdEntry(block.id, context, contentIndexMap.get(blockIndex));
    });

    return {
      fieldEntries,
      blockEntries,
    };
  },

  _getCommentEntries: (container, containerContext) => {
    return (container.redline_comments ?? []).map((redlineComment) => {
      const commentContext = { ...containerContext, commentId: redlineComment.id };
      return runUtil._getScrollIdEntry(redlineComment.id, commentContext);
    });
  },

  _getHeaderEntries: (headers) => {
    return (headers ?? []).flatMap((header) => {
      const entries = [];

      const { fieldEntries, blockEntries } = runUtil._partitionFieldAndBlockRedlines(header, { headerId: header.id });
      entries.push(...fieldEntries, ...blockEntries);

      return entries;
    });
  },

  /**
   * Get step entries from the latest steps. Added steps are not copied in
   * section repeats, but the order among latest step redlines (which can be in
   * step repeats) and added steps must be preserved.
   */
  _getStepEntries: (latestStepIdSet, run) => {
    const stepToSectionIdMap = runUtil.getStepToSectionIdMap(run);

    return run.sections.flatMap((section) => {
      return section.steps.flatMap((step) => {
        const entries = [];

        const sectionId = stepToSectionIdMap[step.id];
        const stepContext = { sectionId, stepId: step.id };

        if (step.created_during_run) {
          // Always include an added step.
          const addedStepEntry = runUtil._getScrollIdEntry(step.id, stepContext);
          entries.push(addedStepEntry);
          return entries;
        } else if (!latestStepIdSet.has(step.id)) {
          // Skip adding entries if the current step is not a latest step.
          return entries;
        }

        const { fieldEntries, blockEntries } = runUtil._partitionFieldAndBlockRedlines(step, stepContext);
        entries.push(...fieldEntries, ...blockEntries);

        const commentEntries = runUtil._getCommentEntries(step, stepContext);
        entries.push(...commentEntries);

        return entries;
      });
    });
  },

  /**
   * Get an ordered list of all redlines in a run. Each list entry includes
   * the id of the target and the context of its container (e.g. header,
   * section, or step ids)
   *
   * @returns Array<import('../hooks/useScroll').IdScrollEntry>
   */
  getRunRedlineScrollEntries: (run) => {
    if (!run) {
      return [];
    }
    const headerEntries = runUtil._getHeaderEntries(run.headers);

    const latestStepIds = runUtil.getLatestStepIds(run);
    const latestStepIdSet = new Set(latestStepIds);
    const stepEntries = runUtil._getStepEntries(latestStepIdSet, run);

    return [...headerEntries, ...stepEntries];
  },

  /**
   * Whether a redline can be included in a step.
   */
  canIncludeRedlines: (step) =>
    !isStepEnded(step) && (!signoffUtil.isSignoffRequired(step.signoffs) || !signoffUtil.anySignoffsComplete(step)),

  getPauseTimeArray: (runActions) => {
    const pauseInfoArray = [];

    let currentPauseTimestamp = null;

    runActions?.forEach((entry) => {
      if ((entry.type === 'pause' || entry.type === 'issue pause') && currentPauseTimestamp === null) {
        currentPauseTimestamp = new Date(entry.timestamp);
      } else if (entry.type === 'resume' && currentPauseTimestamp !== null) {
        const resumeTimestamp = new Date(entry.timestamp);
        const duration = resumeTimestamp.getTime() - currentPauseTimestamp.getTime();
        pauseInfoArray.push({
          timestamp: currentPauseTimestamp,
          duration,
        });
        currentPauseTimestamp = null;
      }
    });

    if (currentPauseTimestamp !== null) {
      const currentTime = new Date();
      // @ts-ignore
      const duration = currentTime.getTime() - currentPauseTimestamp.getTime();
      pauseInfoArray.push({
        timestamp: currentPauseTimestamp,
        duration,
      });
    }

    return pauseInfoArray;
  },

  /**
   * @param {Object} runs - run[] object.
   */
  removePauseTimesFromRuns: (runs) => {
    runs?.forEach((run) => {
      //getting pause time
      let pauseInfoArray = runUtil.getPauseTimeArray(run.actions);
      if (pauseInfoArray.length > 0) {
        let subtractTime = 0;
        run.sections?.forEach((section) => {
          section.steps?.forEach((step, index) => {
            let stepBeforeCompletedAt;
            if (index - 1 >= 0) {
              stepBeforeCompletedAt = new Date(runUtil.getStepEndedTimestamp(section.steps[index - 1]));
            }
            const stepCompletedAt = new Date(runUtil.getStepEndedTimestamp(step));

            pauseInfoArray = pauseInfoArray.filter((pause) => {
              if (
                (stepBeforeCompletedAt === undefined || pause.timestamp > stepBeforeCompletedAt) &&
                pause.timestamp < stepCompletedAt
              ) {
                subtractTime += pause.duration;
                return false; // Exclude the entry from the pause array
              }
              return true; // Include the entry in the pause array
            });

            try {
              const shiftedDate = new Date(stepCompletedAt.getTime() - subtractTime).toISOString();
              runUtil.setStepEndedTimestamp(step, shiftedDate);
            } catch {
              // No-op
            }
          });
        });
      }
    });
  },
  findFirstPausedStep: (run) => {
    for (let sectionIndex = 0; sectionIndex < run.sections.length; sectionIndex++) {
      const section = run.sections[sectionIndex];

      for (let stepIndex = 0; stepIndex < section.steps.length; stepIndex++) {
        const step = section.steps[stepIndex];

        if (step.state === STEP_STATE.PAUSED) {
          return { id: step.id, isStepEnded: isStepEnded(step) };
        }
      }
    }

    return undefined;
  },
};

export default runUtil;
