import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type BackToProps = {
  label: string;
  onClick: () => void;
};

const BackTo = ({ label, onClick }: BackToProps) => {
  return (
    <div className="h-full flex items-center whitespace-nowrap text-ellipsis overflow-hidden text-xs cursor-pointer">
      <div className="flex items-center" onClick={onClick}>
        <FontAwesomeIcon icon="chevron-left" size="xs" className="text-blue-500 mr-1" />
        <div className="max-w-[120px] truncate text-xs mr-3">{label}</div>
      </div>
      <div className="bg-slate-300 min-w-[1px] w-[1px] h-6 ml-0.5 mr-1" />
    </div>
  );
};

export default React.memo(BackTo);
