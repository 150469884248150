import { useCallback } from 'react';
import { cloneDeep, isEqual } from 'lodash';
import procedureUtil from '../lib/procedureUtil';
import printUtil from '../lib/printUtil';
import useProcedureParts from '../manufacturing/hooks/useProcedureParts';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import dictionaryUtil from '../lib/dictionaryUtil';
import useAutoProcedureId from './useAutoProcedureId';
import apm from '../lib/apm';
import { useMixpanel } from '../contexts/MixpanelContext';

const useFormHelpers = ({ present, onProcedureChanged }) => {
  const { mixpanel } = useMixpanel();
  const { onPartChanged } = useProcedureParts({
    procedure: present,
    onProcedureChanged,
  });
  const { services } = useDatabaseServices();
  const { tryUpdateDocWithUniqueId } = useAutoProcedureId();

  const onProcedureDetailsFormChanged = useCallback(
    (values) => {
      if (!present) {
        return;
      }
      let updated = cloneDeep(present);
      updated = {
        ...updated,
        ...values,
      };

      if (isEqual(present, updated)) {
        return;
      }
      onProcedureChanged(updated, present);
    },
    [present, onProcedureChanged]
  );

  const onProcedureSettingsFormChanged = useCallback(
    async (values) => {
      if (!present) {
        return;
      }
      let updated = cloneDeep(present);
      updated = {
        ...updated,
        ...values,
      };

      // if the dictionary changes to a new dictionary, NOT a blank one
      if (values.dictionary_id && values.dictionary_id !== present.dictionary_id) {
        // fetch the telemetries and commands for the new dictionary ID
        Promise.all([
          services.telemetry.searchParameters('', values.dictionary_id ? [values.dictionary_id] : undefined),
          services.commanding.searchCommands('', values.dictionary_id ? [values.dictionary_id] : undefined),
        ])
          .then(([telemetries, commands]) => {
            // update the procedure with new telemetry and commands
            dictionaryUtil.updateProcedureOnDictionaryChange(updated, values.dictionary_id, telemetries, commands);
            onProcedureChanged(updated, present);
            return;
          })
          .catch((err) => apm.captureError(err));
        return;
      }

      await tryUpdateDocWithUniqueId(updated);

      if (isEqual(present, updated)) {
        return;
      }
      onProcedureChanged(updated, present);
    },
    [present, tryUpdateDocWithUniqueId, onProcedureChanged, services.telemetry, services.commanding]
  );

  const onHeaderFormChanged = useCallback(
    (values, headerIndex) => {
      if (!present) {
        return;
      }
      const updated = cloneDeep(present);
      const header = present.headers[headerIndex];
      updated.headers[headerIndex] = {
        ...header,
        ...values,
      };
      if (isEqual(present, updated)) {
        return;
      }
      onProcedureChanged(updated, present);
    },
    [present, onProcedureChanged]
  );

  const onSectionHeaderFormChanged = useCallback(
    (values, sectionIndex, headerIndex) => {
      if (!present) {
        return;
      }
      const updated = cloneDeep(present);
      const header = present.sections[sectionIndex].headers[headerIndex];
      updated.sections[sectionIndex].headers[headerIndex] = {
        ...header,
        ...values,
      };
      if (isEqual(present, updated)) {
        return;
      }
      onProcedureChanged(updated, present);
    },
    [present, onProcedureChanged]
  );

  const onSectionFormChanged = useCallback(
    (values, sectionIndex) => {
      if (!present) {
        return;
      }
      const updated = cloneDeep(present);
      const section = present.sections[sectionIndex];
      updated.sections[sectionIndex] = {
        id: section.id, // don't copy everything here because snippet properties can be removed
        steps: section.steps,
        ...(section.headers && { headers: section.headers }),
        ...values,
      };
      if (isEqual(present, updated)) {
        return;
      }
      onProcedureChanged(updated, present);
    },
    [present, onProcedureChanged]
  );

  const onStepFormChanged = useCallback(
    (values, sectionIndex, stepIndex) => {
      if (!present) {
        return;
      }
      const updated = cloneDeep(present);
      const step = updated.sections[sectionIndex].steps[stepIndex];
      updated.sections[sectionIndex].steps[stepIndex] = {
        id: step.id, // don't copy everything here because step details can be removed
        ...values,
      };
      if (isEqual(present, updated)) {
        return;
      }
      onProcedureChanged(updated, present);
    },
    [present, onProcedureChanged]
  );

  const onAddProcedureVariable = useCallback(() => {
    const updated = cloneDeep(present);
    if (!updated.variables) {
      updated.variables = [];
    }
    updated.variables.push(procedureUtil.newProcedureVariable());
    onProcedureChanged(updated, present);
  }, [present, onProcedureChanged]);

  const onRemoveProcedureVariable = useCallback(
    (index) => {
      const updated = cloneDeep(present);
      if (!updated.variables) {
        updated.variables = [];
      }
      if (index >= 0 && index < updated.variables.length) {
        updated.variables.splice(index, 1);
      }
      printUtil.updateVariableReferences(updated);
      onProcedureChanged(updated, present);
    },
    [present, onProcedureChanged]
  );

  const onVariablesChanged = useCallback(
    (values) => {
      if (isEqual(present.variables, values.variables)) {
        return;
      }
      const updated = {
        ...cloneDeep(present),
        variables: values.variables,
      };
      printUtil.updateVariableReferences(updated);
      onProcedureChanged(updated, present);
    },
    [present, onProcedureChanged]
  );

  const onAddProcedureRisk = useCallback(() => {
    const updated = cloneDeep(present);
    if (!updated.risks) {
      updated.risks = [];
    }
    updated.risks.push({});
    onProcedureChanged(updated, present);
    mixpanel && mixpanel.track('Add Risk to procedure');
  }, [present, onProcedureChanged, mixpanel]);

  const onRemoveProcedureRisk = useCallback(
    (index) => {
      const updated = cloneDeep(present);
      if (!updated.risks) {
        updated.risks = [];
      }
      if (index >= 0 && index < updated.risks.length) {
        updated.risks.splice(index, 1);
      }
      onProcedureChanged(updated, present);
    },
    [present, onProcedureChanged]
  );

  const onRisksChanged = useCallback(
    (procedureRisks) => {
      if (isEqual(present.risks, procedureRisks)) {
        return;
      }
      const updated = {
        ...cloneDeep(present),
        risks: procedureRisks,
      };
      onProcedureChanged(updated, present);
    },
    [present, onProcedureChanged]
  );

  return {
    onPartChanged,
    onProcedureDetailsFormChanged,
    onProcedureSettingsFormChanged,
    onHeaderFormChanged,
    onSectionHeaderFormChanged,
    onSectionFormChanged,
    onStepFormChanged,
    onAddProcedureVariable,
    onRemoveProcedureVariable,
    onVariablesChanged,
    onAddProcedureRisk,
    onRemoveProcedureRisk,
    onRisksChanged,
  };
};

export default useFormHelpers;
