import { useCallback, useEffect, useState } from 'react';
import { ToolInstance } from 'shared/lib/types/api/manufacturing/tools/models';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { DatabaseServices } from '../../contexts/proceduresSlice';
import apm from '../../lib/apm';
import fromClient from '../lib/tools/fromClient';

interface ToolInstancesState {
  allToolInstances: Array<ToolInstance> | undefined;
}

const useToolInstances = (): ToolInstancesState => {
  const [allToolInstances, setAllToolInstances] =
    useState<Array<ToolInstance>>();
  const { services }: { services: DatabaseServices } = useDatabaseServices();

  const refreshToolInstances = useCallback(async () => {
    const clientToolInstances = await services.tools.getToolInstances();
    setAllToolInstances(clientToolInstances.map(fromClient.toToolInstance));
  }, [services.tools]);

  useEffect(() => {
    refreshToolInstances().catch((err) => apm.captureError(err));
  }, [refreshToolInstances]);

  return {
    allToolInstances,
  };
};

export default useToolInstances;
