import { E3Session } from 'shared/lib/types/api/util';
import { AcceptedUsers } from 'shared/lib/types/couch/settings';
import {
  ACCESS_LEVELS_ASCENDING,
  ACCESS,
} from 'shared/lib/constants/permissions';

// TODO(API-40): Move these constants into shared module.

const PERM = {
  PROCEDURES_EDIT: 'procedures-edit',
  RUNS_EDIT: 'runs-edit',
  RUNS_REOPEN: 'runs-reopen',
  SETTINGS_EDIT: 'settings-edit',
  USERS_EDIT: 'users-edit',
  EXPORT_IMPORT_EDIT: 'export-import-edit',
  RUNS_EDIT_STEP: 'runs-edit-step', // Dynamic add/edit steps (currently only add).
  CHANGE_OPERATION: 'change-operation', // change the operation tag of a running procedure
  VIEW_OPERATIONS: 'view-operations',
  VIEW_SETTINGS: 'view-settings',
  BUILDS_EDIT: 'builds-edit',
  BUILDS_IMPORT_PARTS: 'builds-import-parts',
  BUILDS_ADD_INVENTORY: 'builds-add-inventory',
  TESTING_EDIT: 'testing-edit',
  STORAGE_EDIT: 'storage-edit',
  ISSUE_EDIT: 'issue-edit',
  RISK_EDIT: 'risk-edit',
};

const NONE_ACCESS = 'None';

/*
 * Ensuring hierarchically for filtering dropdown options
 * WARNING: THE ORDER HERE IS VERY IMPORTANT
 */
const ALL_LEVELS = ACCESS_LEVELS_ASCENDING;

const ACCESS_PERMISSIONS = {
  [ACCESS.ADMIN]: [
    PERM.PROCEDURES_EDIT,
    PERM.RUNS_EDIT,
    PERM.RUNS_REOPEN,
    PERM.RUNS_EDIT_STEP,
    PERM.SETTINGS_EDIT,
    PERM.USERS_EDIT,
    PERM.EXPORT_IMPORT_EDIT,
    PERM.CHANGE_OPERATION,
    PERM.VIEW_OPERATIONS,
    PERM.VIEW_SETTINGS,
    PERM.BUILDS_EDIT,
    PERM.BUILDS_IMPORT_PARTS,
    PERM.TESTING_EDIT,
    PERM.STORAGE_EDIT,
    PERM.BUILDS_ADD_INVENTORY,
    PERM.ISSUE_EDIT,
    PERM.RISK_EDIT,
  ],
  [ACCESS.EDITOR]: [
    PERM.PROCEDURES_EDIT,
    PERM.RUNS_EDIT,
    PERM.RUNS_EDIT_STEP,
    PERM.VIEW_OPERATIONS,
    PERM.VIEW_SETTINGS,
    PERM.BUILDS_EDIT,
    PERM.TESTING_EDIT,
    PERM.STORAGE_EDIT,
    PERM.BUILDS_ADD_INVENTORY,
    PERM.ISSUE_EDIT,
    PERM.RISK_EDIT,
  ],
  [ACCESS.OPERATOR]: [
    PERM.RUNS_EDIT,
    PERM.VIEW_OPERATIONS,
    PERM.VIEW_SETTINGS,
    PERM.BUILDS_ADD_INVENTORY,
    PERM.ISSUE_EDIT,
    PERM.RISK_EDIT,
  ],
  [ACCESS.VIEWER]: [PERM.VIEW_SETTINGS, PERM.VIEW_OPERATIONS],
};

const EDITOR_ROLES = Object.keys(ACCESS_PERMISSIONS).filter((perm) =>
  ACCESS_PERMISSIONS[perm].includes(PERM.PROCEDURES_EDIT)
);
const OPERATOR_ROLES = Object.keys(ACCESS_PERMISSIONS).filter((perm) =>
  ACCESS_PERMISSIONS[perm].includes(PERM.ISSUE_EDIT)
);
// all permissions grant at least view access
const VIEW_PERMISSIONS = Object.values(PERM);

const auth = {
  /**
   * Returns a list of all current roles the user has for the workspace and project
   */
  _getAllRoles: (
    session: E3Session,
    users: AcceptedUsers,
    currentTeamId: string,
    projectId: string | null = null
  ): Array<string> => {
    const allRoles: Array<string> = [];
    // If missing user or user permissions, return no roles
    if (!session || !session.user_id) {
      return [];
    }
    const userId = session.user_id;
    if (!users || !users[userId]) {
      return [];
    }

    // Add workspace roles
    const allWorkspaceRoles = users[userId].workspace_roles;
    if (allWorkspaceRoles && allWorkspaceRoles[currentTeamId]) {
      allRoles.push(
        ...allWorkspaceRoles[currentTeamId].map((role) => role.toLowerCase())
      );
    }

    // Add project roles
    const allProjectRoles = users[userId].project_roles;
    if (projectId && allProjectRoles && allProjectRoles[projectId]) {
      allRoles.push(
        ...allProjectRoles[projectId].map((role) => role.toLowerCase())
      );
    }

    return allRoles;
  },

  /**
   * Returns the current user's operator role keys, eg ["MD", "OP1"].
   */
  getOperatorRoles: (
    users: AcceptedUsers,
    session: E3Session
  ): Array<string> | null => {
    if (!session || !session.user_id) {
      return null;
    }
    const userId = session.user_id;
    if (!users || !users[userId] || !users[userId].operator_roles) {
      return null;
    }
    return users[userId].operator_roles;
  },

  /**
   * Returns the current user's operator role keys as a set
   */
  getOperatorRolesSet: (
    users: AcceptedUsers,
    session: E3Session
  ): Set<string> => {
    const roles = auth.getOperatorRoles(users, session);
    if (Array.isArray(roles)) {
      return new Set(roles);
    } else {
      return new Set();
    }
  },

  /**
   * Checks if user has the given operator role.
   */
  hasOperatorRole: (
    users: AcceptedUsers,
    session: E3Session,
    role: string
  ): boolean => {
    const roles = auth.getOperatorRoles(users, session);
    if (!role || !roles || roles.length <= 0) {
      return false;
    }
    return roles.includes(role);
  },

  /**
   * Checks if user has org admin role.
   */
  isOrgAdmin: (
    users: AcceptedUsers,
    session: E3Session,
    orgId: string
  ): boolean => {
    // If missing user or user permissions, return no roles
    if (!session || !session.user_id) {
      return false;
    }
    const userId = session.user_id;
    if (!users || !users[userId]) {
      return false;
    }

    const allOrganizationRoles = users[userId].organization_roles;
    return Boolean(
      allOrganizationRoles?.[orgId]
        ?.map((role) => role.toLowerCase())
        .includes(ACCESS.ORG_ADMIN)
    );
  },

  /**
   * Checks if user has the given named permission.
   */
  hasPermission: (
    users: AcceptedUsers,
    session: E3Session,
    permission: string,
    teamId: string,
    projectId: string | null = null
  ): boolean => {
    const userRoles = auth._getAllRoles(session, users, teamId, projectId);
    if (userRoles.length === 0 || !permission) {
      return false;
    }
    for (const role of userRoles) {
      if (ACCESS_PERMISSIONS[role].includes(permission)) {
        return true;
      }
    }
    return false;
  },

  /**
   * Returns all projects for which the user has edit permission.
   */
  projectsWithEditPermission: (
    users: AcceptedUsers,
    session: E3Session
  ): Array<string> => {
    if (!session || !session.user_id) {
      return [];
    }
    const userId = session.user_id;
    if (!users || !users[userId]) {
      return [];
    }
    const user = users[userId];
    if (!user.project_roles) {
      return [];
    }
    const projectRoles = user.project_roles;
    return Object.keys(projectRoles).filter((project) =>
      projectRoles[project].some((role) =>
        EDITOR_ROLES.includes(role.toLowerCase())
      )
    );
  },

  /**
   * Returns all projects for which the user has operator permission.
   */
  projectsWithOperatorPermission: (
    users: AcceptedUsers,
    session: E3Session
  ): Array<string> => {
    if (!session || !session.user_id) {
      return [];
    }
    const userId = session.user_id;
    if (!users || !users[userId]) {
      return [];
    }
    const user = users[userId];
    if (!user.project_roles) {
      return [];
    }
    const projectRoles = user.project_roles;
    return Object.keys(projectRoles).filter((project) =>
      projectRoles[project].some((role) =>
        OPERATOR_ROLES.includes(role.toLowerCase())
      )
    );
  },

  /**
   * Checks if user has edit permission for any project.
   */
  hasProjectsWithEditPermission: (
    users: AcceptedUsers,
    session: E3Session
  ): boolean => auth.projectsWithEditPermission(users, session).length > 0,

  /**
   * Checks if user has operator permission for any project.
   */
  hasProjectsWithOperatorPermission: (
    users: AcceptedUsers,
    session: E3Session
  ): boolean => auth.projectsWithOperatorPermission(users, session).length > 0,

  /**
   * Checks if user does not have edit procedure edit permission at the workspace level but has it for any project.
   */
  hasProjectOnlyEditPermissions: (
    users: AcceptedUsers,
    session: E3Session,
    teamId: string
  ): boolean =>
    !auth.hasPermission(users, session, PERM.PROCEDURES_EDIT, teamId) &&
    auth.hasProjectsWithEditPermission(users, session),

  /**
   * Checks if user does not have operator permission at the workspace level but has it for any project.
   */
  hasProjectOnlyOperatorPermissions: (
    users: AcceptedUsers,
    session: E3Session,
    teamId: string
  ): boolean =>
    !auth.hasPermission(users, session, PERM.ISSUE_EDIT, teamId) &&
    auth.hasProjectsWithOperatorPermission(users, session),

  /**
   * Checks if user does not any permission at the workspace level.
   */
  noWorkspaceAccess: (
    users: AcceptedUsers,
    session: E3Session,
    teamId: string
  ): boolean =>
    !VIEW_PERMISSIONS.some((perm) =>
      auth.hasPermission(users, session, perm, teamId)
    ),
};

export { auth, ACCESS, ALL_LEVELS, PERM, NONE_ACCESS };
