import React from 'react';

export interface TitleProps {
  title: string | JSX.Element;
}

const Title = ({ title }: TitleProps) => {
  return <span className="truncate">{title}</span>;
};

export default React.memo(Title);
