import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import procedureUtil from '../lib/procedureUtil';
import revisionsUtil from '../lib/revisions';
import ProcedureContent from '../components/ProcedureContent';
import ProcedureDetails from '../components/ProcedureDetails';
import ButtonsProcedure from '../components/ButtonsProcedure';
import { PROCEDURE_STATE_DRAFT } from 'shared/lib/procedureUtil';
import { Helmet } from 'react-helmet-async';
import { textOrUntitled } from 'shared/lib/text';
import NotFound from '../components/NotFound';
import useProcedureObserver from '../hooks/useProcedureObserver';
import useProcedureVersions from '../hooks/useProcedureVersions';
import PageSidebar from '../components/PageSidebar';
import ProcedureVersionList from '../components/ProcedureVersionList';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { procedureReviewPath, procedureViewPath } from '../lib/pathUtil';
import useExpandCollapse from '../hooks/useExpandCollapse';
import ProcedureStickyHeader from '../components/ProcedureStickyHeader';

const ProcedurePending = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const isMounted = useRef(true);
  const released = useProcedureObserver({ id });
  const { currentTeamId } = useDatabaseServices();
  const pending = useProcedureObserver({ id: procedureUtil.getPendingProcedureIndex(id) });
  const [showVersionHistory, setShowVersionHistory] = useState(
    history.location.state && history.location.state['versions']
  );
  const { versions } = useProcedureVersions({ id });

  const { isCollapsedMap, setAllExpanded, setIsCollapsed, areAllStepsInSectionExpanded, setAllStepsInSectionExpanded } =
    useExpandCollapse();

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  const draft = useMemo(() => {
    if (pending.procedure && pending.procedure.state === PROCEDURE_STATE_DRAFT) {
      // don't show steps added from a run not accepted to the procedure
      return revisionsUtil.getProcedureWithoutRunSteps(pending.procedure);
    }
    return null;
  }, [pending.procedure]);

  const onShowVersionHistory = useCallback(() => {
    setShowVersionHistory(true);
  }, []);

  const sectionIds = React.useMemo(
    () => (draft && draft.sections ? draft.sections.map((section) => section.id) : []),
    [draft]
  );
  const headerIds = React.useMemo(
    () => (draft && draft.headers ? draft.headers.map((header) => header.id) : []),
    [draft]
  );

  const onExpandAll = useCallback(() => {
    setAllExpanded(true, sectionIds, headerIds);
  }, [setAllExpanded, sectionIds, headerIds]);

  const onCollapseAll = useCallback(() => {
    setAllExpanded(false, sectionIds, headerIds);
  }, [setAllExpanded, sectionIds, headerIds]);

  useEffect(() => {
    if (sectionIds.length === 0) {
      return;
    }

    if (sectionIds.length >= 5) {
      setAllExpanded(false, sectionIds, headerIds);
    }
  }, [headerIds, sectionIds, setAllExpanded]);

  // If pending procedure is still loading return null.
  if (pending.loading && !pending.procedure) {
    return null;
  }

  // If both pending and released procedures are not found, show not found message.
  if (!pending.procedure && !released.procedure) {
    return <NotFound />;
  }

  // If there is no draft for this procedure, show procedure not pending message.
  if (!draft) {
    return (
      <div className="px-4 xl:px-20 py-4 mt-16">
        <div className="mt-3">
          <span className="italic"> Procedure version not pending. </span>
          <Link
            className="italic underline text-blue-600"
            to={released.procedure ? procedureViewPath(currentTeamId, id) : procedureReviewPath(currentTeamId, id)}
          >
            Go to procedure.
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div>
      <ProcedureStickyHeader
        code={draft.code}
        name={draft.name}
        hasComments={false}
        showComments={false}
        state="draft"
        isApproved={false}
        onExpandAll={onExpandAll}
        onCollapseAll={onCollapseAll}
      />
      <div className="flex">
        <div className={`flex-1 pr-4 pl-20 lg:pr-8 py-4 print:m-0 print:p-0 ${showVersionHistory && 'mr-72'}`}>
          <div className="mx-auto">
            <div>
              {/* Sets the document title */}
              <Helmet>
                <title>{`Procedure · ${textOrUntitled(draft.name)}`}</title>
              </Helmet>
            </div>
            <div className="flex gap-x-2">
              <ProcedureDetails procedure={draft} />

              <ButtonsProcedure
                isArchiveDisabled={true}
                isRunDisabled={true}
                isDuplicateDisabled={false}
                isRunHistoryDisabled={true}
                hideRunActions={released.procedure && released.procedure.archived}
                hideEditActions={released.procedure && released.procedure.archived}
                onShowVersionHistory={onShowVersionHistory}
                procedure={draft}
                projectId={draft.project_id}
              />
            </div>
            <ProcedureContent
              procedure={draft}
              isCollapsedMap={isCollapsedMap}
              setIsCollapsed={setIsCollapsed}
              areAllStepsInSectionExpanded={areAllStepsInSectionExpanded}
              setAllStepsInSectionExpanded={setAllStepsInSectionExpanded}
            />
          </div>
        </div>
        {showVersionHistory && (
          <PageSidebar
            title="Version History"
            onClose={() => setShowVersionHistory(false)}
            hasTopBuffer={true}
            hasTopDivider={(versions ?? []).length < 2}
          >
            <ProcedureVersionList procedureId={id} procedure={draft} versions={versions} />
          </PageSidebar>
        )}
      </div>
    </div>
  );
};

export default ProcedurePending;
