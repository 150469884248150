import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { generateHiddenClassString } from '../../lib/styles';
import SubstepNumber from '../SubstepNumber';
import Spacer from '../Spacer';
import useProcedureAdapter from '../../hooks/useProcedureAdapter';
import {
  DiffArrayChangeSymbol,
  FieldInputBlock,
  FieldInputNumberBlock,
  V2Variable,
} from 'shared/lib/types/views/procedures';
import { BlockProvider } from '../../contexts/BlockContext';
import DiffContainer from '../Diff/DiffContainer';
import AttachmentDisplay from '../Blocks/Display/AttachmentDisplay';
import CheckboxDisplay from '../Blocks/Display/CheckboxDisplay';
import CustomListDisplay from '../Blocks/Display/CustomListDisplay';
import ExternalItemDisplay from '../Blocks/Display/ExternalItemDisplay';
import ExternalSearchDisplay from '../Blocks/Display/ExternalSearchDisplay';
import RadioGroupDisplay from '../Blocks/Display/RadioGroupDisplay';
import SettingsListDisplay from '../Blocks/Display/SettingsListDisplay';
import TextDisplay from '../Blocks/Display/TextDisplay';
import TimestampDisplay from '../Blocks/Display/TimestampDisplay';
import ReferenceToken from '../Blocks/Display/ReferenceToken';
import ReviewNumberFieldInput from './Blocks/FieldInput/ReviewNumberFieldInput';
import { getContentBlock } from 'shared/lib/expressionUtil';

export const SUPPORTED_INPUT_DISPLAYS = {
  checkbox: CheckboxDisplay,
  text: TextDisplay,
  number: ({ block, referenceId }: { block: FieldInputNumberBlock; referenceId?: string }) => {
    return (
      <ReviewNumberFieldInput
        block={block}
        label={<ReferenceToken originalReferencedContentId={referenceId ?? ''} />}
        isReference={true}
      />
    );
  },
  timestamp: TimestampDisplay,
  select: CustomListDisplay,
  list: SettingsListDisplay,
  external_item: ExternalItemDisplay,
  external_search: ExternalSearchDisplay,
  multiple_choice: RadioGroupDisplay,
  attachment: AttachmentDisplay,
};

interface DisplayBlockProps {
  block: FieldInputBlock | V2Variable;
  referenceId: string;
  referenceSubtype?: string;
  referenceDiffChangeState?: DiffArrayChangeSymbol;
}
type DisplayBlockType = ({
  block,
  referenceId,
  referenceSubtype,
  referenceDiffChangeState,
}: DisplayBlockProps) => JSX.Element;

interface ReferenceEditSelectProps {
  originalReferencedContentId: string;
  originalReferencedSubtype?: string;
  isHidden: boolean;
  blockLabel: string;
  isValid?: boolean;
  diffChangeState?: DiffArrayChangeSymbol;
  originalReferencedFieldIndex?: number;
}

const ReviewReferenceBlock = ({
  originalReferencedContentId,
  originalReferencedSubtype,
  isHidden,
  blockLabel,
  isValid = true,
  diffChangeState,
  originalReferencedFieldIndex,
}: ReferenceEditSelectProps) => {
  const { getReferencedContentContext } = useProcedureAdapter();
  const referencedContentContext = getReferencedContentContext(originalReferencedContentId);
  const referencedContent = getContentBlock(referencedContentContext?.referencedContent, originalReferencedFieldIndex);

  // Casting is needed to allow for using a dispatch table to conditionally render a component.
  const DisplayBlock = useMemo(
    () => referencedContent && SUPPORTED_INPUT_DISPLAYS[referencedContent?.inputType],
    [referencedContent]
  ) as DisplayBlockType;

  return (
    <BlockProvider value={{ isValid }}>
      <tr>
        <td />
        <td colSpan={2}>
          <div className={generateHiddenClassString('', isHidden)}>
            {referencedContent && referencedContent.type.toLowerCase() === 'input' && DisplayBlock && (
              <div className="flex flex-row">
                <Spacer />
                <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={true} />
                <div className="flex flex-wrap items-start w-full my-1 gap-x-2" aria-label="Input Reference">
                  <DiffContainer
                    label="Input reference"
                    diffChangeState={diffChangeState}
                    isTextSticky={false}
                    width="fit"
                  >
                    <DisplayBlock
                      block={referencedContent}
                      referenceId={originalReferencedContentId}
                      referenceSubtype={originalReferencedSubtype}
                      referenceDiffChangeState={diffChangeState}
                    />
                  </DiffContainer>
                </div>
              </div>
            )}
            {(!referencedContent || !DisplayBlock) && (
              <div className="flex">
                <DiffContainer
                  label="Input reference"
                  diffChangeState={diffChangeState}
                  isTextSticky={false}
                  width="fit"
                >
                  <span className="mt-2 mr-8 py-2 w-full">
                    <FontAwesomeIcon className="mr-2 text-red-500" icon="exclamation-circle" />
                    Invalid Referenced Input Field
                  </span>
                </DiffContainer>
              </div>
            )}
          </div>
        </td>
      </tr>
    </BlockProvider>
  );
};

export default ReviewReferenceBlock;
