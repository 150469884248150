import { Transaction } from '@elastic/apm-rum';
import isNil from 'lodash.isnil';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getHaveAllReviewerGroupsApproved } from 'shared/lib/reviewUtil';
import { Procedure as ProcedureType } from 'shared/lib/types/views/procedures';
import ButtonsProcedure from '../components/ButtonsProcedure';
import NotFound from '../components/NotFound';
import PageSidebar from '../components/PageSidebar';
import ProcedureContent from '../components/ProcedureContent';
import ProcedureDetails from '../components/ProcedureDetails';
import ProcedureStickyHeader from '../components/ProcedureStickyHeader';
import ProcedureVersionList from '../components/ProcedureVersionList';
import ReleaseNote from '../components/ReleaseNote';
import { useAuth } from '../contexts/AuthContext';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { selectProceduresMetadata } from '../contexts/proceduresSlice';
import useExpandCollapse from '../hooks/useExpandCollapse';
import useLocationParams from '../hooks/useLocationParams';
import useProcedureObserver from '../hooks/useProcedureObserver';
import useProcedureVersions from '../hooks/useProcedureVersions';
import useUrlScrollTo from '../hooks/useUrlScrollTo';
import apm from '../lib/apm';
import externalDataUtil from '../lib/externalDataUtil';
import procedureUtil from '../lib/procedureUtil';
import { procedurePendingPath } from '../lib/pathUtil';

const Procedure = () => {
  const history = useHistory();
  const { id, version } = useParams<{ id: string; version: string }>();
  const { auth } = useAuth();
  const location = useLocation();
  const { searchParams } = useLocationParams(location);
  const isMounted = useRef(true);
  const transactionRef = useRef<Transaction | null>(null);
  const procedure = useProcedureObserver({ id });
  const pending = useProcedureObserver({ id: procedureUtil.getPendingProcedureIndex(id) });
  const [procedureRevision, setProcedureRevision] = useState<{ loading: boolean; procedure: ProcedureType | null }>({
    loading: false,
    procedure: null,
  });
  const [showVersionHistory, setShowVersionHistory] = useState(
    history.location.state && history.location.state['versions']
  );
  const { services, currentTeamId } = useDatabaseServices();
  const { versions, isLatestRelease, latestReleasedVersion } = useProcedureVersions({ id });
  const proceduresMetadata = useSelector((state) => selectProceduresMetadata(state, currentTeamId));
  const [showComments, setShowComments] = useState(false);
  const [urlParams, setUrlParams] = useState(() => {
    const sourceUrl = searchParams.get('sourceUrl');
    const name = searchParams.get('sourceName');
    const sectionId = searchParams.get('sectionId');
    const view = searchParams.get('view');
    return {
      sourceUrl,
      name,
      sectionId,
      view,
    };
  });

  // user tried to go to master procedure but only an initial draft exists, so redirect there
  if (!procedure.loading && !procedure.procedure && !pending.loading && pending.procedure) {
    history.replace(procedurePendingPath(currentTeamId, pending.procedure.procedure_id));
  }

  useEffect(() => {
    setUrlParams(() => {
      const sourceUrl = searchParams.get('sourceUrl');
      const name = searchParams.get('sourceName');
      const sectionId = searchParams.get('sectionId');
      const view = searchParams.get('view');
      return {
        sourceUrl,
        name,
        sectionId,
        view,
      };
    });
  }, [searchParams]);

  const { isCollapsedMap, setIsCollapsed, setAllExpanded, areAllStepsInSectionExpanded, setAllStepsInSectionExpanded } =
    useExpandCollapse();

  useEffect(() => {
    transactionRef.current = apm.startTransaction('procedure.load', 'custom') || null;
  }, []);

  useEffect(() => {
    if (!transactionRef.current) {
      return;
    }

    /*
     * Procedure is loaded after the first render where both the
     * procedure and its corresponding draft or review are loaded.
     */
    if (procedure.loading || pending.loading) {
      return;
    }

    transactionRef.current.end();
    transactionRef.current = null;
  }, [procedure.loading, pending.loading]);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  const released = useMemo(() => {
    if (isNil(version) || (procedureRevision.procedure && isLatestRelease(procedureRevision.procedure))) {
      return procedure;
    }
    return procedureRevision;
  }, [procedure, procedureRevision, version, isLatestRelease]);

  const userOperatorRolesSet = useMemo(() => {
    return auth.getOperatorRolesSet();
  }, [auth]);

  const userHasStartRunSignoffs = useMemo(() => {
    return procedureUtil.userHasStartRunSignoffs(released.procedure?.start_run_signoffs_groups, userOperatorRolesSet);
  }, [released.procedure?.start_run_signoffs_groups, userOperatorRolesSet]);

  useEffect(() => {
    if (!services.revisions) {
      return;
    }
    if (isNil(version)) {
      return;
    }
    const loadProcedureRevision = async () => {
      setProcedureRevision({
        loading: true,
        procedure: null,
      });
      let procedure = null;
      try {
        procedure = await services.revisions.getProcedureRevision(id, version);
        if (procedure) {
          const externalItems = await services.externalData.getAllExternalItems(procedure);
          procedure = externalDataUtil.updateProcedureWithItems(procedure, externalItems);
        }
      } catch {
        // Ignore errors and fall back to using procedure without dynamic data.
      }
      setProcedureRevision({
        loading: false,
        procedure,
      });
    };
    loadProcedureRevision().catch((err) => apm.captureError(err));
  }, [id, version, services.revisions, services.externalData, latestReleasedVersion]);

  const onShowVersionHistory = useCallback(() => {
    setShowVersionHistory(true);
  }, []);

  const isLatestReleasedVersion = useMemo(() => {
    if (!released.procedure) {
      return false;
    }
    return isLatestRelease(released.procedure);
  }, [released.procedure, isLatestRelease]);

  const allProceduresMetadata = useMemo(() => {
    return Object.entries(proceduresMetadata).map(([, procedure]) => procedure);
  }, [proceduresMetadata]);

  const isApproved = useMemo(() => {
    if (!released.procedure) {
      return false;
    }
    const pending = procedureUtil.getPendingProcedure(released.procedure, allProceduresMetadata);
    return getHaveAllReviewerGroupsApproved(pending?.reviewer_groups);
  }, [allProceduresMetadata, released.procedure]);

  const procedureState = useMemo(() => {
    if (!released.procedure) {
      return;
    }
    return procedureUtil.getCurrentState(released.procedure, allProceduresMetadata);
  }, [allProceduresMetadata, released.procedure]);

  const hasReleaseComments = useMemo(
    () => released.procedure?.release_note !== undefined,
    [released.procedure?.release_note]
  );

  const hasReviewComments = useMemo(() => {
    return released.procedure?.comments?.some((comment) => comment.type === 'review_comment');
  }, [released.procedure]);

  const hasComments = useMemo(() => hasReleaseComments || hasReviewComments, [hasReleaseComments, hasReviewComments]);

  const sectionIds = React.useMemo(
    () =>
      released.procedure && released.procedure.sections
        ? released.procedure?.sections.map((section) => section.id)
        : [],
    [released.procedure]
  );
  const headerIds = React.useMemo(
    () =>
      released.procedure && released.procedure.headers ? released.procedure?.headers.map((header) => header.id) : [],
    [released.procedure]
  );

  const onExpandAll = useCallback(() => {
    setAllExpanded(true, sectionIds, headerIds);
  }, [setAllExpanded, sectionIds, headerIds]);

  const onCollapseAll = useCallback(() => {
    setAllExpanded(false, sectionIds, headerIds);
  }, [setAllExpanded, sectionIds, headerIds]);

  const hasInvalidExternalItems = useMemo(() => {
    if (!released.procedure) {
      return;
    }
    return externalDataUtil.hasInvalidExternalItems(released.procedure);
  }, [released.procedure]);

  // accounts for height of ProcedureStickyHeader when present
  const scrollToBufferRem = released.procedure ? 2.5 : 0;

  const [hasInitiallyCollapsed, setHasInitiallyCollapsed] = useState(false);

  useEffect(() => {
    if (hasInitiallyCollapsed) {
      return;
    }
    if (sectionIds.length === 0) {
      return;
    }

    if (sectionIds.length >= 5) {
      setAllExpanded(false, sectionIds, headerIds);

      // this is necessary to prevent collapsing all sections after expanding a section for a url link
      setHasInitiallyCollapsed(true);
    }
  }, [hasInitiallyCollapsed, headerIds, sectionIds, setAllExpanded]);

  // Handle direct url scroll to section/step
  const { onScrollToRefChanged, onScrollToId } = useUrlScrollTo({
    setIsCollapsed,
    procedure: released.procedure,
    searchParams,
  });

  const sourceProps = useMemo(() => {
    const { sourceUrl, name } = urlParams;

    if (!name || !sourceUrl) {
      return;
    }

    return {
      title: name,
      from: urlParams.sourceUrl,
    };
  }, [urlParams]);

  // Wait for documents to load.
  if (procedure.loading || pending.loading) {
    return null;
  }

  // If procedure request has resolved and procedure was not found, show message.
  if (
    (version && !procedureRevision.loading && procedureRevision.procedure === null) ||
    (!procedure.procedure && !pending.procedure)
  ) {
    return <NotFound />;
  }

  return (
    <div>
      {released.procedure && (
        <ProcedureStickyHeader
          code={released.procedure.code}
          name={released.procedure.name}
          hasComments={hasComments}
          showComments={hasComments && showComments}
          setShowComments={setShowComments}
          state={procedureState}
          isApproved={isApproved}
          onExpandAll={onExpandAll}
          onCollapseAll={onCollapseAll}
          source={sourceProps}
        />
      )}
      <div className="flex flex-grow" aria-label="Procedure" role="region">
        <div className={`flex-1 pr-4 pl-20 lg:pr-8 py-4 print:m-0 print:p-0 ${showVersionHistory && 'mr-72'}`}>
          {released.procedure && (
            <div className="mx-auto">
              <Helmet>
                <title>{`Procedure · ${released.procedure.name}`}</title>
              </Helmet>
              <div className="grow">
                <div className="flex gap-x-2">
                  <ProcedureDetails procedure={released.procedure} />
                  {urlParams.view !== 'snapshot' && (
                    <ButtonsProcedure
                      procedure={released.procedure}
                      pending={pending.procedure}
                      hasInvalidExternalItems={hasInvalidExternalItems}
                      isArchiveDisabled={!isLatestReleasedVersion}
                      isEditDisabled={!isLatestReleasedVersion}
                      isDuplicateDisabled={!isLatestReleasedVersion}
                      isRunDisabled={!isLatestReleasedVersion || !userHasStartRunSignoffs}
                      isRunHistoryDisabled={!isLatestReleasedVersion}
                      isDraftDisabled={!isLatestReleasedVersion}
                      hideRunActions={released.procedure && released.procedure.archived}
                      hideEditActions={released.procedure && released.procedure.archived}
                      onShowVersionHistory={onShowVersionHistory}
                      showDraftAction={true}
                      projectId={released.procedure.project_id}
                    />
                  )}
                </div>
                {showComments && released.procedure.release_note && (
                  <ReleaseNote releaseNote={released.procedure.release_note} isEditable={false} hasBorder={true} />
                )}
                <ProcedureContent
                  procedure={released.procedure}
                  isCollapsedMap={isCollapsedMap}
                  setIsCollapsed={setIsCollapsed}
                  areAllStepsInSectionExpanded={areAllStepsInSectionExpanded}
                  setAllStepsInSectionExpanded={setAllStepsInSectionExpanded}
                  showReviewComments={showComments}
                  scrollToBufferRem={scrollToBufferRem}
                  onlyShowSection={urlParams.sectionId}
                  onSectionOrStepRefChanged={onScrollToRefChanged}
                  onScrollToId={onScrollToId}
                />
              </div>
            </div>
          )}
        </div>
        {showVersionHistory && (
          <PageSidebar
            title="Version History"
            onClose={() => setShowVersionHistory(false)}
            hasTopBuffer={true}
            hasTopDivider={(versions ?? []).length < 2}
          >
            <ProcedureVersionList procedureId={id} procedure={released.procedure} versions={versions} />
          </PageSidebar>
        )}
      </div>
    </div>
  );
};

export default Procedure;
