import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { cloneDeep } from 'lodash';
import { useCallback, useMemo } from 'react';
import { ProcedureRisk } from 'shared/lib/types/views/procedures';
import { EDIT_STICKY_HEADER_HEIGHT_REM } from '../../components/EditToolbar';
import { useMixpanel } from '../../contexts/MixpanelContext';
import Icon from '../../elements/Icon';
import Selector from '../../elements/Selector';
import FieldError from '../../elements/internal/FieldError';
import FieldLabel from '../../elements/internal/FieldLabel';
import AssessmentIndicator from '../../testing/components/AssessmentIndicator';
import { Risk } from '../types';

type RiskOption = {
  value: {
    id: number;
    title: string;
  };
  label: string;
};

const getRiskAsOption = (risk: ProcedureRisk | Risk): RiskOption | undefined => {
  if (!risk.id) {
    return undefined;
  }
  return { value: { id: risk.id, title: risk.title }, label: risk.title };
};

interface FormProcedureRisksProps {
  procedureRisks: Array<ProcedureRisk>;
  risks: Array<Risk>;
  errors: Array<string>;
  onRemoveRisk: (index: number) => void;
  onFieldRefChanged: (id: string, element: HTMLElement | null) => void;
  onChanged: (procedureRisks: Array<ProcedureRisk>) => void;
  projectId?: string;
}

const FormProcedureRisks = ({
  procedureRisks,
  risks,
  errors,
  onRemoveRisk,
  onFieldRefChanged,
  onChanged,
  projectId,
}: FormProcedureRisksProps) => {
  const { mixpanel } = useMixpanel();

  const usedRisks = useMemo(() => {
    return new Set(procedureRisks.map((item) => item.id));
  }, [procedureRisks]);

  const selectOptions = useMemo(() => {
    if (!risks) {
      return [];
    }

    const filtered = risks.filter((risk) => {
      return (!projectId || (projectId && risk.projectId === projectId)) && !usedRisks.has(risk.id);
    });

    return filtered.map((risk: Risk) => getRiskAsOption(risk));
  }, [projectId, risks, usedRisks]);

  const handleRemoveRisk = useCallback(
    (index: number) => {
      onRemoveRisk(index);
      mixpanel && mixpanel.track('Remove Risk from procedure');
    },
    [mixpanel, onRemoveRisk]
  );

  const setFieldValue = useCallback(
    (index: number, value: ProcedureRisk) => {
      const updated = cloneDeep(procedureRisks);
      updated[index] = value;
      onChanged(updated);
      mixpanel && mixpanel.track('Modify Risk to procedure');
    },
    [mixpanel, onChanged, procedureRisks]
  );

  const itemRenderer = useCallback(
    (props) => {
      const option: RiskOption = props.data;
      const resolved = risks.find((risk) => risk.id === option.value.id);
      const analysis = resolved
        ? {
            row_value: resolved.assessmentMatrixRow,
            column_value: resolved.assessmentMatrixColumn,
          }
        : undefined;
      return (
        <div className="w-full flex flex-row items-center">
          {analysis && <AssessmentIndicator analysis={analysis} />}
          <div className="text-sm ml-1">
            {option.value.id} - {option.label}
          </div>
        </div>
      );
    },
    [risks]
  );

  if (!procedureRisks?.length) {
    return <></>;
  }

  return (
    <div className="flex flex-col mr-2">
      <FieldLabel label="Associated Risks" />
      {procedureRisks &&
        procedureRisks.map((procedureRisk: ProcedureRisk, index: number) => (
          <div key={`risks[${index}]`} className="flex mt-2">
            <div className="inline-flex group">
              <div
                className="min-w-64"
                ref={(element) => onFieldRefChanged(`risks[${index}]`, element)}
                style={{ scrollMarginTop: `${EDIT_STICKY_HEADER_HEIGHT_REM}rem` }}
              >
                <Selector
                  name={`risks[${index}]`}
                  type="standalone"
                  description="Procedure Risk"
                  isDisabled={false}
                  selectedOption={getRiskAsOption(procedureRisk)}
                  selectOptions={selectOptions}
                  onChangeHandler={(option) => setFieldValue(index, option.value)}
                  itemRenderer={itemRenderer}
                />
                {errors && errors[index] && <FieldError errorMessage={errors[index]} />}
              </div>
              <div className="w-10 h-full flex flex-col justify-start opacity-0 group-hover:opacity-100">
                <button type="button" title="Remove Variable" tabIndex={-1} onClick={() => handleRemoveRisk(index)}>
                  <Icon element={faCircleXmark} className="self-center text-gray-400 hover:text-gray-500" />
                </button>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default FormProcedureRisks;
