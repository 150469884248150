import React, { useMemo, useCallback } from 'react';
import { FieldArray } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FieldSetStepDependency from './FieldSetStepDependency';
import StepDependencySelect from './StepDependencySelect';
import procedureUtil from '../../lib/procedureUtil';
import { useSettings } from '../../contexts/SettingsContext';
import { useProcedureContext } from '../../contexts/ProcedureContext';
import stepDependencyUtil from '../../lib/stepDependencyUtil';
import cloneDeep from 'lodash.clonedeep';

const addStepDependencyIcon = (
  <FontAwesomeIcon className="mx-4 self-center text-gray-600 cursor-pointer hover:text-gray-800" icon="plus-circle" />
);

// Returns an array of react-select type options objects.
const getOptions = (sections, getSetting, stepId) => {
  let options = [];

  sections.forEach((section, sectionIndex) => {
    const sectionLabel = procedureUtil.displaySectionKey(sectionIndex, getSetting('display_sections_as', 'letters'));

    const sectionName = section.name;

    let label = sectionLabel;

    if (sectionName) {
      label = `${sectionLabel}. ${sectionName}`;
    }
    options.push({
      type: 'section',
      label,
      value: section.id,
    });

    section.steps.forEach((step, stepIndex) => {
      // We dont show dynamic steps as options until they are accepted into the procedure.
      if (step.created_during_run) {
        return;
      }

      if (stepId === step.id) {
        options = options.filter((option) => option.value !== section.id);
        return;
      }

      const stepLabel = procedureUtil.displaySectionStepKey(
        sectionIndex,
        stepIndex,
        getSetting('display_sections_as', 'letters')
      );
      const stepName = step.name;

      let label = stepLabel;

      if (stepName) {
        label = `${stepLabel}. ${stepName}`;
      }

      options.push({
        type: 'step',
        label,
        value: step.id,
      });
    });
  });

  return options;
};

/**
 * Renders a step signoff array.
 *
 * @param {object} props
 * @param {Array} props.dependencies - Array of step dependency objects.
 * @param {String} props.path - path/name to dependency values.
 * @param {String} props.stepId - id of containing step.
 * @param {Function} props.onDependenciesUpdated - Callback to sync updated dependencies with step.
 * @param {Function} props.trackedCallback - Callback to step function creating tracked callbacks.
 */

const FieldSetStepDependencies = ({ dependencies, path, stepId, onDependenciesUpdated, trackedCallback }) => {
  const { getAllSections } = useProcedureContext();
  const { getSetting } = useSettings();

  const dependencyOptions = useMemo(() => {
    const allSections = getAllSections();
    const allDependencyOptions = getOptions(allSections, getSetting, stepId);
    const usedDependencyOptions = stepDependencyUtil.getAllDependentIds(dependencies);

    // Filter out all steps in a section if a section was selected and filter out section for a selected step
    const dependencyFilters = new Map();
    for (const section of allSections) {
      const sectionFilter = new Set();
      for (const step of section.steps) {
        sectionFilter.add(step.id);
        dependencyFilters.set(step.id, new Set([section.id]));
      }
      dependencyFilters.set(section.id, sectionFilter);
    }

    const implicitlyUsedOptions = new Set();
    usedDependencyOptions.forEach((option) => {
      dependencyFilters.get(option)?.forEach((implicitOption) => {
        implicitlyUsedOptions.add(implicitOption);
      });
    });

    const mergedUsedOptions = new Set([...usedDependencyOptions, ...implicitlyUsedOptions]);

    /**
     * Remaining options should not include options that have already been selected by user
     * or allow the same same section and steps within a section
     */
    const remainingDependencyOptions = allDependencyOptions.filter((depOpt) => {
      return !mergedUsedOptions.has(depOpt.value) && depOpt.value;
    });

    return {
      remaining: remainingDependencyOptions,
      all: allDependencyOptions,
    };
  }, [dependencies, getAllSections, getSetting, stepId]);

  const getOnAddStepDependency = useCallback(() => {
    return trackedCallback('Step dependency added', (options) => {
      const newDependency = procedureUtil.newStepDependency();
      newDependency.dependent_ids = [options[0].value];
      const dependenciesCopy = cloneDeep(dependencies);
      dependenciesCopy.push(newDependency);

      onDependenciesUpdated(dependenciesCopy);
    });
  }, [dependencies, trackedCallback, onDependenciesUpdated]);

  const getOnRemoveDependency = useCallback(
    (dependencyIndex) => {
      return trackedCallback('Step dependency removed', () => {
        // Cannot remove last dependency.
        if (dependencies.length === 1) {
          return;
        }
        const dependenciesCopy = cloneDeep(dependencies);
        dependenciesCopy.splice(dependencyIndex, 1);

        onDependenciesUpdated(dependenciesCopy);
      });
    },
    [dependencies, trackedCallback, onDependenciesUpdated]
  );

  const onDependencyUpdated = useCallback(
    (updatedDependency, index) => {
      const dependenciesCopy = cloneDeep(dependencies);
      dependenciesCopy[index] = updatedDependency;

      onDependenciesUpdated(dependenciesCopy);
    },
    [dependencies, onDependenciesUpdated]
  );

  const isAddDependencyVisible = useMemo(() => {
    return (
      dependencies &&
      dependencies.length &&
      dependencies[dependencies.length - 1] &&
      dependencies[dependencies.length - 1].dependent_ids.length !== 0
    );
  }, [dependencies]);

  return (
    <FieldArray
      name="dependencies"
      key={path}
      render={() => (
        <div className="flex flex-wrap flex-grow gap-x-2 gap-y-2 items-start">
          {dependencies &&
            dependencies.map((dependency, dependencyIndex) => (
              <FieldSetStepDependency
                key={`dependencies[${dependencyIndex}]`}
                name={`dependencies[${dependencyIndex}]`}
                dependencyIndex={dependencyIndex}
                dependency={dependency}
                onRemoveDependency={getOnRemoveDependency(dependencyIndex)}
                dependencyOptions={dependencyOptions}
                onDependencyUpdated={onDependencyUpdated}
              />
            ))}

          {isAddDependencyVisible && (
            <StepDependencySelect
              options={dependencyOptions.remaining}
              placeholder={addStepDependencyIcon}
              opacity={0.3}
              hoverOpacity={0.6}
              value={null}
              onChange={getOnAddStepDependency()}
              ariaLabel="Add Step Dependency"
              leftIconName={['fas', 'pause']}
            />
          )}
        </div>
      )}
    />
  );
};

export default React.memo(FieldSetStepDependencies);
