import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMixpanel } from '../../contexts/MixpanelContext';
import { FEATURE_RUN_STEP_EDITS_ENABLED, FLASH_MSG_MS } from '../../config';
import revisionsUtil from '../../lib/revisions';
import ReviewBlockAttachment from './Blocks/ReviewBlockAttachment';
import ReviewBlockTelemetry from './ReviewBlockTelemetry';
import ReviewBlockCommanding from './Blocks/ReviewBlockCommanding';
import StepCommenting from '../StepCommenting';
import ReviewCommenting from '../ReviewCommenting';
import ProcedureBlockReview from '../Blocks/ProcedureBlockReview';
import ProcedureField from '../ProcedureField';
import ReviewProcedureStepHeader from './ReviewProcedureStepHeader';
import ReviewPartBuild from '../../manufacturing/components/Review/ReviewPartBuild';
import ReviewPartKit from '../../manufacturing/components/Review/ReviewPartKit';
import ReviewPartUsage from '../../manufacturing/components/Review/ReviewPartUsage';
import ReviewInventoryDetailInput from '../../manufacturing/components/Review/ReviewInventoryDetailInput';
import ReviewStepDetail from './ReviewStepDetail';
import { useAuth } from '../../contexts/AuthContext';
import { PERM } from '../../lib/auth';
import ExpandCollapseCaret from '../ExpandCollapse/ExpandCollapseCaret';
import { generateHiddenClassString } from '../../lib/styles';
import signoffUtil from 'shared/lib/signoffUtil';
import runUtil from '../../lib/runUtil';
import { RUN_STATE, STEP_STATE, isStepEnded, getStepState } from 'shared/lib/runUtil';
import withBlockRedlining from '../../hocs/withBlockRedlining';
import withFieldRedlining from '../../hocs/withFieldRedlining';
import { AttachmentImageDisplayStyle, BlockTypes, isRedlineSupported } from '../Blocks/BlockTypes';
import reviewUtil from '../../lib/reviewUtil';
import validateUtil from '../../lib/validateUtil';
import ReviewProcedureStepSignoffButton from './ReviewProcedureStepSignoffButton';
import { useReviewContext } from '../../contexts/ReviewContext';
import { useSettings, AUTO_COLLAPSE_UNACTIONABLE_STEPS_KEY } from '../../contexts/SettingsContext';
import { DefaultStepDetailDefinitions } from '../StepDetailTypes';
import { isEmptyValue } from 'shared/lib/text';
import { useRunContext } from '../../contexts/RunContext';
import { selectRunStep } from '../../contexts/runsSlice';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import CommentsList from '../CommentsList';
import Pluralize from 'pluralize';
import '../../App.css';
import procedureUtil from '../../lib/procedureUtil';
import ReviewStepConditionals from '../StepConditionals/ReviewStepConditionals';
import Button, { BUTTON_TYPES } from '../Button';
import RequirementsOverlay from '../StepDependency/RequirementsOverlay';
import snippetUtil from '../../lib/snippetUtil';
import DiffContainer from '../Diff/DiffContainer';
import diffUtil from '../../lib/diffUtil';
import sharedDiffUtil, { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import useDiff from '../../hooks/useDiff';
import { INITIAL_REDLINE_STATE, useRedline } from '../../hooks/useRedline';
import useProcedureAdapter from '../../hooks/useProcedureAdapter';
import JiraIssueModal from '../JiraIssueModal';
import CreateIssueModal from '../../issues/components/CreateIssueModal';
import ReviewBlockText from './ReviewBlockText';
import ReviewJumpTo from './ReviewJumpTo';
import ReviewBlockProcedureLink from './ReviewBlockProcedureLink';
import ReviewTestCasesBlock from '../../testing/components/Review/ReviewTestCasesBlock';
import ReviewExpressionBlock from './ReviewExpressionBlock';
import ReviewTableInput from '../TableInput/ReviewTableInput';
import ReviewReferenceBlock from './ReviewReferenceBlock';
import { STEP_REFERENCE_TYPE } from '../../issues/constants';
import ThreeDotMenu from '../../elements/ThreeDotMenu';
import ReviewToolCheckOutIn from '../../manufacturing/components/Tools/ReviewToolCheckOutIn';
import { getScrollToUrlParams } from '../../lib/scrollToUtil';
import ReviewToolUsage from '../../manufacturing/components/Tools/ReviewToolUsage';
import ReviewProcedureStepBanner from './ReviewProcedureStepBanner';
import CommentWrapper from '../CommentWrapper';
import { useProcedureContext } from '../../contexts/ProcedureContext';
import StepTimeline from '../StepTimeline';
import ReviewFieldInputTable from './ReviewFieldInputTable';
import ReviewSettingBadge from './ReviewSettingBadge';
import { isStepSettingEnabled } from 'shared/lib/procedureUtil';
import { faLayerGroup, faRedo, faStepForward, faStrikethrough } from '@fortawesome/free-solid-svg-icons';

const PROCEDURE_LEVEL_STEP_SETTINGS = [
  {
    setting: 'skip_step_enabled',
    icon: faStepForward,
    tooltipText: 'Skip Step',
  },
  {
    setting: 'repeat_step_enabled',
    icon: faRedo,
    tooltipText: 'Repeat Step',
  },
  {
    setting: 'step_suggest_edits_enabled',
    icon: faStrikethrough,
    tooltipText: 'Suggest Edits',
  },
];
const BlockTextWithRedlining = withBlockRedlining(ReviewBlockText);
const ProcedureBlockWithRedlining = withBlockRedlining(ProcedureBlockReview);
const StepFieldWithRedlining = withFieldRedlining(ProcedureField);

/*
 * String constants for user display.
 * These should be moved to an official i18n framework when ready.
 */
const CONFIRM_END_STEP_STR = 'Are you sure?';
const CONFIRM_SKIP_STEP_STR = 'Are you sure you want to skip this step?';
const CONFIRM_DISCARD_EDITS_STR = 'Your unsaved edits will be discarded.';
const CONFIRM_EMPTY_INPUTS_STR = 'Some inputs are missing.';
const CONFIRM_REPEAT_STEP_STR = 'Are you sure you want to repeat this step? The current step will be skipped.';
const CONFIRM_UPLOADING_INPUTS_STR = 'Some files are still uploading.';
const CONFIRM_EMPTY_AND_UPLOADING_INPUTS_STR = 'Some inputs are missing and some files are still uploading.';

const MOBILE_WIDTH = 550;

/*
 * Renders a procedure step.
 *
 * onSignOff: Callback of type fn(operator, recorded), where
 *            operator: String operator role that was signed off.
 *            recorded: Dictionary of type {
 *              pass: true/false
 *              value: telemetry value (if single field)
 *              timestamp: timestamp of telemetry value (if single field)
 *            }
 */
const ReviewProcedureStep = ({
  runId,
  projectId,
  step,
  stepKey,
  sectionId,
  sectionKey,
  sourceName,
  docState,
  operation,
  isRepeatable,
  repeatKey,
  onRepeat,
  onSkip,
  onComplete,
  onFailStep,
  onStartLinkedRun,
  onRefChanged,
  onRecordValuesChanged,
  onAddStepBelow,
  isRedlineFeatureEnabled,
  onSaveRedlineBlock,
  onSaveRedlineStepField,
  onSaveRedlineStepComment,
  onAcceptPendingRedline,
  saveNewComment,
  onResolveReviewComment,
  onUnresolveReviewComment,
  saveReviewComment,
  comments,
  isHidden,
  isCollapsed,
  isPreviousStepComplete,
  onStepCollapse,
  scrollToBufferRem = 0,
  notifyRemainingStepOperators,
  notificationListenerConnected,
  hasPreviousStep,
  areRedlineCommentsExpanded,
  expandRedlineComments,
  isInSectionSnippet = false,
  isPreviewMode = false,
  onAddIssue,
  showReviewComments = false,
}) => {
  const { currentTeamId } = useDatabaseServices();
  const { procedure } = useProcedureContext();

  // Map of {contentIndex: errors dict}
  const [contentErrors, setContentErrors] = useState({});
  // Map of {contentIndex: true if content is uploading}
  const [contentUploading, setContentUploading] = useState({});
  const [collapseCompletedSteps, setCollapseCompletedSteps] = useState(true);
  const [showJiraIssueModal, setShowJiraIssueModal] = useState(false);
  const [showNcrIssueModal, setShowNcrIssueModal] = useState(false);

  const { auth } = useAuth();
  const { mixpanel } = useMixpanel();
  const { config, isJiraIntegrationEnabled, isIssuesEnabled } = useSettings();
  const {
    run,
    isRun,
    isUserParticipant,
    areRequirementsMet,
    isStepVisible,
    advanceStep,
    goToNextFromStep,
    goToPreviousFromStep,
    isSingleCardEnabled,
    viewStepAsIncomplete,
    previewUserRoles,
  } = useRunContext();

  const location = window.location;
  const locationUrl = `${location.protocol}//${location.host}${location.pathname}`;

  const { sourceStepConditionalsMap, removedSourceStepConditionalsMap } = useProcedureAdapter();
  const isMounted = useRef(true);
  const stepGotoRef = useRef();
  const { getSetting } = useSettings();
  const { onScrollToDiffRefChanged, isStepRemoved } = useReviewContext();
  const { handleOnScrollToDiffRefChanged } = useDiff({ onScrollToDiffRefChanged });
  const [isMobile, setIsMobile] = useState(window.innerWidth < MOBILE_WIDTH);
  const liveRunStep = useSelector((state) => selectRunStep(state, currentTeamId, runId, sectionId, step.id));
  const runStep = isPreviewMode ? step : liveRunStep; // ignore redux when in preview

  const showRedlineComments = useMemo(
    () => areRedlineCommentsExpanded && areRedlineCommentsExpanded(step.id),
    [areRedlineCommentsExpanded, step.id]
  );

  const stepState = useMemo(() => {
    let state = getStepState(step);
    if (state === STEP_STATE.COMPLETED && viewStepAsIncomplete(step.id)) {
      state = STEP_STATE.INCOMPLETE;
    }
    return state ?? STEP_STATE.INCOMPLETE;
  }, [step, viewStepAsIncomplete]);

  /**
   * Recorded values to use when completing a step. This is the source of truth,
   * and updated whenever child block content values change. Also passed back
   * down to children for reference.
   *
   * Type: Map of {contentIndex: recorded dict}
   */
  const contentRecorded = useMemo(() => {
    if (!runStep || stepState === STEP_STATE.SKIPPED) {
      return {};
    }
    const map = {};
    runStep.content.forEach((block, index) => {
      if (isPreviewMode && block.preview_recorded) {
        map[index] = block.preview_recorded;
      } else if (block.recorded) {
        map[index] = block.recorded;
      }
    });
    return map;
  }, [runStep, stepState, isPreviewMode]);

  useEffect(() => {
    const handleWindowResize = function () {
      setIsMobile(window.innerWidth < MOBILE_WIDTH);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  const isEnded = useMemo(() => isStepEnded(step), [step]);
  const isStepCollapsed = useMemo(() => {
    // Steps are always expanded in single card view
    if (isSingleCardEnabled) {
      return false;
    }
    return isCollapsed || isHidden;
  }, [isCollapsed, isHidden, isSingleCardEnabled]);

  // Returns an array of step detail objects.
  const allStepDetails = useMemo(() => {
    const standardStepDetails = Object.values(DefaultStepDetailDefinitions);

    // If config doc or step details do not exist, return standard step details.
    if (!config || !config.step_details) {
      return standardStepDetails;
    }

    const optionalStepDetails = Object.values(config.step_details);

    return [...standardStepDetails, ...optionalStepDetails];
  }, [config]);

  // Returns step details that have a non empty value.
  const visibleStepDetails = useMemo(() => {
    return allStepDetails.filter((stepDetail) => {
      const stepDetailValue = diffUtil.getFieldValue(step, stepDetail.id);

      if (Array.isArray(stepDetailValue)) {
        return stepDetailValue.length !== 0;
      }

      return !isEmptyValue(stepDetailValue);
    });
  }, [step, allStepDetails]);

  const toggleIsStepCollapsed = useCallback(() => {
    if (typeof onStepCollapse === 'function') {
      onStepCollapse(step.id, !isStepCollapsed);
    }
  }, [isStepCollapsed, onStepCollapse, step.id]);

  // recorded: any dict or obj meant to be recorded with this content block
  const recordValuesChanged = useCallback(
    (contentId, recorded) => {
      if (typeof onRecordValuesChanged !== 'function') {
        return;
      }
      onRecordValuesChanged(step.id, contentId, recorded);
    },
    [step, onRecordValuesChanged]
  );

  const onRecordErrorsChanged = useCallback((contentIndex, errors) => {
    setContentErrors((dict) => {
      const updates = {};
      updates[contentIndex] = errors;
      return {
        ...dict,
        ...updates,
      };
    });
  }, []);

  const onRecordUploadingChanged = useCallback((contentIndex, isUploading) => {
    setContentUploading((dict) => {
      const updates = {};
      updates[contentIndex] = isUploading;
      return {
        ...dict,
        ...updates,
      };
    });
  }, []);

  const isRedlineDisabledBecauseOfStepSnippet = useMemo(() => snippetUtil.isSnippet(step), [step]);

  const isLatestStep = useMemo(() => isRepeatable, [isRepeatable]);

  const isRedlineDisabledBecauseOfRepeat = !isLatestStep;

  const {
    canSuggestEdits,
    isRedlineEnabled,
    isRedlineStateDirty,
    onToggleRedline,
    reasonRedlineIsDisabled,
    redlineState,
    setRedlineState,
    showsRedlineButton,
  } = useRedline({
    docState,
    isPreviewMode,
    isRedlineDisabledBecauseOfSectionSnippet: isInSectionSnippet,
    isRedlineDisabledBecauseOfStepSnippet,
    isRedlineDisabledBecauseOfRepeat,
    isRedlineFeatureEnabled,
    isUserParticipant,
    projectId,
  });

  const dirtyContentChangeHandler = useCallback(
    (dirty, contentIndex) => {
      setRedlineState((state) => ({
        ...state,
        dirtyContent: {
          ...state.dirtyContent,
          [contentIndex]: dirty,
        },
      }));
    },
    [setRedlineState]
  );

  const dirtyFieldChangeHandler = useCallback(
    (dirty, field) => {
      setRedlineState((state) => ({
        ...state,
        dirtyFields: {
          ...state.dirtyFields,
          [field]: dirty,
        },
      }));
    },
    [setRedlineState]
  );

  const dirtyCommentChangeHandler = useCallback(
    (dirty) => {
      setRedlineState((state) => ({
        ...state,
        dirtyComment: dirty,
      }));
    },
    [setRedlineState]
  );

  const redlineBlockChanges = useCallback(
    (contentIndex) => {
      // Only show redlines for runs.
      if (!isRun || !step.redlines) {
        return [];
      }
      const block = step.content[contentIndex];
      return revisionsUtil.getBlockChanges(block, contentIndex, step.redlines);
    },
    [isRun, step]
  );

  const redlineStepFieldChanges = useCallback(
    (field) => {
      // Only show redlines for runs.
      if (!isRun || !step.redlines) {
        return [];
      }
      return revisionsUtil.getStepFieldChanges(step, field, step.redlines);
    },
    [isRun, step]
  );

  const saveRedlineHandler = useCallback(
    (contentIndex, block) => {
      if (mixpanel) {
        mixpanel.track('Redline Created', { 'Block Type': block.type });
      }
      return (
        onSaveRedlineBlock(contentIndex, block)
          .then(() => {
            if (!isMounted.current) {
              return;
            }
            setRedlineState((state) => ({
              ...state,
              dirtyContent: {
                ...state.dirtyContent,
                [contentIndex]: false,
              },
            }));
          })
          // Ignored for now, user can click "Save" again
          .catch(() => {})
      );
    },
    [onSaveRedlineBlock, mixpanel, setRedlineState]
  );

  const stepFieldKey = useCallback((stepField) => Object.keys(stepField)[0], []);

  const saveRedlineStepFieldHandler = useCallback(
    (stepField) => {
      const key = stepFieldKey(stepField);
      if (mixpanel) {
        mixpanel.track('Redline Created', { 'Field Name': key });
      }
      return (
        onSaveRedlineStepField(stepField)
          .then(() => {
            if (!isMounted.current) {
              return;
            }
            setRedlineState((state) => ({
              ...state,
              dirtyFields: { [key]: false },
            }));
          })
          // Ignored for now, user can click "Save" again
          .catch(() => {})
      );
    },
    [onSaveRedlineStepField, mixpanel, stepFieldKey, setRedlineState]
  );

  // Returns true if there is a requires_previous step flag and the previous step is complete.
  const isPreviousStepRequirementFulfilled = useMemo(() => {
    if (!step.requires_previous) {
      return true;
    }

    return isPreviousStepComplete;
  }, [step.requires_previous, isPreviousStepComplete]);

  const areConditionalAndStepDependenciesFulfilled = useMemo(() => {
    /**
     * We use this component in a non-run (edit) context, where
     * areDependenciesFulfilled will be undefined. We don't need to verify if
     * dependencies are fulfilled in a non-run context, so we can return true.
     */
    if (!isRun || !areRequirementsMet) {
      return true;
    }
    return areRequirementsMet(step);
  }, [isRun, step, areRequirementsMet]);

  // All operators included on step signoffs
  const allSignoffOperators = useMemo(() => {
    if (!step.signoffs) {
      return [];
    }
    return [
      ...new Set(
        [].concat.apply(
          [],
          step.signoffs.map((s) => s.operators)
        )
      ),
    ];
  }, [step.signoffs]);

  // Operator roles the user has that are included on step signoffs
  const userStepRoles = useMemo(() => {
    if (isPreviewMode) {
      if (previewUserRoles?.length > 0) {
        return previewUserRoles;
      }
      return allSignoffOperators;
    }
    return allSignoffOperators.filter((operator) => auth.hasOperatorRole(operator));
  }, [allSignoffOperators, auth, isPreviewMode, previewUserRoles]);

  const hasGenericSignoffs = useMemo(
    () => step.signoffs && step.signoffs.some((s) => s.operators && s.operators.length === 0),
    [step.signoffs]
  );

  const completeSignoffs = useMemo(() => signoffUtil.getCompleteSignoffs(step), [step]);

  const userHasSignoffs = useMemo(() => {
    return userStepRoles.length !== 0 || hasGenericSignoffs;
  }, [userStepRoles, hasGenericSignoffs]);

  const isRunnable = useMemo(() => {
    // When signoffs are not required, allow user to complete step more than once
    if (!signoffUtil.isSignoffRequired(step.signoffs)) {
      return true;
    }
    if (isEnded) {
      return false;
    }
    return userHasSignoffs;
  }, [isEnded, step.signoffs, userHasSignoffs]);

  // Any operator in the list of required operators can complete some step fields
  const isEnabled = useMemo(
    () =>
      areConditionalAndStepDependenciesFulfilled &&
      isPreviousStepRequirementFulfilled &&
      isUserParticipant &&
      auth.hasPermission(PERM.RUNS_EDIT, projectId) &&
      docState === RUN_STATE.RUNNING &&
      isRunnable,
    [
      areConditionalAndStepDependenciesFulfilled,
      isPreviousStepRequirementFulfilled,
      isUserParticipant,
      auth,
      projectId,
      docState,
      isRunnable,
    ]
  );

  const isAcceptRedlineEnabled = useMemo(() => runUtil.canIncludeRedlines(step), [step]);

  // Steps can be skipped even if they have unmet requirements/dependencies
  const isSkipEnabled = useMemo(() => {
    return (
      isUserParticipant &&
      auth.hasPermission(PERM.RUNS_EDIT, projectId) &&
      docState === RUN_STATE.RUNNING &&
      !isEnded &&
      (userStepRoles.length !== 0 || hasGenericSignoffs)
    );
  }, [isUserParticipant, auth, projectId, docState, isEnded, userStepRoles.length, hasGenericSignoffs]);

  const isRepeatEnabled = useMemo(
    () =>
      isRepeatable && // a step can only be repeated if the section is repeatable
      !step.created_during_run && // Repeat disabled for newly created steps (for now)
      areConditionalAndStepDependenciesFulfilled &&
      isPreviousStepRequirementFulfilled &&
      isUserParticipant &&
      auth.hasPermission(PERM.RUNS_EDIT, projectId) &&
      docState === RUN_STATE.RUNNING &&
      (userStepRoles.length !== 0 || hasGenericSignoffs),
    [
      isRepeatable,
      step.created_during_run,
      areConditionalAndStepDependenciesFulfilled,
      isPreviousStepRequirementFulfilled,
      isUserParticipant,
      auth,
      projectId,
      docState,
      userStepRoles.length,
      hasGenericSignoffs,
    ]
  );

  const isContentCompleteEnabled = useMemo(() => {
    if (!signoffUtil.isSignoffRequired(step.signoffs)) {
      return isEnabled;
    }
    return isEnabled && (!completeSignoffs || completeSignoffs.length < 1);
  }, [isEnabled, completeSignoffs, step.signoffs]);

  const hasContentErrors = useMemo(() => {
    if (contentErrors) {
      for (const contentIndex in contentErrors) {
        if (Object.keys(contentErrors[contentIndex]).length > 0) {
          return true;
        }
      }
    }
    return false;
  }, [contentErrors]);

  const isEmptyInputValue = useCallback((value) => value === undefined || value === '' || value === null, []);

  const hasEmptyFieldInputValues = useCallback(
    (contentRecorded) => {
      if (!contentRecorded || isEmptyInputValue(contentRecorded.value)) {
        return true;
      }
    },
    [isEmptyInputValue]
  );

  const hasEmptyTableInputValues = useCallback(
    (content, contentRecorded) => {
      // If the table only has static columns no need to go through the validation.
      if (content.columns.every((column) => column.column_type === 'text')) {
        return false;
      }

      if (!contentRecorded) {
        return true;
      }

      const values = contentRecorded.values;

      return values.some((rowsArray) =>
        rowsArray.some((cell, columnIndex) => {
          const column = content.columns[columnIndex];
          const columnType = column.column_type;

          // Static text columns dont need to be validated.
          if (columnType === 'text') {
            return false;
          }

          // both default/undefined or unchecked/false checkbox should be considered empty
          if (column.input_type === 'checkbox') {
            return !cell;
          }

          // Returns true if a cell value is empty.
          return isEmptyInputValue(cell);
        })
      );
    },
    [isEmptyInputValue]
  );

  /*
   * True if any content block is both empty and not in the process of uploading.
   *
   * To check if any files are uploading, check hasAnyUploadingValues or the
   * appropriate index in the contentUploading array.
   */
  const hasAnyEmptyValues = useMemo(() => {
    if (!step.content) {
      return false;
    }

    return step.content.some((content, i) => {
      const contentType = content.type;
      const recorded = contentRecorded[i];

      if (contentType === BlockTypes.FieldInput) {
        if (!contentUploading[i] && hasEmptyFieldInputValues(recorded)) {
          return true;
        }
        if (content.inputType === 'sketch' && (!recorded?.value || !recorded?.value.attachment_id)) {
          return true;
        }
        // always consider unchecked boxes to be empty
        if (content.inputType === 'checkbox' && recorded?.value === false) {
          return true;
        }
      } else if (contentType === 'table_input') {
        if (hasEmptyTableInputValues(content, recorded)) {
          return true;
        }
      } else if (contentType === 'part_usage') {
        if (!recorded || recorded.length === 0) {
          return true;
        }
        if (isEmptyValue(recorded[0].item_id) || isEmptyValue(recorded[0].amount)) {
          return true;
        }
      } else if (contentType === 'part_kit') {
        if (!recorded || recorded.length === 0) {
          return true;
        }
        const recordedItems = recorded['items'];
        const itemsInStep = content.items;
        if (Object.keys(recordedItems).length !== itemsInStep.length) {
          return true;
        }
        for (const item of Object.values(recordedItems)) {
          if (isEmptyValue(item.item_id) || isEmptyValue(item.amount)) {
            return true;
          }
        }
      } else if (contentType === 'part_build') {
        if (!recorded || recorded.length === 0) {
          return true;
        }
        const recordedItems = recorded['items'];
        const itemsInStep = content.items;
        if (Object.keys(recordedItems).length < itemsInStep.length) {
          return true;
        }
        for (const item of Object.values(recordedItems)) {
          if (
            isEmptyValue(item.amount) ||
            ('serial' in item && isEmptyValue(item.serial)) ||
            ('lot' in item && isEmptyValue(item.lot))
          ) {
            return true;
          }
        }
      }
      return false;
    });
  }, [step.content, contentRecorded, hasEmptyFieldInputValues, hasEmptyTableInputValues, contentUploading]);

  // True if any content block is in the process of uploading.
  const hasAnyUploadingValues = useMemo(() => {
    if (!step.content) {
      return false;
    }
    return step.content.some((_content, i) => contentUploading[i]);
  }, [step.content, contentUploading]);

  const anyOperatorSignedOff = useCallback(
    (step) => stepState === STEP_STATE.COMPLETED || signoffUtil.anySignoffsComplete(step),
    [stepState]
  );

  /*
   * Shows a confirmation to the user when step is ending.
   * Shows a suitable confirmation message depending if there are unsaved suggested
   * edits or empty step inputs. Will show confirmation if there are empty
   * step inputs, or if there are unsaved suggested edits, or if `forceConfirm`
   * is true.
   *
   * message: String, the base confirmation message to show.
   * forceConfirm: Boolean, True to always show the user confirmation.
   * warnOnEmptyOrUploadingValues (optional): Boolean, True to warn if some inputs
   *                                          (field, table, file, etc.) are missing or
   *                                          in the process of being uploaded, default
   *                                          true.
   *
   * returns: True if user confirmed or no confirmation was needed, otherwise
   *          false.
   */
  const confirmStepEnd = useCallback(
    (message, forceConfirm, warnOnEmptyOrUploadingValues = true) => {
      if (typeof message !== 'string' || typeof forceConfirm !== 'boolean') {
        return;
      }
      // Build user confirmation message
      let shouldConfirm = forceConfirm;
      // Warn: If redlining is active and there are unsaved changes.
      if (isRedlineStateDirty(redlineState)) {
        message += ` ${CONFIRM_DISCARD_EDITS_STR}`;
        shouldConfirm = true;
      }

      // Warn: If some inputs (field, table, etc) are missing or uploading.
      if (warnOnEmptyOrUploadingValues && !anyOperatorSignedOff(step)) {
        // Missing and uploading values
        if (hasAnyEmptyValues && hasAnyUploadingValues) {
          message += ` ${CONFIRM_EMPTY_AND_UPLOADING_INPUTS_STR}`;
          shouldConfirm = true;
        }
        // Missing values only
        if (hasAnyEmptyValues && !hasAnyUploadingValues) {
          message += ` ${CONFIRM_EMPTY_INPUTS_STR}`;
          shouldConfirm = true;
        }
        // Uploading values only
        if (!hasAnyEmptyValues && hasAnyUploadingValues) {
          message += ` ${CONFIRM_UPLOADING_INPUTS_STR}`;
          shouldConfirm = true;
        }
      }

      // Show popup confirmation if needed
      return !(shouldConfirm && !window.confirm(message));
    },
    [redlineState, step, hasAnyEmptyValues, isRedlineStateDirty, hasAnyUploadingValues, anyOperatorSignedOff]
  );

  const isAutoCollapseEnabled = useMemo(() => {
    // Don't auto collapse in single card view or outside runs
    if (isSingleCardEnabled || !isRun) {
      return false;
    }
    return getSetting(AUTO_COLLAPSE_UNACTIONABLE_STEPS_KEY, false);
  }, [isSingleCardEnabled, getSetting, isRun]);

  /**
   * Initialize the step as collapsed if it is completed or has unmet
   * requirements. When the requirements are met, expand the step.
   *
   * Only does this on the first render, and not when the run data has changed,
   * to prevent the screen from jumping unexpectedly, when other users complete steps.
   */
  const requirementsPreviouslyMet = useRef(areConditionalAndStepDependenciesFulfilled);

  useEffect(() => {
    if (isAutoCollapseEnabled && collapseCompletedSteps) {
      if (isCollapsed === undefined) {
        if (isEnded || !areConditionalAndStepDependenciesFulfilled) {
          onStepCollapse(step.id, true);
        }
      } else {
        if (areConditionalAndStepDependenciesFulfilled && !requirementsPreviouslyMet.current) {
          onStepCollapse(step.id, false);
        }
        requirementsPreviouslyMet.current = areConditionalAndStepDependenciesFulfilled;
      }

      setCollapseCompletedSteps(false);
    }
  }, [
    areConditionalAndStepDependenciesFulfilled,
    onStepCollapse,
    step.id,
    isAutoCollapseEnabled,
    isEnded,
    isCollapsed,
    collapseCompletedSteps,
  ]);

  // Complete == no signoffs required, move to next step
  const handleOnComplete = useCallback(() => {
    if (hasContentErrors) {
      // Form errors will render
      return;
    }
    // Confirm step completion
    if (!confirmStepEnd(CONFIRM_END_STEP_STR, false)) {
      return;
    }
    // Clear redline state and discard any unsaved edits
    setRedlineState(INITIAL_REDLINE_STATE);

    if (typeof onComplete === 'function') {
      if (mixpanel) {
        mixpanel.track('Step Completed');
        if (getSetting(AUTO_COLLAPSE_UNACTIONABLE_STEPS_KEY, false)) {
          onStepCollapse(step.id, true);
        }
      }
      onComplete(contentRecorded);
      goToNextFromStep(step.id);
    }
  }, [
    hasContentErrors,
    contentRecorded,
    getSetting,
    mixpanel,
    onComplete,
    onStepCollapse,
    step.id,
    goToNextFromStep,
    confirmStepEnd,
    setRedlineState,
  ]);

  const handleOnPreviousStep = useCallback(() => {
    if (mixpanel) {
      mixpanel.track('Step Previous');
    }
    goToPreviousFromStep(step);
  }, [goToPreviousFromStep, mixpanel, step]);

  const handleOnFailStep = useCallback(() => {
    if (hasContentErrors) {
      // Form errors will render
      return;
    }
    // Confirm step completion
    if (!confirmStepEnd(CONFIRM_END_STEP_STR, false)) {
      return;
    }
    if (typeof onFailStep === 'function') {
      if (mixpanel) {
        mixpanel.track('Step Failed');
      }
      if (isAutoCollapseEnabled) {
        onStepCollapse(step.id, true);
      }
      onFailStep(contentRecorded);
      advanceStep();
    }
  }, [
    contentRecorded,
    onFailStep,
    onStepCollapse,
    step.id,
    mixpanel,
    confirmStepEnd,
    hasContentErrors,
    advanceStep,
    isAutoCollapseEnabled,
  ]);

  const repeatStep = useCallback(
    (contentRecorded) => {
      if (typeof onRepeat !== 'function') {
        return;
      }
      // Show confirmation when step not ended
      if (!isEnded && !confirmStepEnd(CONFIRM_REPEAT_STEP_STR, true)) {
        return;
      }
      // Clear redline state and discard any unsaved edits
      setRedlineState(INITIAL_REDLINE_STATE);

      if (mixpanel) {
        mixpanel.track('Step Repeated');
      }
      onRepeat(contentRecorded);
    },
    [onRepeat, isEnded, confirmStepEnd, mixpanel, setRedlineState]
  );

  const skipStep = useCallback(
    (contentRecorded) => {
      if (typeof onSkip !== 'function') {
        return;
      }
      // Always show confirmation message when skipping step
      if (!confirmStepEnd(CONFIRM_SKIP_STEP_STR, true, false)) {
        return;
      }
      // Clear redline state and discard any unsaved edits
      setRedlineState(INITIAL_REDLINE_STATE);

      if (mixpanel) {
        mixpanel.track('Step Skipped');
      }

      if (isAutoCollapseEnabled) {
        onStepCollapse(step.id, true);
      }

      // Skip step
      onSkip(contentRecorded);
      advanceStep();
    },
    [onSkip, confirmStepEnd, mixpanel, isAutoCollapseEnabled, onStepCollapse, step.id, advanceStep, setRedlineState]
  );

  const runStateBannerClass = useMemo(() => {
    if (stepState === STEP_STATE.COMPLETED) {
      return 'bg-app-green-200';
    } else if (stepState === STEP_STATE.FAILED) {
      return 'bg-red-200';
    } else if (stepState === STEP_STATE.SKIPPED) {
      return 'bg-app-gray-400';
    }
    return '';
  }, [stepState]);

  const runStateStepBodyClass = useMemo(() => {
    if (stepState === STEP_STATE.COMPLETED) {
      return 'border-2 border-green-400 bg-white';
    } else if (stepState === STEP_STATE.FAILED) {
      return 'border-2 border-red-400 bg-white';
    } else if (stepState === STEP_STATE.SKIPPED) {
      return 'bg-zinc-50 border-2 border-app-gray-400'; /// zinc-50 == #FAFAFA
    } else {
      // Step has not been completed, failed, nor skipped
      return 'border-2 border-blue-200 bg-white';
    }
  }, [stepState]);

  // TODO (jon): fix that rings are being clipped at top and bottom
  const ringClasses = useMemo(() => {
    if (stepState === STEP_STATE.COMPLETED) {
      return 'ring-2 ring-offset-2 ring-app-green-400 ring-offset-app-green-200';
    } else if (stepState === STEP_STATE.FAILED) {
      return 'ring-2 ring-offset-2 ring-red-400 ring-offset-red-200';
    } else if (stepState === STEP_STATE.SKIPPED) {
      return 'ring-2 ring-offset-2 ring-app-gray-600 ring-offset-app-gray-400';
    }
    return '';
  }, [stepState]);

  // Get recorded content or pending recorded content for a content block.
  const pendingOrRecordedContent = useCallback(
    (content, contentIndex) => {
      // TODO: Refactor and cleanup, this is getting gnarly with optional signoffs.
      if (!signoffUtil.isSignoffRequired(step.signoffs)) {
        // If user is trying to change the content values, show the new values.
        if (contentRecorded[contentIndex] !== null && contentRecorded[contentIndex] !== undefined) {
          return contentRecorded[contentIndex];
        }
        // Show the previous (recorded) values.
        if (content.recorded) {
          return content.recorded;
        }
        return null;
      }

      if (content.recorded) {
        return content.recorded;
      }

      return contentRecorded[contentIndex];
    },
    [contentRecorded, step.signoffs]
  );

  const onStepRefChanged = useCallback(
    (element) => {
      if (typeof onRefChanged === 'function') {
        onRefChanged(step.id, element);
      }
    },
    [step, onRefChanged]
  );

  const isAddedStep = useMemo(() => {
    return 'created_during_run' in step && step['created_during_run'] === true;
  }, [step]);

  const redlineComments = useMemo(() => {
    return step && step.redline_comments;
  }, [step]);

  const redlineCommentsButtonLabel = useMemo(() => {
    if (!redlineComments) {
      return null;
    }
    let label = showRedlineComments ? 'Hide' : 'Show';
    label += ` ${Pluralize('suggestion', redlineComments.length, true)}`;
    return label;
  }, [redlineComments, showRedlineComments]);

  const toggleShowRedlineComments = useCallback(
    () => expandRedlineComments && expandRedlineComments(step.id, !showRedlineComments),
    [expandRedlineComments, showRedlineComments, step.id]
  );

  const stepRemoved = useMemo(() => isStepRemoved && isStepRemoved(step), [isStepRemoved, step]);

  const formatStepKey = useCallback(
    (displayStyle) => {
      if (!stepKey || !sectionKey) {
        return;
      }

      if (step.diff_change_state === ARRAY_CHANGE_SYMBOLS.REMOVED) {
        return '--';
      }

      return {
        letters: `${sectionKey}${stepKey}`,
        numbers: `${sectionKey}.${stepKey}`,
      }[displayStyle || 'letters'];
    },
    [sectionKey, step.diff_change_state, stepKey]
  );

  const isAddJiraIssueMenuOptionDisabled = useMemo(() => {
    return (
      !auth.hasPermission(PERM.RUNS_EDIT, projectId) ||
      !isUserParticipant ||
      (isJiraIntegrationEnabled && !isJiraIntegrationEnabled()) ||
      isPreviewMode
    );
  }, [auth, isUserParticipant, projectId, isPreviewMode, isJiraIntegrationEnabled]);

  const isAddIssueMenuOptionDisabled = useMemo(() => {
    return (
      !auth.hasPermission(PERM.RUNS_EDIT, projectId) ||
      !isUserParticipant ||
      (docState !== RUN_STATE.RUNNING && docState !== RUN_STATE.PAUSED) ||
      (isIssuesEnabled && !isIssuesEnabled()) ||
      isPreviewMode
    );
  }, [auth, projectId, isUserParticipant, docState, isIssuesEnabled, isPreviewMode]);

  const isAddStepMenuOptionDisabled = useMemo(() => {
    return (
      !auth.hasPermission(PERM.RUNS_EDIT_STEP, projectId) ||
      !isUserParticipant ||
      docState !== RUN_STATE.RUNNING ||
      !isRepeatable ||
      !getSetting('run_step_edits_enabled', false) ||
      !FEATURE_RUN_STEP_EDITS_ENABLED ||
      isPreviewMode
    );
  }, [auth, docState, getSetting, isRepeatable, isUserParticipant, projectId, isPreviewMode]);

  const isFailStepMenuOptionDisabled = useMemo(() => {
    return (
      !auth.hasPermission(PERM.RUNS_EDIT, projectId) ||
      !isUserParticipant ||
      docState !== RUN_STATE.RUNNING ||
      !isEnabled ||
      hasContentErrors ||
      !signoffUtil.isSignoffRequired(step.signoffs)
    );
  }, [auth, docState, hasContentErrors, isEnabled, isUserParticipant, projectId, step.signoffs]);

  const showRedlineCommentsRow = useMemo(() => {
    if (!(runUtil.isRunStateActive(docState) || docState === RUN_STATE.COMPLETED)) {
      return false;
    }
    return !(redlineState.isActive || !redlineComments);
  }, [docState, redlineComments, redlineState.isActive]);

  const skipStepAction = useMemo(() => {
    return {
      type: 'label',
      label: 'Skip Step',
      data: {
        icon: 'step-forward',
        title: 'Skip Step',
        onClick: () => skipStep(contentRecorded),
        disabled: !isSkipEnabled,
      },
    };
  }, [contentRecorded, isSkipEnabled, skipStep]);

  const repeatStepAction = useMemo(() => {
    return {
      type: 'label',
      label: 'Repeat Step',
      data: {
        icon: 'redo',
        title: 'Repeat Step',
        onClick: () => repeatStep(contentRecorded),
        disabled: !isRepeatEnabled,
      },
    };
  }, [contentRecorded, isRepeatEnabled, repeatStep]);

  const failStepAction = useMemo(() => {
    return {
      type: 'label',
      label: 'Fail Step',
      data: {
        icon: 'exclamation-circle',
        title: 'Fail Step',
        onClick: handleOnFailStep,
        disabled: isFailStepMenuOptionDisabled,
      },
    };
  }, [handleOnFailStep, isFailStepMenuOptionDisabled]);

  const addStepBelowAction = useMemo(() => {
    return {
      type: 'label',
      label: 'Add Step Below',
      data: {
        icon: 'plus',
        title: 'Add Step Below',
        onClick: () => onAddStepBelow(step),
        disabled: isAddStepMenuOptionDisabled,
      },
    };
  }, [isAddStepMenuOptionDisabled, onAddStepBelow, step]);

  const addJiraIssue = useMemo(() => {
    return {
      type: 'label',
      label: 'Add Jira Issue',
      data: {
        icon: 'fa-brands fa-jira',
        title: 'Add Jira Issue',
        onClick: () => setShowJiraIssueModal(true),
        disabled: isAddJiraIssueMenuOptionDisabled,
      },
    };
  }, [isAddJiraIssueMenuOptionDisabled]);

  const addNcrIssue = useMemo(() => {
    return {
      type: 'label',
      label: 'Add Issue',
      data: {
        icon: 'file-circle-exclamation',
        title: 'Add Issue',
        onClick: () => setShowNcrIssueModal(true),
        disabled: isAddIssueMenuOptionDisabled,
      },
    };
  }, [isAddIssueMenuOptionDisabled]);

  const menuActions = useMemo(() => {
    const actions = [skipStepAction, repeatStepAction, failStepAction];

    if (isJiraIntegrationEnabled && isJiraIntegrationEnabled()) {
      actions.push(addJiraIssue);
    }

    if (isIssuesEnabled && isIssuesEnabled()) {
      actions.push(addNcrIssue);
    }

    if (canSuggestEdits) {
      actions.push(addStepBelowAction);
    }

    return actions;
  }, [
    skipStepAction,
    repeatStepAction,
    failStepAction,
    isJiraIntegrationEnabled,
    isIssuesEnabled,
    canSuggestEdits,
    addJiraIssue,
    addNcrIssue,
    addStepBelowAction,
  ]);

  const isMenuEnabled = useMemo(() => {
    return runUtil.isRunStateActive(docState) && auth.hasPermission(PERM.RUNS_EDIT, projectId);
  }, [docState, auth, projectId]);

  const stepHeaderHasValues = useMemo(() => procedureUtil.stepHeaderHasValues(step), [step]);

  const hasDependencies = useMemo(() => {
    const hasStepDependencies = Boolean(
      step.dependencies &&
        step.dependencies.length &&
        step.dependencies[0] &&
        step.dependencies[0].dependent_ids &&
        step.dependencies[0].dependent_ids.length
    );
    const stepId = step.id?.endsWith('__removed') ? step.id.replace('__removed', '') : step.id;
    const hasStepConditionals =
      Object.values(sourceStepConditionalsMap?.[stepId] || {}).length > 0 ||
      Object.values(removedSourceStepConditionalsMap?.[stepId] || {}).length > 0;
    return hasStepDependencies || hasStepConditionals;
  }, [step, sourceStepConditionalsMap, removedSourceStepConditionalsMap]);

  const showPreviousNextButtons = useMemo(() => {
    return (
      runUtil.isRunStateActive(docState) &&
      !signoffUtil.isSignoffRequired(step.signoffs) &&
      isPreviousStepRequirementFulfilled
    );
  }, [docState, step, isPreviousStepRequirementFulfilled]);

  const isPreviousButtonEnabled = useMemo(() => {
    return isUserParticipant && auth.hasPermission(PERM.RUNS_EDIT, projectId) && docState === RUN_STATE.RUNNING;
  }, [isUserParticipant, auth, projectId, docState]);

  const isNextButtonEnabled = useMemo(() => {
    return (
      isUserParticipant &&
      auth.hasPermission(PERM.RUNS_EDIT, projectId) &&
      areConditionalAndStepDependenciesFulfilled &&
      docState === RUN_STATE.RUNNING
    );
  }, [isUserParticipant, auth, projectId, areConditionalAndStepDependenciesFulfilled, docState]);

  const showSignOffButton = useMemo(() => {
    return (
      signoffUtil.isSignoffRequired(step.signoffs) &&
      stepState !== STEP_STATE.FAILED &&
      stepState !== STEP_STATE.SKIPPED
    );
  }, [step, stepState]);

  const isNotifyOperatorsButtonVisible = useMemo(
    () =>
      runUtil.isRunStateActive(docState) &&
      auth.hasPermission(PERM.RUNS_EDIT, projectId) &&
      !hasGenericSignoffs &&
      step.signoffs &&
      step.signoffs.length > 0 &&
      notificationListenerConnected &&
      !isPreviewMode,
    [docState, auth, projectId, hasGenericSignoffs, step.signoffs, notificationListenerConnected, isPreviewMode]
  );

  const [isNotifying, setIsNotifying] = useState(false);
  const [notificationSuccess, setNotificationSuccess] = useState(null);
  const handleOnNotifyRemainingOperators = useCallback(() => {
    setIsNotifying(true);
    notifyRemainingStepOperators(step.id)
      .then(() => {
        setNotificationSuccess(true);
        setTimeout(() => {
          setNotificationSuccess(null);
          setIsNotifying(false);
        }, FLASH_MSG_MS);
      })
      .catch(() => {
        setNotificationSuccess(false);
        setTimeout(() => {
          setNotificationSuccess(null);
          setIsNotifying(false);
        }, FLASH_MSG_MS);
      });
  }, [step.id, notifyRemainingStepOperators]);

  const formattedStepKey = useMemo(() => {
    return formatStepKey(config && config.display_sections_as);
  }, [config, formatStepKey]);

  const subStepKeys = useMemo(() => {
    let currentSubStepKey = 1;
    const numbers = {};
    step.content.forEach((content) => {
      const { substepKey: key, nextNumber } = procedureUtil.displaySubStepKey(
        formattedStepKey,
        currentSubStepKey,
        content.type,
        content.diff_change_state
      );
      numbers[content.id] = key;
      currentSubStepKey = nextNumber;
    });
    return numbers;
  }, [step.content, formattedStepKey]);

  const isNotifyOperatorsButtonEnabled = useMemo(
    () =>
      areConditionalAndStepDependenciesFulfilled &&
      isPreviousStepRequirementFulfilled &&
      isUserParticipant &&
      !isNotifying &&
      !isEnded,
    [
      areConditionalAndStepDependenciesFulfilled,
      isPreviousStepRequirementFulfilled,
      isUserParticipant,
      isNotifying,
      isEnded,
    ]
  );

  const toTheSideImages = useMemo(() => {
    const toTheSideImages = [];
    if (step.content) {
      step.content.forEach((content) => {
        if (
          content.type.toLowerCase() === 'attachment' &&
          sharedDiffUtil.getDiffValue(content, 'display_style', 'new') === AttachmentImageDisplayStyle.ToTheSide
        ) {
          toTheSideImages.push(content);
        }
      });
    }
    return toTheSideImages;
  }, [step.content]);

  const hasToTheSideImages = useMemo(() => {
    return toTheSideImages.length > 0;
  }, [toTheSideImages]);

  // Makes the images in line if they are the only step contents
  const onlyHasToTheSideImages = useMemo(() => {
    return step.content && toTheSideImages.length === step.content.length;
  }, [toTheSideImages, step.content]);

  const shouldDisplayToTheSideImages = useMemo(() => {
    return hasToTheSideImages && !onlyHasToTheSideImages && !isMobile;
  }, [hasToTheSideImages, onlyHasToTheSideImages, isMobile]);

  const colSpanValue = useMemo(() => {
    return shouldDisplayToTheSideImages ? '2' : '3';
  }, [shouldDisplayToTheSideImages]);

  const stepDataTestId = useMemo(() => {
    const changeInfo = [
      ARRAY_CHANGE_SYMBOLS.ADDED,
      ARRAY_CHANGE_SYMBOLS.MODIFIED,
      ARRAY_CHANGE_SYMBOLS.REMOVED,
    ].includes(step.diff_change_state)
      ? `_${step.diff_change_state}`
      : '';
    return `step-${formatStepKey(config && config.display_sections_as)}-${repeatKey || 0}${changeInfo}`;
  }, [config, repeatKey, formatStepKey, step.diff_change_state]);

  const stepBannerEnabled = useMemo(() => {
    return hasDependencies || step.timer || step.duration;
  }, [step.timer, step.duration, hasDependencies]);

  if (isRun && !isStepVisible(step)) {
    return null;
  }

  const renderStepDetails = (stepDetail) => {
    if (stepDetail.id === 'duration' && typeof step[stepDetail.id] === 'object') {
      // No-op
    } else if (stepDetail.id === 'timer' && typeof step[stepDetail.id] === 'object') {
      // No-op
    } else {
      return (
        <ReviewStepDetail
          key={stepDetail.id}
          icon={stepDetail.icon}
          label={stepDetail.title}
          value={diffUtil.getFieldValue(step, stepDetail.id)}
          diffChangeState={diffUtil.getDiffChangeStateForFieldValue(step, stepDetail.id)}
        />
      );
    }
  };

  return (
    <tbody data-testid={stepDataTestId}>
      <tr>
        <td colSpan={3}>
          <DiffContainer
            label="Step"
            diffChangeState={step.diff_change_state}
            onScrollToDiffRefChanged={(element) => handleOnScrollToDiffRefChanged(step.id, element)}
          >
            <table className="table-fixed w-full border-collapse" cellSpacing="0" cellPadding="0" border={0}>
              <thead>
                <tr>
                  <th className="w-8"></th>
                  <th className="w-full"></th>
                  <th className="w-8"></th>
                </tr>
              </thead>
              <tbody aria-label="Step" role="region">
                <tr>
                  <td></td>
                  <td>
                    <table className="table-fixed w-full border-collapse" cellSpacing="0" cellPadding="0" border={0}>
                      <thead>
                        <tr>
                          <th className="w-4"></th>
                          <th className="w-full"></th>
                          <th className="w-0"></th>
                        </tr>
                      </thead>
                      {stepHeaderHasValues && (
                        <>
                          <tbody>
                            <tr>
                              <td>
                                <div
                                  className="w-0 h-0"
                                  ref={stepGotoRef}
                                  style={{ scrollMarginTop: `${scrollToBufferRem}rem` }}
                                ></div>
                              </td>
                            </tr>
                          </tbody>
                          <ReviewProcedureStepHeader
                            header={step.headers[0]}
                            onRefChanged={onRefChanged}
                            scrollMarginTopValueRem={scrollToBufferRem}
                          />
                        </>
                      )}
                      {stepBannerEnabled && (
                        <ReviewProcedureStepBanner
                          step={step}
                          isRun={isRun}
                          hasDependencies={hasDependencies}
                          isSingleCardEnabled={isSingleCardEnabled}
                          areConditionalAndStepDependenciesFulfilled={areConditionalAndStepDependenciesFulfilled}
                          onRefChanged={onRefChanged}
                          scrollMarginTopValueRem={scrollToBufferRem}
                          updateStepDetail={undefined}
                          baseRunningCondition={false}
                          isStepActive={false}
                          stepState={stepState}
                        />
                      )}
                      <tbody
                        aria-label="Step Body"
                        className={`shadow-lg shadow rounded ${runStateStepBodyClass}`}
                        ref={stepHeaderHasValues ? null : stepGotoRef}
                        style={{ scrollMarginTop: `${scrollToBufferRem}rem` }}
                        role="region"
                      >
                        {/* Title and completion checkbox row */}
                        <tr className={runStateBannerClass}>
                          <td className="align-top">
                            <div className={generateHiddenClassString('flex py-2', isHidden)}>
                              <div className="h-9">
                                {!isSingleCardEnabled && (
                                  <ExpandCollapseCaret
                                    isExpanded={!isStepCollapsed}
                                    onClick={toggleIsStepCollapsed}
                                    ariaLabel="Expand Collapse Step Toggle"
                                    isHidden={!onStepCollapse}
                                  />
                                )}
                              </div>
                            </div>
                          </td>
                          <td colSpan={2} className="break-words">
                            <div
                              ref={onStepRefChanged}
                              className={generateHiddenClassString(
                                'ml-4 py-2 flex items-start app-border-gray-4 page-break',
                                isHidden
                              )}
                              style={{ scrollMarginTop: `${scrollToBufferRem}rem` }}
                            >
                              {/* Step key */}
                              <button
                                className={`h-8 w-8 mt-0.5 flex justify-center items-center rounded-full bg-black ${ringClasses}`}
                                onClick={toggleIsStepCollapsed}
                              >
                                <span className="font-bold text-xs text-white">
                                  {formattedStepKey ? formattedStepKey : '--'}
                                </span>
                              </button>
                              {/* Step title bar */}
                              <div className="ml-4 w-8 flex flex-row grow justify-between">
                                {/* Step title bar left side content */}
                                <div className="flex grow font-semibold">
                                  <StepFieldWithRedlining
                                    fieldName="name"
                                    fieldValue={step.name}
                                    redlines={redlineStepFieldChanges('name')}
                                    placeholder="Step name*"
                                    showsRedlineAction={redlineState.isActive}
                                    isEditing={false}
                                    setIsEditing={() => null}
                                    onSubmitEdit={saveRedlineStepFieldHandler}
                                    onDirtyChanged={dirtyFieldChangeHandler}
                                    validate={validateUtil.validateFieldStepName}
                                    onLabelClick={toggleIsStepCollapsed}
                                    onAcceptPendingRedline={isAcceptRedlineEnabled ? onAcceptPendingRedline : undefined}
                                    isBold={true}
                                    isPadded={true}
                                    step={undefined}
                                  />
                                  {isAddedStep && (
                                    <div className="whitespace-nowrap flex-none self-start mt-1.5">
                                      <span className="ml-4 text-sm text-gray-600">
                                        <FontAwesomeIcon icon="clipboard" />
                                      </span>
                                      <span className="ml-1 text-sm font-bold text-gray-600 italic">Added</span>
                                    </div>
                                  )}
                                  {repeatKey && (
                                    <div className="whitespace-nowrap flex-none self-start mt-1.5">
                                      <span className="ml-4 text-sm text-gray-600">
                                        <FontAwesomeIcon icon="redo" />
                                      </span>
                                      <span className="ml-1 text-sm font-bold text-gray-600 italic">
                                        Repeat {repeatKey}
                                      </span>
                                    </div>
                                  )}
                                  {stepState === STEP_STATE.FAILED && (
                                    <div className="whitespace-nowrap flex-none self-start mt-1.5">
                                      <span className="ml-4 text-sm text-gray-600">
                                        <FontAwesomeIcon icon="exclamation-circle" />
                                      </span>
                                      <span className="ml-1 text-sm font-bold text-gray-600 italic">Failed</span>
                                    </div>
                                  )}
                                  {/* Content with icon for skip */}
                                  {stepState === STEP_STATE.SKIPPED && (
                                    <div className="whitespace-nowrap flex-none self-start mt-1.5">
                                      <span className="ml-4 text-sm text-gray-600">
                                        <FontAwesomeIcon icon="step-forward" aria-label="skipped" />
                                      </span>
                                      <span className="ml-1 text-sm font-bold text-gray-600 italic">Skipped</span>
                                    </div>
                                  )}
                                </div>

                                {/* TODO (jon): refactor this gnarly jsx */}
                                {/* Step title bar right side content */}
                                <div className="flex items-start gap-x-2">
                                  {/* Notify operators button*/}
                                  {isNotifyOperatorsButtonVisible && (
                                    <div className="flex text-sm mr-1 items-center relative">
                                      <button
                                        className="rounded items-center justify-center h-7 w-7 p-1 text-blue-600 disabled:bg-transparent hover:bg-gray-200 disabled:text-gray-400 disabled:cursor-default"
                                        type="button"
                                        title="Notify Remaining Operator(s)"
                                        aria-label="Notify Remaining Operators Button"
                                        disabled={!isNotifyOperatorsButtonEnabled}
                                        onClick={handleOnNotifyRemainingOperators}
                                      >
                                        <FontAwesomeIcon icon="fas fa-paper-plane" />
                                      </button>
                                      {notificationSuccess !== null && (
                                        <div className="absolute -left-8 top-10 m-auto shadow-md z-10">
                                          <div
                                            className={`px-2 py-1 rounded 0 text-center ${
                                              notificationSuccess
                                                ? 'bg-green-200 text-green-700'
                                                : 'bg-red-200 text-red-700'
                                            }`}
                                          >
                                            {notificationSuccess ? 'Notifications Sent' : 'Notification Failed'}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                  {PROCEDURE_LEVEL_STEP_SETTINGS.map(({ setting, tooltipText, icon }) => {
                                    return (
                                      <ReviewSettingBadge
                                        key={setting}
                                        tooltipText={tooltipText}
                                        icon={icon}
                                        oldSettingValue={isStepSettingEnabled(
                                          sharedDiffUtil.getDiffValue(step, setting, 'old'),
                                          sharedDiffUtil.getDiffValue(procedure, setting, 'old')
                                        )}
                                        newSettingValue={isStepSettingEnabled(
                                          sharedDiffUtil.getDiffValue(step, setting, 'new'),
                                          sharedDiffUtil.getDiffValue(procedure, setting, 'new')
                                        )}
                                        oldVisibleSettingValue={
                                          !isStepSettingEnabled(
                                            undefined,
                                            sharedDiffUtil.getDiffValue(procedure, setting, 'old')
                                          )
                                        }
                                        newVisibleSettingValue={
                                          !isStepSettingEnabled(
                                            undefined,
                                            sharedDiffUtil.getDiffValue(procedure, setting, 'new')
                                          )
                                        }
                                      />
                                    );
                                  })}
                                  <ReviewSettingBadge
                                    tooltipText="Batch Step"
                                    icon={faLayerGroup}
                                    oldSettingValue={sharedDiffUtil.getDiffValue(step, 'runAsBatch', 'old') === true}
                                    newSettingValue={sharedDiffUtil.getDiffValue(step, 'runAsBatch', 'new') === true}
                                    oldVisibleSettingValue={true}
                                    newVisibleSettingValue={true}
                                  />

                                  {/* Content with checkbox for signoffs */}
                                  <div className="flex flex-col items-end px-2">
                                    {step.requires_previous && (
                                      <span className="ml-4 mr-3 font-semibold text-xs text-gray-600 whitespace-nowrap">
                                        Requires Previous Step
                                      </span>
                                    )}
                                    {isRun &&
                                      !areConditionalAndStepDependenciesFulfilled &&
                                      stepState === STEP_STATE.INCOMPLETE && (
                                        <div className="py-1">
                                          <RequirementsOverlay step={step} isTooltipEnabled={isSingleCardEnabled} />
                                        </div>
                                      )}
                                    <div className="flex flex-row flex-wrap justify-end gap-x-2 gap-y-2">
                                      {showSignOffButton &&
                                        step.signoffs.map((signoff) => (
                                          <ReviewProcedureStepSignoffButton
                                            key={signoff.id}
                                            signoff={signoff}
                                            isStepCollapsed={isStepCollapsed}
                                          />
                                        ))}
                                    </div>
                                  </div>
                                  {/* @ts-ignore Type error that will be fixed when file is converted to typescript */}
                                  {isMenuEnabled && <ThreeDotMenu menuActions={menuActions} menuLabel="Step Menu" />}
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        {!isStepCollapsed && (visibleStepDetails.length > 0 || hasDependencies) && (
                          <tr>
                            <td colSpan={3}>
                              <div className={generateHiddenClassString('', isStepCollapsed)}></div>
                              <div
                                className={generateHiddenClassString(
                                  `flex flex-row flex-nowrap ${runStateBannerClass}`,
                                  isStepCollapsed
                                )}
                              >
                                <div className="w-11 mr-1"></div>
                                <div className="flex flex-row flex-wrap gap-x-3 items-start text-sm font-medium">
                                  {stepRemoved && !isSingleCardEnabled && hasDependencies && (
                                    <div className="flex">
                                      <FontAwesomeIcon className="text-gray-500 self-center mr-1" icon="info-circle" />
                                      <div className="flex flex-row gap-x-1 py-1">
                                        Dependencies are no longer valid.
                                      </div>
                                    </div>
                                  )}
                                  {visibleStepDetails.map((stepDetail) => (
                                    <div key={stepDetail.id}>{renderStepDetails(stepDetail)}</div>
                                  ))}
                                </div>
                              </div>
                            </td>
                          </tr>
                        )}
                        {!isStepCollapsed && (
                          <tr>
                            <td colSpan={3} className="align-top">
                              <table
                                className="table-fixed w-full border-collapse"
                                cellSpacing="0"
                                cellPadding="0"
                                border={0}
                              >
                                <thead>
                                  {shouldDisplayToTheSideImages && (
                                    <tr>
                                      <th className="w-4"></th>
                                      <th className="w-2/4"></th>
                                      <th className="w-2/4"></th>
                                    </tr>
                                  )}
                                  {!shouldDisplayToTheSideImages && (
                                    <tr>
                                      <th className="w-4"></th>
                                      <th className="w-auto"></th>
                                    </tr>
                                  )}
                                </thead>
                                <tbody>
                                  <tr>
                                    <td colSpan={colSpanValue} className="align-top">
                                      <table
                                        className="table-fixed w-full border-collapse"
                                        cellSpacing="0"
                                        cellPadding="0"
                                        border={0}
                                      >
                                        <thead>
                                          <tr>
                                            <th className="w-4"></th>
                                            <th className="w-auto"></th>
                                            <th className="w-64"></th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {/* Step content blocks */}
                                          {step.content &&
                                            step.content.map((content, contentIndex) => (
                                              <CommentWrapper
                                                key={`comment-wrapper-${content.id}`}
                                                comments={step.comments}
                                                content={content}
                                                isRun={isRun}
                                                saveNewComment={saveNewComment}
                                              >
                                                <Fragment key={contentIndex}>
                                                  {content.type.toLowerCase() === 'text' && (
                                                    <BlockTextWithRedlining
                                                      block={content}
                                                      blockLabel={subStepKeys[content.id]}
                                                      contentIndex={contentIndex}
                                                      redlines={redlineBlockChanges(contentIndex)}
                                                      isHidden={isStepCollapsed}
                                                      showsRedlineAction={redlineState.isActive}
                                                      isEditing={false}
                                                      setIsEditing={() => null}
                                                      onDirtyChanged={dirtyContentChangeHandler}
                                                      onSubmitEdit={(block) => saveRedlineHandler(contentIndex, block)}
                                                      onAcceptPendingRedline={
                                                        isAcceptRedlineEnabled ? onAcceptPendingRedline : undefined
                                                      }
                                                      onRefChanged={onRefChanged}
                                                      scrollMarginTopValueRem={scrollToBufferRem}
                                                    />
                                                  )}
                                                  {/* Render telemetry content row */}
                                                  {/* TODO: Refactor to use ProcedureBlockWithRedlining */}
                                                  {content.type.toLowerCase() === 'telemetry' && (
                                                    <ReviewBlockTelemetry
                                                      blockLabel={subStepKeys[content.id]}
                                                      telemetry={content}
                                                      docState={docState}
                                                      isHidden={isStepCollapsed}
                                                      isSpacerHidden={false}
                                                    />
                                                  )}
                                                  {/* Render commanding content row */}
                                                  {content.type.toLowerCase() === 'commanding' && (
                                                    <ReviewBlockCommanding
                                                      blockLabel={subStepKeys[content.id]}
                                                      commanding={content}
                                                      isHidden={isStepCollapsed}
                                                      isSpacerHidden={false}
                                                    />
                                                  )}
                                                  {/* Render procedure block */}
                                                  {(content.type.toLowerCase() === 'input' ||
                                                    content.type.toLowerCase() === 'alert' ||
                                                    content.type.toLowerCase() === 'requirement' ||
                                                    content.type.toLowerCase() === 'external_item') && (
                                                    <ProcedureBlockWithRedlining
                                                      block={content}
                                                      // No redlines in reviews.
                                                      redlines={[]}
                                                      recorded={pendingOrRecordedContent(content, contentIndex)}
                                                      blockLabel={subStepKeys[content.id]}
                                                      contentIndex={contentIndex}
                                                      isEnabled={false}
                                                      isHidden={isStepCollapsed}
                                                      showsRedlineAction={
                                                        redlineState.isActive && isRedlineSupported(content.type)
                                                      }
                                                      isEditing={false}
                                                      setIsEditing={() => null}
                                                      onDirtyChanged={dirtyContentChangeHandler}
                                                      onSubmitEdit={(block) => saveRedlineHandler(contentIndex, block)}
                                                      onRecordValuesChanged={recordValuesChanged}
                                                      onRecordErrorsChanged={onRecordErrorsChanged}
                                                      onRecordUploadingChanged={onRecordUploadingChanged}
                                                      onContentRefChanged={onRefChanged}
                                                      scrollMarginTopValueRem={scrollToBufferRem}
                                                      onAcceptPendingRedline={
                                                        isAcceptRedlineEnabled ? onAcceptPendingRedline : undefined
                                                      }
                                                    />
                                                  )}
                                                  {/* Render Table input */}
                                                  {content.type.toLowerCase() === 'table_input' && (
                                                    <ReviewTableInput
                                                      content={content}
                                                      blockLabel={subStepKeys[content.id]}
                                                      recorded={pendingOrRecordedContent(content, contentIndex)}
                                                      contentIndex={contentIndex}
                                                      isHidden={isStepCollapsed}
                                                      onFieldValueChange={recordValuesChanged}
                                                      onFieldErrorsChanged={onRecordErrorsChanged}
                                                      isSpacerHidden={false}
                                                    />
                                                  )}
                                                  {/* Render procedure link row */}
                                                  {content.type.toLowerCase() === 'procedure_link' && (
                                                    <ReviewBlockProcedureLink
                                                      docId={runId}
                                                      blockLabel={subStepKeys[content.id]}
                                                      docState={docState}
                                                      operation={operation}
                                                      contentIndex={contentIndex}
                                                      link={content}
                                                      onStartLinkedRun={onStartLinkedRun}
                                                      isHidden={isStepCollapsed}
                                                      isEnabled={isEnabled && !isPreviewMode}
                                                      sourceName={sourceName}
                                                    />
                                                  )}
                                                  {/* Render attachments row */}
                                                  {content.type.toLowerCase() === 'attachment' &&
                                                    (onlyHasToTheSideImages ||
                                                      isMobile ||
                                                      !toTheSideImages.includes(content)) && (
                                                      <ReviewBlockAttachment
                                                        attachment={content}
                                                        blockLabel={subStepKeys[content.id]}
                                                        isHidden={isStepCollapsed}
                                                        isSpacerHidden={false}
                                                      />
                                                    )}
                                                  {/* Render jump to row */}
                                                  {content.type.toLowerCase() === BlockTypes.JumpTo && (
                                                    <ReviewJumpTo
                                                      content={content}
                                                      blockLabel={subStepKeys[content.id]}
                                                      isHidden={isStepCollapsed}
                                                      isValid={!stepRemoved}
                                                    />
                                                  )}
                                                  {/* Render reference row */}
                                                  {content.type.toLowerCase() === BlockTypes.Reference && (
                                                    <ReviewReferenceBlock
                                                      originalReferencedContentId={sharedDiffUtil.getDiffValue(
                                                        content,
                                                        'reference',
                                                        'new'
                                                      )}
                                                      originalReferencedSubtype={sharedDiffUtil.getDiffValue(
                                                        content,
                                                        'sub_reference',
                                                        'new'
                                                      )}
                                                      originalReferencedFieldIndex={sharedDiffUtil.getDiffValue(
                                                        content,
                                                        'field_index',
                                                        'new'
                                                      )}
                                                      blockLabel={subStepKeys[content.id]}
                                                      isHidden={Boolean(isStepCollapsed)}
                                                      isValid={!stepRemoved}
                                                      diffChangeState={content.diff_change_state}
                                                    />
                                                  )}
                                                  {/* Render expression row */}
                                                  {content.type.toLowerCase() === BlockTypes.Expression && (
                                                    <ReviewExpressionBlock
                                                      name={sharedDiffUtil.getDiffValue(content, 'name', 'new')}
                                                      tokens={content.tokens}
                                                      blockLabel={subStepKeys[content.id]}
                                                      isHidden={Boolean(isStepCollapsed)}
                                                      isValid={!stepRemoved}
                                                      diffChangeState={content.diff_change_state}
                                                    />
                                                  )}
                                                  {/* Render part kit */}
                                                  {content.type.toLowerCase() === BlockTypes.PartKit && (
                                                    <ReviewPartKit
                                                      content={content}
                                                      onRecordValuesChanged={recordValuesChanged}
                                                      onRecordErrorsChanged={() => undefined}
                                                      onAddItem={() => undefined}
                                                      recorded={pendingOrRecordedContent(content, contentIndex)}
                                                      isHidden={Boolean(isStepCollapsed)}
                                                      isEnabled={isContentCompleteEnabled}
                                                      blockLabel={subStepKeys[content.id]}
                                                      isStepComplete={stepState === STEP_STATE.COMPLETED}
                                                      teamId={currentTeamId}
                                                    />
                                                  )}
                                                  {/* Render part kit */}
                                                  {content.type.toLowerCase() === BlockTypes.PartBuild && (
                                                    <ReviewPartBuild
                                                      content={content}
                                                      recorded={pendingOrRecordedContent(content, contentIndex)}
                                                      isHidden={Boolean(isStepCollapsed)}
                                                      isEnabled={isContentCompleteEnabled}
                                                      blockLabel={subStepKeys[content.id]}
                                                      teamId={currentTeamId}
                                                    />
                                                  )}
                                                  {/* Render inventory detail input */}
                                                  {content.type.toLowerCase() === BlockTypes.InventoryDetailInput && (
                                                    <ReviewInventoryDetailInput
                                                      content={content}
                                                      onRecordValuesChanged={recordValuesChanged}
                                                      recorded={pendingOrRecordedContent(content, contentIndex)}
                                                      blockLabel={subStepKeys[content.id]}
                                                      isEnabled={isContentCompleteEnabled}
                                                    />
                                                  )}
                                                  {/* Render tool check-out */}
                                                  {content.type.toLowerCase() === BlockTypes.ToolCheckOut && (
                                                    <ReviewToolCheckOutIn
                                                      content={content}
                                                      type="out"
                                                      teamId={currentTeamId}
                                                    />
                                                  )}
                                                  {/* Render tool check-in */}
                                                  {content.type.toLowerCase() === BlockTypes.ToolCheckIn && (
                                                    <ReviewToolCheckOutIn
                                                      content={content}
                                                      type="in"
                                                      teamId={currentTeamId}
                                                    />
                                                  )}
                                                  {/* Render part usage */}
                                                  {content.type.toLowerCase() === BlockTypes.PartUsage && (
                                                    <ReviewPartUsage
                                                      content={content}
                                                      errors={contentErrors && contentErrors[contentIndex]}
                                                      onRecordValuesChanged={recordValuesChanged}
                                                      onRecordErrorsChanged={(errors) =>
                                                        onRecordErrorsChanged(contentIndex, errors)
                                                      }
                                                      recorded={pendingOrRecordedContent(content, contentIndex)}
                                                      isHidden={Boolean(isStepCollapsed)}
                                                      isEnabled={isContentCompleteEnabled}
                                                      blockLabel={subStepKeys[content.id]}
                                                      teamId={currentTeamId}
                                                    />
                                                  )}
                                                  {/* Render tool usage */}
                                                  {content.type.toLowerCase() === BlockTypes.ToolUsage && (
                                                    <ReviewToolUsage content={content} teamId={currentTeamId} />
                                                  )}
                                                  {content.type.toLowerCase() === BlockTypes.TestCases && (
                                                    <ReviewTestCasesBlock
                                                      content={content}
                                                      isHidden={Boolean(isStepCollapsed)}
                                                      blockLabel={subStepKeys[content.id]}
                                                    />
                                                  )}
                                                  {content.type.toLowerCase() === BlockTypes.FieldInputTable && (
                                                    <ReviewFieldInputTable content={content} />
                                                  )}
                                                </Fragment>
                                              </CommentWrapper>
                                            ))}
                                        </tbody>
                                      </table>
                                    </td>
                                    {shouldDisplayToTheSideImages && (
                                      <td className="align-top">
                                        <table
                                          className="table-fixed w-full border-collapse"
                                          cellSpacing="0"
                                          cellPadding="0"
                                          border={0}
                                        >
                                          <tbody>
                                            {/* Render side by side images */}
                                            {toTheSideImages.map((content, contentIndex) => (
                                              <Fragment key={contentIndex}>
                                                <ReviewBlockAttachment
                                                  attachment={content}
                                                  isHidden={isStepCollapsed}
                                                  isSpacerHidden={true}
                                                  blockLabel={subStepKeys[content.id]}
                                                />
                                              </Fragment>
                                            ))}
                                          </tbody>
                                        </table>
                                      </td>
                                    )}
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        )}

                        {/* Step conditionals information */}
                        {!isStepCollapsed && step.conditionals && step.conditionals.length > 0 && (
                          <tr>
                            <td colSpan={3}>
                              <div className={generateHiddenClassString('', isStepCollapsed)}></div>
                              <div className={generateHiddenClassString('flex flex-row flex-nowrap', isStepCollapsed)}>
                                <div className="w-11 mr-1"></div>
                                <div className="flex flex-row flex-wrap gap-x-3 items-start text-sm font-medium">
                                  <ReviewStepConditionals step={step} conditionals={step.conditionals} />
                                </div>
                              </div>
                            </td>
                          </tr>
                        )}

                        {/* Next and previous step actions */}
                        {!isStepCollapsed && showPreviousNextButtons && (
                          <tr>
                            <td></td>
                            <td colSpan={2}>
                              <div className={generateHiddenClassString('flex space-x-4 mt-4 ml-4', isStepCollapsed)}>
                                {isSingleCardEnabled && hasPreviousStep && (
                                  <Button
                                    type={BUTTON_TYPES.PRIMARY}
                                    onClick={handleOnPreviousStep}
                                    isDisabled={!isPreviousButtonEnabled}
                                  >
                                    Previous
                                  </Button>
                                )}
                                <Button
                                  type={BUTTON_TYPES.PRIMARY}
                                  onClick={handleOnComplete}
                                  isDisabled={!isNextButtonEnabled}
                                >
                                  Next
                                </Button>
                              </div>
                            </td>
                          </tr>
                        )}

                        {/* Step completion user id and timestamps */}
                        {!isStepCollapsed && (stepState === STEP_STATE.SKIPPED || step.actions?.length > 0) && (
                          <>
                            <tr>
                              <td></td>
                              <td colSpan={2}>
                                <div className={generateHiddenClassString('page-break', isStepCollapsed)}>
                                  <StepTimeline sectionId={sectionId} step={step} issues={run.issues} isRun={isRun} />
                                </div>
                              </td>
                            </tr>
                          </>
                        )}

                        {/* Review commenting row */}
                        {!isStepCollapsed && showReviewComments && (
                          <>
                            <tr>
                              <td></td>
                              <td colSpan={2}>
                                <div className={generateHiddenClassString('mb-2', isStepCollapsed)}></div>
                                <div className={generateHiddenClassString('mb-2 page-break', isStepCollapsed)}>
                                  <ReviewCommenting
                                    stepId={step.id}
                                    onResolveReviewComment={onResolveReviewComment}
                                    onUnresolveReviewComment={onUnresolveReviewComment}
                                    saveReviewComment={saveReviewComment}
                                    reviewComments={reviewUtil.getStepReviewComments(comments, step.id)}
                                  />
                                </div>
                              </td>
                            </tr>
                          </>
                        )}

                        {/* Extra padding if there is no run comment (isPreviewMode) and no review comment */}
                        {!isStepCollapsed && isPreviewMode && !showReviewComments && (
                          <tr className="h-3">
                            <td colSpan={3}></td>
                          </tr>
                        )}

                        {/* Show/hide redline comments button and comments */}
                        {!isStepCollapsed && showRedlineCommentsRow && (
                          <tr>
                            <td></td>
                            <td colSpan={2}>
                              <div className={generateHiddenClassString('ml-6 mt-2 print:hidden', isStepCollapsed)}>
                                <div>
                                  <button onClick={toggleShowRedlineComments}>
                                    <div className="link text-sm">
                                      <FontAwesomeIcon icon="comment" className="mr-2" />
                                      <span>{redlineCommentsButtonLabel}</span>
                                    </div>
                                  </button>
                                </div>
                                {showRedlineComments && (
                                  <div>
                                    <div className="mx-8">
                                      <CommentsList comments={redlineComments} onRefChanged={onRefChanged} />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        )}

                        <tr>
                          <td>
                            <JiraIssueModal
                              // TODO: [EPS-3845] config setting to allow this to be generic
                              urlText={`${run?.name}: ${step?.name}`}
                              url={`${locationUrl}${getScrollToUrlParams({ id: step?.id, type: 'step' })}`}
                              onAddIssue={onAddIssue}
                              isModalShown={showJiraIssueModal}
                              onHideModal={() => setShowJiraIssueModal(false)}
                            ></JiraIssueModal>
                          </td>
                        </tr>

                        {isIssuesEnabled && isIssuesEnabled() && (
                          <tr>
                            <td>
                              <CreateIssueModal
                                runId={runId}
                                referenceId={step?.id}
                                referenceType={STEP_REFERENCE_TYPE}
                                onAddIssue={onAddIssue}
                                isModalShown={showNcrIssueModal}
                                onHideModal={() => setShowNcrIssueModal(false)}
                                projectId={projectId}
                              />
                            </td>
                          </tr>
                        )}

                        {/* Step commenting row */}
                        {!isStepCollapsed && !isPreviewMode && (
                          <tr>
                            <td></td>
                            <td colSpan={2}>
                              <div className={generateHiddenClassString('mb-2', isStepCollapsed)}></div>
                              <div className={generateHiddenClassString('mb-2 page-break', isStepCollapsed)}>
                                <StepCommenting
                                  sectionId={sectionId}
                                  stepId={step.id}
                                  comments={step.comments}
                                  redlineComments={step.redline_comments}
                                  saveNewComment={saveNewComment}
                                  isRedlineActive={redlineState.isActive}
                                  isRedlineEnabled={isRedlineEnabled}
                                  reasonRedlineIsDisabled={reasonRedlineIsDisabled}
                                  onToggleRedline={onToggleRedline}
                                  onSaveRedlineStepComment={onSaveRedlineStepComment}
                                  onDirtyRedlineCommentChanged={dirtyCommentChangeHandler}
                                  showsRedlineButton={showsRedlineButton}
                                  onRefChanged={onRefChanged}
                                  isRun={false}
                                />
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                      <tbody>
                        <tr className="h-4"></tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </DiffContainer>
        </td>
      </tr>
    </tbody>
  );
};

export default ReviewProcedureStep;
