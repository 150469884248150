import {
  BlockType,
  Run,
  RunStepBlock,
} from 'shared/lib/types/views/procedures';

const runUtil = {
  checkedOutToolInstances: (run: Run): Array<number> => {
    const checkOuts = new Set<number>();
    const contentBlocks = internal.getContentByType(run, [
      'tool_check_out',
      'tool_check_in',
    ]);
    for (const content of contentBlocks) {
      if (content.type === 'tool_check_out' && content.recorded) {
        checkOuts.add(content.recorded.tool_instance_id);
      } else if (content.type === 'tool_check_in' && content.recorded) {
        checkOuts.delete(content.recorded.tool_instance_id);
      }
    }
    return Array.from(checkOuts);
  },
};

const internal = {
  getContentByType: (
    run: Run,
    types: Array<BlockType>
  ): Array<RunStepBlock> => {
    return run.sections
      .flatMap((section) => section.steps.filter((step) => step.completedAt))
      .sort((s1, s2) => {
        if (!s1.completedAt || !s2.completedAt) {
          return 0; // we filtered you out, undefined completedAt
        }
        return s1.completedAt.localeCompare(s2.completedAt);
      })
      .flatMap((step) =>
        step.content.filter((content) => types.includes(content.type))
      );
  },
};

export default runUtil;
