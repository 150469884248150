import { useCallback, useEffect, useState } from 'react';
import Button from '../../components/Button';
import useScheduleTrack from '../hooks/useScheduleTrack';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { FrontendEvent as Event, ResourceConflict } from 'shared/schedule/types/event';
import { Formik, Field } from 'formik';
import EventDateTime from '../components/EventDateTime';
import EventProcedure from '../components/EventProcedure/alt';
import EventDependencies from '../components/EventDependencies';
import EventSelect from '../components/EventSelect';
import EventMarkdown from '../components/EventMarkdown';
import REFRESH_TRY_AGAIN_MESSAGE from '../../lib/messages';
import Modal from '../../components/Modal';
import { validateEvent } from '../lib/event';
import EventDurationInput from '../components/EventDurationSelect';
import Tooltip from '../../elements/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import StatusInput from '../components/StatusInput';
import RelativeEventSelect from '../components/RelativeEventSelect';
import ResourceSelect from '../components/ResourceSelect';
import { DatabaseServices } from '../../contexts/proceduresSlice';
import { compareEvents } from 'shared/lib/scheduleUtil';
import OffsetInput from '../components/OffsetInputAlt';
import { CouchLikeOperationSummary } from 'shared/lib/types/operations';
import { Dialog } from 'primereact/dialog';
import { Accordion, AccordionTab } from 'primereact/accordion';
import RRuleGenerator from './RRuleGenerator';
import Error from '../../components/Error';
import { StringSelectOption } from '../../lib/formik';
import apm from '../../lib/apm';

const INITIAL_EVENT = {
  id: '',
  name: '',
  dependencies: {
    in: [],
    out: [],
  },
  predecessor_id: '',
};

const StartOptions = [
  { value: 'at', label: 'At' },
  { value: 'after', label: 'After' },
];

const TypeOptions = [
  { value: 'event', label: 'Event' },
  { value: 'milestone', label: 'Milestone' },
];

const Footer = ({
  onSave,
  disableSave,
  onCancel,
  disableCancel,
}: {
  onSave: () => void;
  disableSave: boolean;
  onCancel: () => void;
  disableCancel: boolean;
}) => {
  return (
    <div className="mt-2 sm:flex sm:flex-row-reverse">
      <Button type="primary" onClick={onSave} isDisabled={disableSave}>
        Save Event
      </Button>
      <Button type="secondary" onClick={onCancel} isDisabled={disableCancel}>
        Cancel
      </Button>
    </div>
  );
};

interface CreateEventModalProps {
  isVisible: boolean;
  onSave: () => void;
  onCancel: () => void;
  operation?: CouchLikeOperationSummary;
}

const CreateEventModal = ({ isVisible, onSave, onCancel, operation }: CreateEventModalProps) => {
  const scheduleTrack = useScheduleTrack();
  const { currentTeamId, services }: { currentTeamId: string; services: DatabaseServices } = useDatabaseServices();

  const [startType, setStartType] = useState<StringSelectOption>({ value: 'at', label: 'At:' });
  const [error, setError] = useState<string | null>(null);
  const [resourceConflicts, setResourceConflicts] = useState<Array<ResourceConflict> | null>(null);
  const [swimlaneOptions, setSwimlaneOptions] = useState<Array<StringSelectOption>>([]);
  const [events, setEvents] = useState<Array<Event>>([]);

  // Load events
  useEffect(() => {
    services.events
      .getSingleEvents()
      .then((newEvents) => {
        newEvents.sort(compareEvents);
        setEvents(newEvents);
      })
      .catch(() => {
        setError('Error loading events - please refresh and try again.');
      });
  }, [services.events]);

  // Load swimlane options
  useEffect(() => {
    services.swimlanes
      .getSwimlanes()
      .then((swimlanes) => {
        setSwimlaneOptions(
          swimlanes.map((swimlane) => ({
            value: swimlane.id,
            label: swimlane.name,
          }))
        );
      })
      .catch(() => {
        setError(REFRESH_TRY_AGAIN_MESSAGE);
      });
  }, [services.events, services.swimlanes]);

  const saveEvent = useCallback(
    async (event, opts) => {
      try {
        // Create the swimlane if it doesn't exist => TODO (Alex): Move this to backend
        if (event.swimlane_id && !swimlaneOptions.some((o) => o.value === event.swimlane_id)) {
          const newSwimlaneId = await services.swimlanes.createSwimlane({ name: event.swimlane_id });
          setSwimlaneOptions((options) => {
            options.push({
              value: newSwimlaneId,
              label: event.swimlane_id,
            });
            return options;
          });
          event.swimlane_id = newSwimlaneId;
          scheduleTrack('Swimlane created');
        }

        // Create new event
        scheduleTrack('Event created');
        await services.events.createEvent(event, !!opts.ignore_resource_conflicts);
        onSave();
      } catch (err) {
        if (err.response.status === 409 && err.response.data.error === 'resource_conflicts') {
          setResourceConflicts(err.response.data.conflicts);
        } else {
          setError(REFRESH_TRY_AGAIN_MESSAGE);
        }
      }
    },
    [services.events, scheduleTrack, services.swimlanes, swimlaneOptions, onSave]
  );

  return (
    <Formik
      initialValues={{
        ...INITIAL_EVENT,
        operation,
      }}
      onSubmit={(event) => saveEvent(event, {})}
      enableReinitialize={true}
      validate={validateEvent}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ values, errors, handleSubmit, setFieldValue, setSubmitting, isSubmitting }) => (
        <Dialog
          header="New Event"
          onHide={onCancel}
          visible={isVisible}
          closable={false}
          className="w-3/4 md:w-1/2"
          footer={
            <Footer onSave={handleSubmit} onCancel={onCancel} disableSave={isSubmitting} disableCancel={isSubmitting} />
          }
        >
          {resourceConflicts && (
            <Modal
              title="Resource Conflict"
              primaryActionTitle="Save with conflicts"
              onPrimaryAction={() => {
                setSubmitting(true);
                saveEvent(values, { ignore_resource_conflicts: true })
                  .then(() => {
                    setSubmitting(false);
                  })
                  .catch((err) => apm.captureError(err));
              }}
              secondaryActionTitle="Resolve"
              onSecondaryAction={() => setResourceConflicts(null)}
              isPrimaryActionEnabled={!isSubmitting}
              isSecondaryActionEnabled={!isSubmitting}
            >
              <div className="flex flex-col">
                <span>The following resources have conflicts:</span>
                <ul className="list-disc ml-6">
                  {resourceConflicts.map((conflict, idx) => (
                    <li key={idx}>{conflict.resourceId}</li>
                  ))}
                </ul>
                <span className="mt-3">Resolve, or save with conflicts.</span>
              </div>
            </Modal>
          )}

          <div className="flex flex-col space-y-2 mb-2">
            <div className="flex flex-row pl-7 justify-between items-center w-full px-1">
              {error && <div className="flex justify-center text-red-700 mr-2 w-full">{error}</div>}
            </div>

            {/* Event Type & Name */}
            <div className="flex flex-row space-x-1 w-full">
              <div className="flex flex-col">
                <div className="flex flex-row items-center text-xs text-gray-500 space-x-1">
                  <label>Event Type</label>
                  <Tooltip content="Milestones are displayed as vertical lines spanning the full height of a swimlane.">
                    <FontAwesomeIcon data-testid="milestone-tooltip-icon" icon="circle-info" />
                  </Tooltip>
                </div>
                <Select
                  value={values.milestone ? TypeOptions[1] : TypeOptions[0]}
                  classNamePrefix="react-select"
                  className="text-sm border-1 border-gray-300 rounded mr-2 w-40"
                  onChange={(selectedOption) => {
                    setFieldValue('milestone', Boolean(selectedOption.value === 'milestone'));
                  }}
                  options={TypeOptions}
                  isSearchable={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  aria-label="Event type selector"
                />
              </div>
              <div className="flex flex-col w-full">
                <label className="text-xs text-gray-500">Event Name</label>
                <Field
                  name="name"
                  aria-label="Event name"
                  placeholder="New Event"
                  type="text"
                  className="text-sm border-1 border-gray-300 rounded"
                />
                {errors.name && <span className="text-red-700">{errors.name}</span>}
              </div>
            </div>

            {/* Event Start & End*/}
            <div className="flex flex-row items-center space-x-1">
              <div className="flex flex-col">
                <label className="text-xs text-gray-500">Timing Type</label>
                <Select
                  value={startType}
                  classNamePrefix="react-select"
                  className="text-sm border-1 border-gray-300 rounded mr-2 w-20"
                  onChange={(selectedOption) => {
                    setFieldValue('predecessor_id', null);
                    setFieldValue('start', null);
                    setFieldValue('end', null);
                    setFieldValue('predecessor_offset', null);
                    setStartType(selectedOption);
                  }}
                  options={StartOptions}
                  isSearchable={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  aria-label="Event timing type selector"
                />
              </div>

              {startType.value === 'at' ? (
                <div className="flex flex-col">
                  <label className="text-xs text-gray-500">Start (UTC)</label>
                  <Field name="start" component={EventDateTime} isEditing={true} utcLabel={false} />
                </div>
              ) : (
                <div className="flex flex-col">
                  <label className="text-xs text-gray-500">Event</label>
                  <Field
                    name="start"
                    component={RelativeEventSelect}
                    isEditing={true}
                    events={events}
                    currentTeamId={currentTeamId}
                  />
                </div>
              )}
              {startType.label === 'After' && (
                <div className="flex flex-col">
                  <label className="text-xs text-gray-500">Wait</label>
                  <Field component={OffsetInput} />
                </div>
              )}

              {!values.milestone && startType.value === 'at' && (
                <div className="flex flex-col">
                  <label className="text-xs text-gray-500">End (UTC)</label>
                  <Field name="end" component={EventDateTime} isEditing={true} utcLabel={false} />
                </div>
              )}
            </div>

            {errors.start && <Error text={errors.start} />}
            {errors.end && <Error text={errors.end} />}
            {errors.predecessor_offset && <Error text={errors.predecessor_offset} />}

            {!values.milestone && (
              <div>
                <label className="text-xs text-gray-500">
                  Duration {!values.duration && !values.start && !values.predecessor_id && 'N/A'}
                </label>
                {(values.duration || values.start || values.predecessor_id) && (
                  <Field name="duration" component={EventDurationInput} isEditing={true} />
                )}
              </div>
            )}
          </div>

          <Accordion multiple>
            <AccordionTab headerTemplate={<div className="py-2">Procedure</div>}>
              <Field name="procedure" component={EventProcedure} />
            </AccordionTab>
            <AccordionTab headerTemplate={<div className="py-2">Parent Dependencies</div>}>
              <div className="mb-3">
                <Field
                  name="dependencies.in"
                  component={EventDependencies}
                  isEditing={true}
                  events={events}
                  currentTeamId={currentTeamId}
                />
              </div>
            </AccordionTab>
            <AccordionTab
              headerTemplate={
                <div className="py-2">
                  Resources
                  <Tooltip content="Resource conflicts are not checked for recurrences.">
                    <FontAwesomeIcon data-testid="resources-tooltip-icon" icon="info-circle" className="text-sm ml-1" />
                  </Tooltip>
                </div>
              }
            >
              <div className="mb-3">
                <label className="text-xs text-gray-500">Participants</label>
                <Field name="resources" component={ResourceSelect} isEditing={true} />
              </div>
            </AccordionTab>
            <AccordionTab
              headerTemplate={
                <div className="py-2">
                  Repeat {errors.rrule && <span className="ml-3 text-red-700 font-normal italics">{errors.rrule}</span>}
                </div>
              }
            >
              {operation && (
                <div className="flex mb-2">
                  <Tooltip content="Repeats not available for operation events.">
                    <div className="flex flex-row w-fit px-2 h-[42px] rounded border items-center">
                      <span className="italic text-gray-400">N/A</span>
                    </div>
                  </Tooltip>
                </div>
              )}

              {!operation && (
                <div className="-ml-3 mb-2">
                  <RRuleGenerator
                    onChange={(rrule) => setFieldValue('rrule', rrule)}
                    config={{
                      repeat: ['None', 'Yearly', 'Monthly', 'Weekly', 'Daily'],
                      weekStartsOnSunday: false,
                    }}
                    value={values.rrule}
                  />
                </div>
              )}
            </AccordionTab>
            <AccordionTab headerTemplate={<div className="py-2">Details</div>}>
              <div className="flex flex-col space-y-2">
                <div className="flex flex-row space-x-2">
                  <div className="flex flex-col">
                    <label className="text-xs text-gray-500">Operation</label>
                    <Field
                      name="operation.name"
                      aria-label="Event operation"
                      placeholder="None"
                      component="input"
                      type="text"
                      className="border-1 border-gray-300 p-2 rounded text-sm mr-2 h-[38px] disabled:bg-gray-100"
                      disabled={true}
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="text-xs text-gray-500">Swimlane</label>
                    <Field
                      name="swimlane_id"
                      component={EventSelect}
                      options={swimlaneOptions}
                      placeholder="Select or Create Swimlane"
                      isEditing={true}
                    />
                  </div>
                </div>

                <div className="flex flex-col">
                  <label className="text-xs text-gray-500">Status</label>
                  <Field name="status" component={StatusInput} isEditing={true} />
                </div>

                <div className="flex flex-col">
                  <label className="text-xs text-gray-500">Description</label>
                  <Field
                    name="description"
                    aria-label="Event description"
                    placeholder="Description"
                    type="text"
                    className="text-sm border-1 border-gray-300 rounded"
                  />
                </div>
                <div className="flex flex-col" aria-label="Event Notes">
                  <label className="text-xs text-gray-500">Notes</label>
                  <div className="-ml-3">
                    <Field name="notes" component={EventMarkdown} isEditing={true} />
                  </div>
                </div>
              </div>
            </AccordionTab>
          </Accordion>

          {/* Extra padding at bottom of dialog so date selectors don't overflow.*/}
          <div className="h-8" />
        </Dialog>
      )}
    </Formik>
  );
};

export default CreateEventModal;
